var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-file" },
    [
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-file-uploader",
              attrs: {
                multiple: "",
                action: _vm.uploadUrl,
                "before-upload": _vm.handleBeforeUpload,
                "file-list": _vm.fileList,
                limit: _vm.limit,
                "on-error": _vm.handleUploadError,
                "on-exceed": _vm.handleExceed,
                "on-success": _vm.handleUploadSuccess,
                "show-file-list": false,
                headers: _vm.headers,
                data: _vm.otherData
              }
            },
            [
              _c("el-button", { attrs: { size: "mini", type: "primary" } }, [
                _vm._v("选取文件")
              ]),
              _vm._v(" "),
              _vm.showTip
                ? _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [
                      _vm._v("\n        请上传\n        "),
                      _vm.fileSize
                        ? [
                            _vm._v(" 单个大小不超过 "),
                            _c("b", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(_vm._s(_vm.fileSize) + "MB")
                            ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fileType
                        ? [
                            _vm._v(" 格式为 "),
                            _c("b", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(_vm._s(_vm.fileType.join("/")))
                            ])
                          ]
                        : _vm._e(),
                      _vm._v("\n        的文件\n      ")
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.showConfirmDialog
            ? _c(
                "el-button",
                {
                  staticStyle: { position: "absolute", top: "0" },
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.uploadFile }
                },
                [_vm._v("选取文件")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          staticClass: "upload-file-list el-upload-list el-upload-list--text",
          attrs: { name: "el-fade-in-linear", tag: "ul" }
        },
        _vm._l(_vm.fileList, function(file, index) {
          return _c(
            "li",
            {
              key: file.url,
              staticClass: "el-upload-list__item ele-upload-list__item-content"
            },
            [
              _c(
                "el-link",
                {
                  attrs: { href: "#", underline: false },
                  nativeOn: {
                    click: function($event) {
                      return _vm.downloadFileItem("" + _vm.baseUrl + file.url)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "el-icon-document" }, [
                    _vm._v(" " + _vm._s(_vm.getFileName(file.name)) + " ")
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.fileList && _vm.fileList.length > 0
                ? _c(
                    "div",
                    { staticClass: "ele-upload-list__item-content-action" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.handleDelete(index)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("FileDownload", {
        ref: "fileDownload",
        attrs: { visible: _vm.downloadVisible },
        on: {
          "update:visible": function($event) {
            _vm.downloadVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }