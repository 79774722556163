var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: { model: _vm.listQuery, size: "small", inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "作者分类", prop: "categoryId" } },
            [
              _c("treeselect", {
                staticStyle: { width: "240px" },
                attrs: {
                  options: _vm.categoryOptions,
                  normalizer: _vm.normalizer,
                  placeholder: "选择作者分类"
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.listQuery.categoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "categoryId", $$v)
                  },
                  expression: "listQuery.categoryId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作者名称", prop: "authorName" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: {
                  placeholder: "请输入作者名称",
                  size: "medium",
                  clearable: ""
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.authorName,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "authorName", $$v)
                  },
                  expression: "listQuery.authorName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/author/library/list"],
                      expression: "['GET /admin/author/library/list']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["POST /admin/author/library/create"],
                      expression: "['POST /admin/author/library/create']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini"
                  },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作者名称",
              prop: "authorName",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作者头像",
              prop: "authorAvatar",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.authorAvatar
                      ? _c("img", {
                          staticClass: "img-circle img-xs",
                          attrs: {
                            src: _vm.baseUrl + scope.row.authorAvatar,
                            width: "40"
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作者简介",
              prop: "authorDesc",
              "min-width": "150",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作者分类",
              prop: "categoryId",
              "min-width": "100",
              formatter: _vm.formatCategoryName
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "联系方式",
              prop: "authorTel",
              width: "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "作者状态",
              align: "center",
              prop: "deleted",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            "作者状态: " +
                            (scope.row.deleted ? "禁用" : "启用"),
                          placement: "top"
                        }
                      },
                      [
                        _c("el-switch", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: [
                                "PUT /admin/author/library/disabling/{id}",
                                "PUT /admin/author/library/resuming/{id}"
                              ],
                              expression:
                                "['PUT /admin/author/library/disabling/{id}', 'PUT /admin/author/library/resuming/{id}']"
                            }
                          ],
                          attrs: {
                            "active-value": false,
                            "inactive-value": true
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleStatusChange(scope.row)
                            }
                          },
                          model: {
                            value: scope.row.deleted,
                            callback: function($$v) {
                              _vm.$set(scope.row, "deleted", $$v)
                            },
                            expression: "scope.row.deleted"
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "推荐状态",
              align: "center",
              prop: "isRecommend",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [
                            "PUT /admin/author/recommend/{authorId}/{isRecommend}"
                          ],
                          expression:
                            "['PUT /admin/author/recommend/{authorId}/{isRecommend}']"
                        }
                      ],
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.handleRecommendChange(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isRecommend,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isRecommend", $$v)
                        },
                        expression: "scope.row.isRecommend"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "粉丝数",
              prop: "fansNumber",
              width: "70"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "稿件数量",
              prop: "manuscriptNumber",
              width: "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "稿件下载数",
              prop: "manuscriptDownloads",
              width: "90"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "关联申请作者",
              prop: "authorApproveId",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.authorApproveId && scope.row.authorApproveId > 0
                      ? _c("el-tag", [_vm._v("是")])
                      : _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("否")
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTime",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "260",
              label: "操作",
              "class-name": "small-padding fixed-width",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [
                              "GET /admin/author/library/read/{id}",
                              "PUT /admin/author/library/update"
                            ],
                            expression:
                              "['GET /admin/author/library/read/{id}', 'PUT /admin/author/library/update']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["DELETE /admin/author/library/delete/{id}"],
                            expression:
                              "['DELETE /admin/author/library/delete/{id}']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-delete"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "1000px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "940px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "right",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleTabsClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "基本信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mr20",
                                  attrs: {
                                    label: "作者名称",
                                    prop: "authorName"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.authorName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "authorName",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.authorName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "ml20",
                                  attrs: {
                                    label: "作者分类",
                                    prop: "categoryId"
                                  }
                                },
                                [
                                  _c("treeselect", {
                                    attrs: {
                                      options: _vm.categoryOptions,
                                      normalizer: _vm.normalizer,
                                      flat: true,
                                      placeholder: "选择作者分类"
                                    },
                                    on: { select: _vm.handleSelect },
                                    model: {
                                      value: _vm.dataForm.categoryId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.dataForm,
                                          "categoryId",
                                          $$v
                                        )
                                      },
                                      expression: "dataForm.categoryId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "mr20",
                                  attrs: {
                                    label: "联系方式",
                                    prop: "authorTel"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.dataForm.authorTel,
                                      callback: function($$v) {
                                        _vm.$set(_vm.dataForm, "authorTel", $$v)
                                      },
                                      expression: "dataForm.authorTel"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "作者头像", prop: "authorAvatar" }
                            },
                            [
                              _c("ImageUpload", {
                                attrs: {
                                  uploadImgUrl: _vm.uploadPath,
                                  value: _vm.dataForm.authorAvatar,
                                  limit: 1,
                                  fileSize: 1
                                },
                                on: { input: _vm.getUploadImageUrl }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "作者简介", prop: "authorDesc" }
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", rows: 4 },
                                model: {
                                  value: _vm.dataForm.authorDesc,
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataForm, "authorDesc", $$v)
                                  },
                                  expression: "dataForm.authorDesc"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "作者详情", name: "2" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            prop: "authorDetail",
                            "label-width": "0px"
                          }
                        },
                        [
                          _c("editor-tiny-mce", {
                            ref: "tinyMce",
                            attrs: {
                              config: { width: 936, height: 360 },
                              value: _vm.dataForm.authorDetail
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.applyAuthorDialogVisible,
            title: "选择申请作者"
          },
          on: {
            "update:visible": function($event) {
              _vm.applyAuthorDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "queryApplyAuthorForm",
              attrs: {
                model: _vm.applyAuthorListQuery,
                size: "small",
                inline: true
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作者名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入作者名称", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleApplyAuthorFilter($event)
                      }
                    },
                    model: {
                      value: _vm.applyAuthorListQuery.name,
                      callback: function($$v) {
                        _vm.$set(_vm.applyAuthorListQuery, "name", $$v)
                      },
                      expression: "applyAuthorListQuery.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "申请时间" } },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "240px" },
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.searchTime,
                      callback: function($$v) {
                        _vm.searchTime = $$v
                      },
                      expression: "searchTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: { click: _vm.handleApplyAuthorFilter }
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetApplyAuthorQuery }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.applyAuthorListLoading,
                  expression: "applyAuthorListLoading"
                }
              ],
              ref: "applyAuthorTable",
              attrs: {
                data: _vm.applyAuthorList,
                size: "small",
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": ""
              },
              on: { "row-click": _vm.handleSingleElection }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "55", label: "选择" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radio",
                            attrs: { label: scope.row.authorId },
                            model: {
                              value: _vm.applyAuthorId,
                              callback: function($$v) {
                                _vm.applyAuthorId = $$v
                              },
                              expression: "applyAuthorId"
                            }
                          },
                          [_vm._v(" ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作者ID",
                  prop: "authorId",
                  width: "120",
                  sortable: ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作者名称",
                  prop: "authorName",
                  "min-width": "200"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系方式",
                  prop: "authorTel",
                  "min-width": "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "真实姓名",
                  prop: "realName",
                  "min-width": "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "申请时间",
                  prop: "createTime",
                  width: "150"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.applyAuthorTotal > 0,
                expression: "applyAuthorTotal>0"
              }
            ],
            attrs: {
              total: _vm.applyAuthorTotal,
              page: _vm.applyAuthorListQuery.page,
              limit: _vm.applyAuthorListQuery.size
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.applyAuthorListQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.applyAuthorListQuery, "size", $event)
              },
              pagination: _vm.getApplyAuthorList
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "op-container",
              staticStyle: { "margin-top": "40px" }
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.applyAuthorDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleRelateApplyAuthor }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }