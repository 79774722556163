//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getToken } from "@/utils/auth";

export default {
  props: {
    uploadImgUrl: {
      type: String,
      required: true
    },
    value: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 5
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 2
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: function _default() {
        return ["png", "jpg", "jpeg"];
      }
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      number: 0,
      uploadList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      baseUrl: process.env.BASE_API,
      headers: {
        'AdminAuthorizationToken': getToken()
      },
      fileList: [],
      fileNums: 0 //防止上传图片等待时间长，而右侧出现一个添加图片的框
    };
  },

  watch: {
    value: {
      handler: function handler(val) {
        var _this = this;

        if (val) {
          // 首先将值转为数组
          var list = Array.isArray(val) ? val : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(function (item) {
            if (typeof item === "string") {
              if (item.indexOf(_this.baseUrl) === -1) {
                item = { name: _this.baseUrl + item, url: _this.baseUrl + item };
              } else {
                item = { name: item, url: item };
              }
            }
            return item;
          });
          this.fileNums = this.fileList.length;
        } else {
          this.fileList = [];
          this.fileNums = 0;
          return [];
        }
      },

      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip: function showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    }
  },
  methods: {
    // 删除图片
    handleRemove: function handleRemove(file, fileList) {
      var findex = this.fileList.map(function (f) {
        return f.name;
      }).indexOf(file.name);
      if (findex > -1) {
        this.fileList.splice(findex, 1);
        this.$emit("input", this.listToString(this.fileList));
      }
      this.fileNums = this.fileList.length;
    },

    // 上传成功回调
    handleUploadSuccess: function handleUploadSuccess(res) {
      this.uploadList.push({ name: this.baseUrl + res.data.url, url: this.baseUrl + res.data.url });
      if (this.uploadList.length === this.number) {
        this.fileList = this.fileList.concat(this.uploadList);
        this.fileNums = this.fileList.length;
        this.uploadList = [];
        this.number = 0;
        this.$emit("input", this.listToString(this.fileList));
        this.$modal.closeLoading();
      }
    },

    // 上传前loading加载
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isImg = false;
      if (this.fileType.length) {
        var fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isImg = this.fileType.some(function (type) {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isImg = file.type.indexOf("image") > -1;
      }

      if (!isImg) {
        this.$modal.msgError("\u6587\u4EF6\u683C\u5F0F\u4E0D\u6B63\u786E, \u8BF7\u4E0A\u4F20" + this.fileType.join("/") + "\u56FE\u7247\u683C\u5F0F\u6587\u4EF6!");
        return false;
      }
      if (this.fileSize) {
        var isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError("\u4E0A\u4F20\u56FE\u7247\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 " + this.fileSize + " MB!");
          return false;
        }
      }
      this.$modal.loading("正在上传图片，请稍候...");
      this.fileNums++;
      this.number++;
    },

    // 文件个数超出
    handleExceed: function handleExceed() {
      this.$modal.msgError("\u4E0A\u4F20\u6587\u4EF6\u6570\u91CF\u4E0D\u80FD\u8D85\u8FC7 " + this.limit + " \u4E2A!");
    },

    // 上传失败
    handleUploadError: function handleUploadError() {
      this.$modal.msgError("上传图片失败，请重试");
      this.$modal.closeLoading();
    },

    // 预览
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    // 对象转成指定字符串分隔
    listToString: function listToString(list, separator) {
      var strs = "";
      separator = separator || ",";
      for (var i in list) {
        strs += list[i].url.replace(this.baseUrl, "") + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    }
  }
};