import request from '@/utils/request';
import Qs from 'qs';

/**
 * 列表查询电子商品订单
 * @param {*} params 
 * @returns 
 */
export function listOrderEbook(params) {
  return request({
    url: '/order/one/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 详情查询电子商品订单
 * @param {*} params 
 * @returns 
 */
export function listOrderEbookDetail(orderId) {
  return request({
    url: '/order/one/read/' + orderId,
    method: 'get'
  });
}

/**
 * 实物商品订单管理】列表查询实物商品订单
 * @param {*} params 
 * @returns 
 */
export function listOrderPaperBook(params) {
  return request({
    url: '/order/two/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 【实物商品订单管理】详情查询实物商品订单
 * @param {*} params 
 * @returns 
 */
export function orderPaperBookDetail(orderId) {
  return request({
    url: '/order/two/read/' + orderId,
    method: 'get'
  });
}

/**
 * 【实物商品订单管理】实物商品订单发货
 * @param {*} params 
 * @returns 
 */
export function orderPaperBookShip(data) {
  return request({
    url: '/order/two/ship',
    method: 'put',
    data: data
  });
}

/**
 * 【实物商品订单管理】实物商品订单退货审核
 * @param {*} params 
 * @returns 
 */
export function orderPaperBookReturnCheck(data) {
  return request({
    url: '/order/two/examine',
    method: 'put',
    data: data
  });
}

/**
 * 【实物商品订单管理】实物商品订单退款
 * @param {*} params 
 * @returns 
 */
export function orderPaperBookRefund(orderId) {
  return request({
    url: '/order/two/refund/' + orderId,
    method: 'put'
  });
}