import { render, staticRenderFns } from "./faq.vue?vue&type=template&id=1e80c4ac&"
import script from "./faq.vue?vue&type=script&lang=js&"
export * from "./faq.vue?vue&type=script&lang=js&"
import style0 from "./faq.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\zgsf\\UI\\shufa-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('1e80c4ac', component.options)
    } else {
      api.reload('1e80c4ac', component.options)
    }
    module.hot.accept("./faq.vue?vue&type=template&id=1e80c4ac&", function () {
      api.rerender('1e80c4ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src\\views\\sys\\faq.vue"
export default component.exports