//
//
//
//
//
//

import { getToken } from '@/utils/auth';

export default {
  name: 'PdfEditor',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      html: "/static/pdfEditor/index.html",
      pdfPath: '',
      worksId: ''
    };
  },
  created: function created() {
    if (!this.pdfPath || !this.worksId) {
      this.pdfPath = this.$route.params.pdfUrl;
      this.worksId = this.$route.params.id;
    }
    console.log('*******created*************');
  },
  mounted: function mounted() {
    var pdfUrl = this.pdfPath;
    var id = this.worksId;
    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    console.log("窗口宽度=====" + width);
    var pdfScale = 1.3; // 在线查看pdf可用，编辑不可以用
    if (width >= 1366 && width <= 1440) {
      pdfScale = 1.5;
    } else if (width > 1440 && width < 1920) {
      pdfScale = 1.8;
    } else if (width >= 1920) {
      pdfScale = 2.0;
    }
    console.log("id=" + id + " pdfUrl=" + pdfUrl + " pdfScale=" + pdfScale);
    var iframe = this.$refs['iframe'];
    if (iframe.attachEvent) {
      //兼容浏览器判断
      iframe.attachEvent("onload", function () {
        var iframeWin = iframe.contentWindow;
        iframeWin.postMessage({
          baseUrl: process.env.BASE_API,
          pdfUrl: pdfUrl,
          id: id,
          token: getToken(),
          pdfScale: pdfScale
        }, '*');
      });
    } else {
      iframe.onload = function () {
        var iframeWin = iframe.contentWindow;
        iframeWin.postMessage({
          baseUrl: process.env.BASE_API,
          pdfUrl: pdfUrl,
          id: id,
          token: getToken(),
          pdfScale: pdfScale
        }, '*');
      };
    }
  },

  methods: {}
};