import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listKeyword, createKeyword, deleteKeyword, updateKeyword, disablingKeyword, resumingKeyword } from '@/api/manuscript/keyword';

var textMap = {
  update: '编辑',
  create: '创建'
};

var textTF = [{ label: '否', value: false }, { label: '是', value: true }];

export default {
  name: 'Keyword',
  data: function data() {
    return {
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        keyword: undefined
      },
      downloadLoading: false,
      dataForm: {
        id: undefined,
        keyword: undefined,
        isDefault: false,
        isHot: false,
        sortOrder: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: textMap,
      textTF: textTF,
      rules: {
        keyword: [{ required: true, message: '关键字不能为空', trigger: 'blur' }],
        sortOrder: [{ required: true, message: "显示排序不能为空", trigger: "blur" }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listKeyword(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    reset: function reset() {
      this.dataForm = {
        id: undefined,
        keyword: undefined,
        isDefault: false,
        isHot: false,
        sortOrder: undefined
      };
      this.resetForm("dataForm");
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.reset();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createKeyword(_this3.dataForm).then(function (response) {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.notifySuccess('添加成功');
          }).catch(function (response) {
            _this3.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateKeyword(_this5.dataForm).then(function () {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.notifySuccess('更新成功');
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      var that = this;
      this.$confirm('是否确认删除名称为"' + row.keyword + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 确定操作
        deleteKeyword(row.id).then(function (response) {
          that.notifySuccess('删除成功');
          var index = _this6.list.indexOf(row);
          _this6.list.splice(index, 1);
        }).catch(function (response) {
          that.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作

      });
    }
  }
};