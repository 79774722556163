import request from '@/utils/request';
var qs = require('qs'); // qs模块是axios中自带的

/**
 * 新手上路详情查询
 */
export function findNoviceRoad() {
  return request({
    url: '/system/novice/road/get',
    method: 'get'
  });
}

/**
 * 新手上路更新
 */
export function updateNoviceRoad(data) {
  return request({
    url: '/system/novice/road/update',
    method: 'put',
    data: data
  });
}

/**
 * 支付方式详情查询
 */
export function findPayMethod() {
  return request({
    url: '/system/payment/method/get',
    method: 'get'
  });
}

/**
 * 支付方式更新
 */
export function updatePayMethod(data) {
  return request({
    url: '/system/payment/method/update',
    method: 'put',
    data: data
  });
}

/**
 * 查看隐私保护政策
 */
export function findPrivacyRule() {
  return request({
    url: '/system/privacy/agreement/get',
    method: 'get'
  });
}

/**
 * 编辑隐私保护政策
 */
export function updatePrivacyRule(data) {
  return request({
    url: '/system/privacy/agreement/update',
    method: 'put',
    data: data
  });
}

/**
 * 购物指南详情查询
 */
export function findShopDirectory() {
  return request({
    url: '/system/shopping/directory/get',
    method: 'get'
  });
}

/**
 * 购物指南更新
 */
export function updateShopDirectory(data) {
  return request({
    url: '/system/shopping/directory/update',
    method: 'put',
    data: data
  });
}

/**
 * 购物条款详情查询
 */
export function findShopTerms() {
  return request({
    url: '/system/shopping/terms/get',
    method: 'get'
  });
}

/**
 * 购物条款更新
 */
export function updateShopTerms(data) {
  return request({
    url: '/system/shopping/terms/update',
    method: 'put',
    data: data
  });
}

/**
 * 查看用户协议
 */
export function findUserAgreementRule() {
  return request({
    url: '/system/user/agreement/get',
    method: 'get'
  });
}

/**
 * 编辑用户协议
 */
export function updateUserAgreementRule(data) {
  return request({
    url: '/system/user/agreement/update',
    method: 'put',
    data: data
  });
}

// 系统参数配置
export function getParamsList() {
  return request({
    url: '/system/params/list',
    method: 'get'
  });
}

// 更新系统参数配置
export function setParams(data) {
  return request({
    url: '/system/params/update',
    method: 'put',
    data: data
  });
}