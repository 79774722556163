var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.works
        ? _c(
            "el-card",
            { staticClass: "card-box" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("作品名称："),
                      _c("span", [_vm._v(_vm._s(_vm.works.worksName))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("责任编辑："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.works.manuscriptMandoc &&
                              _vm.works.manuscriptMandoc.psnname
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("作品ID："),
                      _c("span", [_vm._v(_vm._s(_vm.works.id))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("创建时间："),
                      _c("span", [_vm._v(_vm._s(_vm.works.createTime))])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("作品分类："),
                      _c("span", [_vm._v(_vm._s(_vm.works.categoryName))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("作品价格："),
                      _c("span", [_vm._v(_vm._s(_vm.works.worksPrice))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("标签名称："),
                      _c("span", [_vm._v(_vm._s(_vm.works.tagName))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("   关键字："),
                      _c("span", [_vm._v(_vm._s(_vm.works.worksKeyword))])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("创作时间："),
                      _c("span", [_vm._v(_vm._s(_vm.works.addTime))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("入展时间："),
                      _c("span", [_vm._v(_vm._s(_vm.works.showTime))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("其他时间："),
                      _c("span", [_vm._v(_vm._s(_vm.works.otherTime))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("是否上架："),
                      _c("span", [
                        _vm._v(_vm._s(_vm.works.isOnSale === 0 ? "否" : "是"))
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("推荐作品："),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.works.isRecommend === 0 ? "否" : "是")
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("销售作品："),
                      _c("span", [
                        _vm._v(_vm._s(_vm.works.isSale === 0 ? "否" : "是"))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("作者名称："),
                      _c("span", [_vm._v(_vm._s(_vm.works.authorName))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("作品库存："),
                      _c("span", [_vm._v(_vm._s(_vm.works.nonhandnum))])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("存货编码："),
                      _c("span", [_vm._v(_vm._s(_vm.works.invcode))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("作品销量："),
                      _c("span", [_vm._v(_vm._s(_vm.works.salesVolume))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("收藏次数："),
                      _c("span", [_vm._v(_vm._s(_vm.works.collectTimes))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("计量单位："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.works.manuscriptMandoc &&
                              _vm.works.manuscriptMandoc.measname
                          )
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("快递运费："),
                      _c("span", [_vm._v(_vm._s(_vm.works.freightPrice))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "mb20 mt10", attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex", "font-size": "15px" } },
                      [
                        _c("span", { staticStyle: { width: "75px" } }, [
                          _vm._v("作品简介：")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { flex: "1", color: "#909399" } },
                          [_vm._v(_vm._s(_vm.works.worksDesc))]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "work-cover-item" }, [
                      _vm._v("作品封面：\n          "),
                      _vm.works.worksCover
                        ? _c("img", {
                            staticClass: "work-cover",
                            attrs: { src: _vm.baseUrl + _vm.works.worksCover },
                            on: {
                              click: function($event) {
                                return _vm.previewImage(_vm.works.worksCover)
                              }
                            }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.imageDialogVisible
        ? _c("AuthImgPreview", {
            attrs: { src: _vm.preImageUrl, visible: _vm.imageDialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.imageDialogVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "pl-5" }, [_vm._v("详细信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }