import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listCarousel, createCarousel, updateCarousel, deleteCarousel, readCarousel, resumingCarousel, disablingCarousel } from '@/api/system/rotationMap';
import { uploadPath } from '@/api/storage';
import { getToken } from '@/utils/auth';
import EditorTinyMce from '@/components/Editor';

export default {
  name: 'RotationMap',
  components: { EditorTinyMce: EditorTinyMce },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      ids: [], // 选中数组
      showSearch: true,
      single: true,
      multiple: false,
      uploadPath: uploadPath,
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        title: undefined
      },
      dataForm: {
        id: undefined,
        title: undefined,
        content: undefined,
        picUrl: undefined,
        link: undefined,
        isLink: false
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {},
      downloadLoading: false,
      imageDialogVisible: false,
      preImageUrl: ''
    };
  },

  computed: {
    headers: function headers() {
      return {
        'AdminAuthorizationToken': getToken()
      };
    }
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listCarousel(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    reset: function reset() {
      this.dataForm = {
        id: undefined,
        title: undefined,
        content: undefined,
        picUrl: undefined,
        link: undefined,
        isLink: false
      };
      this.resetForm("dataForm");
    },
    changeAdContentType: function changeAdContentType(e) {
      this.rules = {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        picUrl: [{ required: true, message: '轮播图片不能为空', trigger: 'blur' }]
      };
      if (this.dataForm.isLink) {
        this.rules.link = [{ required: true, message: '链接地址不能为空', trigger: 'blur' }];
      } else {
        this.rules.content = [{ required: true, message: '内容不能为空', trigger: 'blur' }];
      }
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.reset();
      this.rules = {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        picUrl: [{ required: true, message: '轮播图片不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '内容不能为空', trigger: 'blur' }]
      };
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },

    beforeUpload: function beforeUpload(file) {
      var isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.notifyError('上传轮播图片大小不能超过 1MB!');
      }
      return isLt1M;
    },
    uploadUrl: function uploadUrl(response) {
      this.dataForm.picUrl = response.data.url;
    },
    createData: function createData() {
      var _this3 = this;

      if (!this.dataForm.isLink) {
        this.dataForm.content = this.$refs.tinyMce.getContent();
        this.dataForm.contentPaths = this.$refs.tinyMce.getUrlPaths();
      }
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createCarousel(_this3.dataForm).then(function (response) {
            //this.$refs.tinyMce.clearEditor() //清除Editor内容
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.notifySuccess('创建成功');
          }).catch(function (response) {
            _this3.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.rules = {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        picUrl: [{ required: true, message: '轮播图片不能为空', trigger: 'blur' }]
      };

      var adId = row.id ? row.id : this.ids[0];
      readCarousel(adId).then(function (res) {
        _this4.dataForm = res.data.data;

        if (_this4.dataForm.isLink) {
          _this4.rules.link = [{ required: true, message: '链接地址不能为空', trigger: 'blur' }];
        } else {
          _this4.rules.content = [{ required: true, message: '内容不能为空', trigger: 'blur' }];
        }
      }).catch(function (response) {
        _this4.notifyError(response.data.msg);
      });

      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      if (!this.dataForm.isLink) {
        this.dataForm.content = this.$refs.tinyMce.getContent();
        this.dataForm.contentPaths = this.$refs.tinyMce.getUrlPaths();
      }

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateCarousel(_this5.dataForm).then(function () {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.notifySuccess('更新成功');
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      this.$confirm('确认要删除该轮播图吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteCarousel(row.id).then(function (response) {
          _this6.notifySuccess('删除成功');
          _this6.getList();
        }).catch(function (response) {
          _this6.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleStatusChange: function handleStatusChange(row) {
      var _this7 = this;

      var text = row.deleted === true ? '禁用' : '启用';
      this.$confirm('确认要"' + text + '"该轮播图吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.deleted = !row.deleted;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this8 = this;

      if (row.deleted === false) {
        // 停用改成启用
        resumingCarousel(row.id).then(function (response) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = _getIterator(_this8.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var v = _step.value;

              if (v.id === row.id) {
                var index = _this8.list.indexOf(v);
                _this8.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      } else {
        // 启用改成停用
        disablingCarousel(row.id).then(function (response) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = _getIterator(_this8.list), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var v = _step2.value;

              if (v.id === row.id) {
                var index = _this8.list.indexOf(v);
                _this8.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      }
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    },
    handleDownload: function handleDownload() {
      var _this9 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['ID', '标题', '内容', '图片', '查看次数', '活动链接', '是否启用'];
        var filterVal = ['id', 'name', 'content', 'url', 'postion', 'link', 'enabled'];
        excel.export_json_to_excel2(tHeader, _this9.list, filterVal, '轮播图信息');
        _this9.downloadLoading = false;
      });
    }
  }
};