//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { readMandocWorks } from '@/api/manuscript/works';

import AuthImgPreview from '@/components/AuthImgPreview';

export default {
  name: 'PaperWorksDetail',
  components: { AuthImgPreview: AuthImgPreview },
  filters: {
    formatAttachmentName: function formatAttachmentName(attachmentName) {
      if (attachmentName) {
        return attachmentName.slice(attachmentName.lastIndexOf("_") + 1).replace('.old', '');
      }
      return '';
    }
  },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      worksId: 0,
      works: {},
      preImageUrl: '',
      imageDialogVisible: false
    };
  },
  created: function created() {
    if (this.$route.query.id == null) {
      return;
    }
    this.worksId = this.$route.query.id;
    this.init();
  },

  methods: {
    init: function init() {
      var _this = this;

      readMandocWorks(this.worksId).then(function (response) {
        _this.works = response.data.data;
      }).catch(function (response) {
        _this.notifyError(response.data.msg);
      });
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    }
  }
};