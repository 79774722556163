var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "iframeBox" } },
    [
      _c("iframe", {
        ref: "iframe",
        staticClass: "myIframe",
        attrs: { src: _vm.html, scrolling: "no", frameborder: "0" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }