//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { readSaleWorks, readSplitSaleWorks, unlockWorks, updateSaleWorksChapter } from '@/api/manuscript/works';
import { getToken } from '@/utils/auth';
import FileDownload from "@/components/FileDownload";
import AuthImg from '@/components/AuthImg';
import FileUpload from '@/components/FileUpload';

export default {
  name: 'WorksSaleDetail',
  components: { FileDownload: FileDownload, FileUpload: FileUpload, AuthImg: AuthImg },
  filters: {
    formatAttachmentName: function formatAttachmentName(attachmentName) {
      if (attachmentName) {
        return attachmentName.slice(attachmentName.lastIndexOf("_") + 1).replace('.old', '');
      }
      return '';
    }
  },
  data: function data() {
    return {
      vueid: "myid",
      baseUrl: process.env.BASE_API,
      html: "/static/pdfEditor/index.html",
      worksId: 0,
      works: {},
      imageDialogVisible: false,
      preImageUrl: '',
      pdfEditVisible: false,
      downloadVisible: false,
      activeName: '1',
      chapterListLoading: false,
      chapterList: []
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id == null) {
      return;
    }
    this.worksId = this.$route.query.id;
    this.init();

    var self = this;
    window[this.vueid] = function (val) {
      //html调用vue中的方法
      switch (val) {
        case 1:
          self.closePdfEditDialog(1);
          break;
        case 2:
          _this.notifySuccess("保存批注数据成功");
          break;
        case 3:
          _this.notifyError("保存批注数据失败，请重试");
          break;
        case 4:
          _this.notifyError("加载PDF失败");
          unlockWorks(_this.worksId).then(function (res) {
            _this.pdfEditVisible = false;
          }).catch(function (err) {
            _this.notifyError(err.data.msg);
          });
          break;
        case 5:
          self.closePdfEditDialog(2);
      }
    };
  },

  methods: {
    init: function init() {
      var _this2 = this;

      readSaleWorks(this.worksId).then(function (response) {
        _this2.works = response.data.data;

        if (_this2.works.systemType !== 2) {
          _this2.getChapterDataList();
        }
      }).catch(function (response) {
        _this2.notifyError(response.data.msg);
      });
    },
    handleTabsClick: function handleTabsClick(tab, event) {
      this.activeName = tab.name;
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    },
    downloadFileItem: function downloadFileItem(fileUrl) {
      this.downloadVisible = true;
      this.$refs.fileDownload.downloadXhr(fileUrl);
    },
    getChapterDataList: function getChapterDataList() {
      var _this3 = this;

      //获取章节数据
      this.chapterListLoading = true;
      readSplitSaleWorks(this.worksId).then(function (res) {
        _this3.chapterListLoading = false;
        _this3.chapterList = res.data.data;
      }).catch(function (response) {
        _this3.notifyError(response.data.msg);
        _this3.chapterListLoading = false;
      });
    },
    handlePdfEdit: function handlePdfEdit(pdfUrl) {
      var _this4 = this;

      // pdf在线批注
      this.pdfEditVisible = true;
      setTimeout(function () {
        _this4.sendData2Html(pdfUrl, _this4.works.fileJsons); //上次批注数据
      }, 300);
    },
    sendData2Html: function sendData2Html(pdfUrl, jsonData) {
      var id = this.worksId;
      var myIframe = this.$refs['iframe'];
      if (myIframe) {
        myIframe.contentWindow.location.reload();
        if (myIframe.attachEvent) {
          myIframe.attachEvent('onload', function () {
            var iframeWin = myIframe.contentWindow;
            iframeWin.postMessage({
              baseUrl: process.env.BASE_API,
              pdfUrl: pdfUrl,
              id: id,
              token: getToken(),
              jsonData: jsonData || '',
              isLook: true
            }, '*');
          });
        } else {
          myIframe.onload = function () {
            var iframeWin = myIframe.contentWindow;
            iframeWin.postMessage({
              baseUrl: process.env.BASE_API,
              pdfUrl: pdfUrl,
              id: id,
              token: getToken(),
              jsonData: jsonData || '',
              isLook: true
            }, '*');
          };
        }
      }
    },
    closePdfEditDialog: function closePdfEditDialog(mtype) {
      var _this5 = this;

      var text = mtype === 1 ? '是否确认关闭在线编辑？' : '是否确认关闭？';
      this.$confirm(text, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (mtype === 1) {
          unlockWorks(_this5.worksId).then(function (response) {
            _this5.pdfEditVisible = false;
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        } else {
          _this5.pdfEditVisible = false;
        }
      }).catch(function () {// 取消操作
      });
    },
    handleUpdateChapter: function handleUpdateChapter(row) {
      var _this6 = this;

      // 更新章节信息
      if (row.chapterIndex == '' || row.chapterIndex <= 0) {
        return this.notifyInfo('章节序号不能为空且必须是大于零的整数');
      }
      if (row.chapterName == '') {
        return this.notifyInfo('章节名称不能为空！');
      }
      if (!row.chapterPrice) {
        return this.notifyInfo('章节价格不能为空！');
      }
      updateSaleWorksChapter(row).then(function (res) {
        _this6.notifySuccess('更新成功');
      }).catch(function (response) {
        _this6.notifyError(response.data.msg);
      });
    }
  }
};