var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("div", { staticClass: "per-main" }, [
        _c("div", { staticClass: "per-wrap" }, [
          _c("div", { staticClass: "per-r" }, [
            _c("div", { staticClass: "bg-sec" }, [
              _c("div", { staticClass: "order-sec" }, [
                _c("div", { staticClass: "row" }, [
                  _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { colspan: "3" } }, [
                          _c("h6", { staticClass: "top-info" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.orderInfo.createTime) +
                                "   订单号：" +
                                _vm._s(_vm.orderInfo.orderSn) +
                                "\n                      "
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _vm.orderInfo.orderStatus === 101 &&
                        _vm.orderInfo.endPaidTime
                          ? _c("td", [
                              _c("p", { staticClass: "status" }, [
                                _vm._v("等待付款")
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "time" },
                                [
                                  _vm._v("剩余时间\n                        "),
                                  _c("djs", {
                                    attrs: {
                                      endTime: _vm.orderInfo.endPaidTime,
                                      callback: _vm.djsCallback,
                                      endText: "已取消"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "paybtn animate-btn",
                                  on: { click: _vm.handlePay }
                                },
                                [
                                  _c("p", { staticClass: "p1" }, [
                                    _vm._v("立即支付")
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "p2" }, [
                                    _vm._v("立即支付")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "cancel",
                                  on: { click: _vm.handleCancelOrder }
                                },
                                [_vm._v("取消订单")]
                              )
                            ])
                          : _c("td", [
                              _c("p", { staticClass: "mb_10" }, [
                                _vm._v("订单状态")
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "status" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("getOrderStatus")(
                                      _vm.orderInfo.orderStatus
                                    )
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _vm.orderInfo.orderStatus === 301
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "paybtn animate-btn",
                                      on: { click: _vm.handleConfirmReceipt }
                                    },
                                    [
                                      _c("p", { staticClass: "p1" }, [
                                        _vm._v("确认收货")
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "p2" }, [
                                        _vm._v("确认收货")
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderInfo.orderStatus == 401 ||
                              _vm.orderInfo.orderStatus == 402
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "paybtn animate-btn",
                                      on: { click: _vm.handleApplyReturn }
                                    },
                                    [
                                      _c("p", { staticClass: "p1" }, [
                                        _vm._v("申请退货")
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "p2" }, [
                                        _vm._v("申请退货")
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderInfo.orderStatus == 102 ||
                              _vm.orderInfo.orderStatus == 103 ||
                              _vm.orderInfo.orderStatus == 401 ||
                              _vm.orderInfo.orderStatus == 402
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "cancel",
                                      on: { click: _vm.handleDelOrder }
                                    },
                                    [_vm._v("删除订单")]
                                  )
                                : _vm._e()
                            ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "txtbox" }, [
                            _c("p", { staticClass: "wz" }, [
                              _c("span", { staticClass: "smtit" }, [
                                _vm._v("商品总额：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "inf price" }, [
                                _c("i", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("parseMoney")(
                                        _vm.orderInfo.totalPrice
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "wz" }, [
                              _c("span", { staticClass: "smtit" }, [
                                _vm._v("优惠折扣：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "inf" }, [
                                _c("i", [
                                  _vm._v(
                                    "-" +
                                      _vm._s(
                                        _vm._f("parseMoney")(
                                          _vm.orderInfo.couponPrice
                                        )
                                      )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "wz" }, [
                              _c("span", { staticClass: "smtit" }, [
                                _vm._v("运费：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "inf" }, [
                                _c("i", [
                                  _vm._v(
                                    "+" +
                                      _vm._s(
                                        _vm._f("parseMoney")(
                                          _vm.orderInfo.freightPrice
                                        )
                                      )
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "wz" }, [
                              _c("span", { staticClass: "smtit" }, [
                                _vm._v("实付款：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "inf price" }, [
                                _c("i", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("parseMoney")(
                                        _vm.orderInfo.actualPrice
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [
                          _c("div", { staticClass: "txtbox" }, [
                            _c("p", { staticClass: "wz" }, [
                              _c("span", { staticClass: "smtit" }, [
                                _vm._v("收货人：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "inf" }, [
                                _vm._v(_vm._s(_vm.orderInfo.consignee))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "wz" }, [
                              _c("span", { staticClass: "smtit" }, [
                                _vm._v("收货地址：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "inf" }, [
                                _vm._v(_vm._s(_vm.orderInfo.address))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "wz" }, [
                              _c("span", { staticClass: "smtit" }, [
                                _vm._v("手机号码：")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "inf" }, [
                                _vm._v(_vm._s(_vm.orderInfo.mobile))
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "txtbox" }, [
                            _vm.orderInfo.orderStatus === 501
                              ? _c("p", { staticClass: "wz" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "smtit",
                                      staticStyle: { width: "110px" }
                                    },
                                    [_vm._v("拒绝退货原因：")]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "inf" }, [
                                    _c("i", [
                                      _vm._v(_vm._s(_vm.orderInfo.rejectReason))
                                    ])
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderInfo.orderStatus === 502
                              ? _c("p", { staticClass: "wz" }, [
                                  _c("span", { staticClass: "inf" }, [
                                    _c("i", [
                                      _vm._v(_vm._s(_vm.orderInfo.rejectReason))
                                    ])
                                  ])
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.orderInfo.orderStatus === 502
                            ? _c(
                                "button",
                                {
                                  staticClass: "paybtn animate-btn",
                                  on: { click: _vm.handleLogisticsInfor }
                                },
                                [
                                  _c("p", { staticClass: "p1" }, [
                                    _vm._v("退货快递信息")
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "p2" }, [
                                    _vm._v("退货快递信息")
                                  ])
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.orderInfo.orderManuscriptList &&
                  _vm.orderInfo.orderManuscriptList.length > 0
                    ? _c("table", { staticClass: "table2 mt_20" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.orderInfo.orderManuscriptList, function(
                            item,
                            index
                          ) {
                            return _c("tr", { key: index }, [
                              _c("td", { staticClass: "global_text_center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "goods-box",
                                    on: {
                                      click: function($event) {
                                        return _vm.goToWorksDetail(
                                          item.manuscriptId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "imgbox" },
                                      [
                                        _c("el-image", {
                                          staticClass: "img",
                                          attrs: {
                                            src: _vm.BaseUrl + item.worksCover,
                                            alt: "",
                                            fit: "scale-down"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "bgimg" })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "title hide-line2" },
                                      [_vm._v(_vm._s(item.worksName))]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "global_text_center" }, [
                                _vm._v(_vm._s(_vm._f("parseMoney")(item.price)))
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "global_text_center" }, [
                                _vm._v(_vm._s(item.number))
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("CommonDialog", {
        ref: "dialog",
        attrs: { content: _vm.dContent, callback: _vm.submitDialogCallback }
      }),
      _vm._v(" "),
      _c("OrderPaperApplyReturn", {
        attrs: { show: _vm.isShowApplyReturn, orderSn: _vm.orderSn },
        on: { returnApplySuccess: _vm.getDetail }
      }),
      _vm._v(" "),
      _c("OrderPaperReturnLogistics", {
        attrs: { show: _vm.isShowReturnLogistics, orderSn: _vm.orderSn },
        on: { trackingSuccess: _vm.getDetail }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "top-info" }, [
        _c("th", { staticClass: "global_text_center" }, [_vm._v("作品")]),
        _vm._v(" "),
        _c("th", { staticClass: "global_text_center width_100" }, [
          _vm._v("作品价格")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "global_text_center width_100" }, [
          _vm._v("作品数量")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }