import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * web首页作品导航分类
 */
import { listCategory, createCategory, updateCategory, deleteCategory, readParentCategory, disablingCategory, resumingCategory } from '@/api/manuscript/category';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

var statusMap = {
  0: '启用',
  1: '禁用'
};

export default {
  name: 'Category',
  components: { Treeselect: Treeselect },
  data: function data() {
    return {
      statusMap: statusMap,
      loading: true,
      showSearch: true,
      categoryList: [],
      categoryOptions: [],
      parentCategory: [], // 一二级分类(启用状态)
      title: '',
      open: false,
      isExpandAll: true, // 是否展开，默认全部展开
      refreshTable: true, // 重新渲染表格状态
      listQuery: {
        title: undefined,
        deleted: undefined
      },
      form: {},
      rules: {
        parentId: [{ required: true, message: '上级分类不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '分类名称不能为空', trigger: 'blur' }],
        sortOrder: [{ required: true, message: '显示排序不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      listCategory(this.listQuery).then(function (response) {
        _this.categoryList = _this.handleTree(response.data.data.list);
        _this.loading = false;
      });
    },

    /** 转换分类数据结构 */
    normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },

    // 取消按钮
    cancel: function cancel() {
      this.open = false;
      this.reset();
    },

    // 表单重置
    reset: function reset() {
      this.form = {
        id: undefined,
        parentId: undefined,
        name: undefined,
        level: undefined,
        sortOrder: undefined,
        desc: undefined
      };
      this.resetForm('form');
    },

    /** 搜索按钮操作 */
    handleQuery: function handleQuery() {
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },

    /** 新增按钮操作 */
    handleCreate: function handleCreate(row) {
      var _this2 = this;

      this.reset();
      if (row != undefined) {
        this.title = '添加分类';
        this.form.parentId = row.id;
        readParentCategory(0).then(function (response) {
          _this2.parentCategory = response.data.data;
          _this2.categoryOptions = _this2.handleTree(response.data.data);
        });
      } else {
        // 添加一级分类
        this.title = '添加一级分类';
        this.form.parentId = 0;
        this.form.level = 1;
      }
      this.open = true;
    },

    /** 展开/折叠操作 */
    toggleExpandAll: function toggleExpandAll() {
      var _this3 = this;

      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(function () {
        _this3.refreshTable = true;
      });
    },
    handleSelect: function handleSelect(node) {
      // console.log(node)
    },

    /** 修改按钮操作 */
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.reset();
      this.title = '修改分类';
      this.open = true;
      readParentCategory(row.id).then(function (response) {
        _this4.parentCategory = response.data.data;
        _this4.categoryOptions = _this4.handleTree(response.data.data);
        _this4.form = _Object$assign({}, row);
      });
    },

    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this5 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this5.form.parentId !== 0) {
            for (var i = 0; i < _this5.parentCategory.length; i++) {
              if (_this5.parentCategory[i].id === _this5.form.parentId) {
                _this5.form.level = _this5.parentCategory[i].level + 1;
                break;
              }
            }
          }

          if (_this5.form.id != undefined) {
            delete _this5.form['children']; // 删除属性
            updateCategory(_this5.form).then(function (response) {
              _this5.notifySuccess('修改成功');
              _this5.open = false;
              _this5.getList();
            }).catch(function (response) {
              _this5.notifyError(response.data.msg);
            });
          } else {
            createCategory(_this5.form).then(function (response) {
              _this5.notifySuccess('新增成功');
              _this5.open = false;
              _this5.getList();
            }).catch(function (response) {
              _this5.notifyError(response.data.msg);
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      console.log(row);
      this.$confirm('是否确认删除名称为"' + row.name + '"的数据项？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteCategory(row.id).then(function (response) {
          _this6.notifySuccess('删除成功');
          _this6.getList();
        }).catch(function (response) {
          _this6.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleStatusChange: function handleStatusChange(row) {
      var _this7 = this;

      var text = row.deleted === true ? '禁用' : '启用';
      this.$confirm('确认要' + text + '"' + row.name + '"分类吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.deleted = !row.deleted;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this8 = this;

      if (row.deleted === false) {
        // 停用改成启用
        resumingCategory(row.id).then(function (response) {
          _this8.getList();
          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      } else {
        // 启用改成停用
        disablingCategory(row.id).then(function (response) {
          _this8.getList();
          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      }
    }
  }
};