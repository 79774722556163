//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listMail, updateMail, detailMail } from '@/api/member/mailTemplate';
import EditorTinyMce from '@/components/Editor';

var textMap = {
  update: '编辑',
  create: '创建'
};

export default {
  name: 'MailTemplate',
  components: { EditorTinyMce: EditorTinyMce },
  data: function data() {
    return {
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        name: undefined
      },
      downloadLoading: false,
      dataForm: {
        id: undefined,
        mailName: undefined,
        mailContent: undefined,
        mailKey: undefined
      },
      picPaths: [], //上传图片的返回访问路径集合
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: textMap,
      rules: {
        mailName: [{ required: true, message: '邮件标题不能为空', trigger: 'blur' }],
        mailContent: [{ required: true, message: '邮件内容不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listMail(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleDetal: function handleDetal(row) {
      var _this2 = this;

      this.dataForm.mailContent = '';
      detailMail(row.mailKey).then(function (res) {
        _this2.dataForm = res.data.data;
        _this2.dialogStatus = 'update';
        _this2.dialogFormVisible = true;
        _this2.$nextTick(function () {
          _this2.$refs.tinyMce.clearUrlPaths(); //清除Editor上传的文件路径
          _this2.$refs['dataForm'].clearValidate();
        });
      }).catch(function (err) {
        _this2.notifyError(err.data.msg);
      });
    },
    updateData: function updateData() {
      var _this3 = this;

      this.dataForm.mailContent = this.$refs.tinyMce.getContent();
      this.dataForm.mailContentPaths = this.$refs.tinyMce.getUrlPaths();

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateMail(_this3.dataForm).then(function () {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.notifySuccess('更新成功');
          }).catch(function (response) {
            _this3.notifyError(response.data.msg);
          });
        }
      });
    }
  }
};