//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '@/store';
import { updateUserProfile } from '@/api/admin/profile';

export default {
  props: {
    user: {
      type: Object
    }
  },
  data: function data() {
    return {
      rules: {
        username: [{ required: true, message: "用户名不能为空", trigger: "blur" }],
        mail: [{ required: true, message: "邮箱地址不能为空", trigger: "blur" }, { type: "email", message: "'请输入正确的邮箱地址", trigger: ["blur", "change"] }],
        tel: [{ required: true, message: "手机号码不能为空", trigger: "blur" }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }]
      }
    };
  },

  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          updateUserProfile(_this.user).then(function (res) {
            store.commit('SET_USER', _this.user);
            _this.notifySuccess('修改成功');
          }).catch(function (res) {
            _this.notifyError(res.data.msg);
          });
        }
      });
    },
    close: function close() {
      this.$store.dispatch('delView', this.$route);
      this.$router.push({ path: '/' });
    }
  }
};