import request from '@/utils/request';
import Qs from 'qs';

export function listWorks(params) {
  return request({
    url: '/manuscript/works/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 投稿作品详情查询
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function readWorks(manuscriptId) {
  return request({
    url: '/manuscript/works/read/' + manuscriptId,
    method: 'get'
  });
}

/**
 * 稿件作品创建
 * @param {*} data 
 * @returns 
 */
export function createWorks(data) {
  return request({
    url: '/manuscript/works/create',
    method: 'POST',
    data: data
  });
}

/**
 * 更新投稿作品的基本信息
 * @param {*} data 
 * @returns 
 */
export function updateWorks(data) {
  return request({
    url: '/manuscript/works/update',
    method: 'put',
    data: data
  });
}

export function deleteWorks(id) {
  return request({
    url: '/manuscript/works/delete/' + id,
    method: 'delete'
  });
}

/**
 * 验证指定稿件能否在线编辑
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function validateWorks(manuscriptId) {
  return request({
    url: '/manuscript/works/validated/' + manuscriptId,
    method: 'PUT'
  });
}

/**
 * 对指定稿件的在线编辑进行解锁
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function unlockWorks(manuscriptId) {
  return request({
    url: '/manuscript/works/unlock/' + manuscriptId,
    method: 'PUT'
  });
}

/**
 * 审核投稿作品
 * @param {稿件Id} manuscriptId 
 * @param {审核说明} data 
 * @returns 
 */
export function approveWorks(manuscriptId, data) {
  return request({
    url: '/manuscript/works/approve/' + manuscriptId,
    method: 'PUT',
    data: data
  });
}

/**
 * 批注后回传保存稿件批注数据
 * @param {稿件Id} manuscriptId 
 * @param {批注数据} data 
 * @returns 
 */
export function savePdfJson(manuscriptId, data) {
  return request({
    url: '/manuscript/works/file/save/' + manuscriptId,
    method: 'POST',
    data: data
  });
}

/**
 * 查看稿件拆分的数据
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function readSplitWorks(manuscriptId) {
  return request({
    url: '/manuscript/works/read/split/' + manuscriptId,
    method: 'GET'
  });
}

/**
 * 自动拆分章节操作
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function autoSplitWorks(manuscriptId) {
  return request({
    url: '/manuscript/works/auto/split/' + manuscriptId,
    method: 'PUT'
  });
}

/**
 * 更新章节拆分的信息
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function updateSplitWorks(manuscriptId, data) {
  return request({
    url: '/manuscript/works/update/split/' + manuscriptId,
    method: 'PUT',
    data: data
  });
}

/**
 * 添加到销售作品
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function addWorksSale(manuscriptId) {
  return request({
    url: '/manuscript/works/add/sale/' + manuscriptId,
    method: 'POST'
  });
}

/**
 * 【作品管理】作品项目信息申请单提交三审
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function sendWorsToErp(manuscriptId) {
  return request({
    url: '/manuscript/works/send/erp/' + manuscriptId,
    method: 'PUT'
  });
}

// -------------------实物书------------------------------------------

/**
 * 列表查询实物作品
 * @param {作品Id} params 
 * @returns 
 */
export function listMandocWorks(params) {
  return request({
    url: '/mandoc/works/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 实物作品详情查询
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function readMandocWorks(manuscriptId) {
  return request({
    url: '/mandoc/works/read/' + manuscriptId,
    method: 'get'
  });
}

/**
 * 添加到销售作品
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function addMandocWorksSale(manuscriptId) {
  return request({
    url: '/mandoc/works/add/sale/' + manuscriptId,
    method: 'POST'
  });
}

/**
 * 实物作品更新
 * @param {*} data 
 * @returns 
 */
export function updateMandocWorks(data) {
  return request({
    url: '/mandoc/works/update',
    method: 'put',
    data: data
  });
}

//--------------------销售作品-----------------------------------------
/**
 * 列表查询销售作品
 * @param {*} params 
 * @returns 
 */
export function listSaleWorks(params) {
  return request({
    url: '/manuscript/sale/works/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 销售作品详情查询
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function readSaleWorks(manuscriptId) {
  return request({
    url: '/manuscript/sale/works/read/' + manuscriptId,
    method: 'get'
  });
}

/**
 * 下架
 */
export function disablingSaleWorks(id) {
  return request({
    url: '/manuscript/sale/works/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 上架
 */
export function resumingSaleWorks(id) {
  return request({
    url: '/manuscript/sale/works/resuming/' + id,
    method: 'PUT'
  });
}

/**
 * 销售作品推荐
 * @param {*} manuscriptId 
 * @param {是否推荐(0 否; 1 是)} isRecommend 
 * @returns 
 */
export function recommendSaleWorks(manuscriptId, isRecommend) {
  return request({
    url: '/manuscript/sale/works/recommend/' + manuscriptId + '/' + isRecommend,
    method: 'PUT'
  });
}

/**
 * 查看稿件章节的数据
 * @param {稿件Id} manuscriptId 
 * @returns 
 */
export function readSplitSaleWorks(manuscriptId) {
  return request({
    url: 'manuscript/sale/works/read/split/' + manuscriptId,
    method: 'GET'
  });
}

/**
 * 销售作品更新基本信息
 */
export function updateSaleWorks(data) {
  return request({
    url: '/manuscript/sale/works/update',
    method: 'PUT',
    data: data
  });
}

/**
 * 更新指定章节数据
 */
export function updateSaleWorksChapter(data) {
  return request({
    url: '/manuscript/sale/works/update/chapter',
    method: 'PUT',
    data: data
  });
}