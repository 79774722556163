import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listAuthor, recommendAuthor } from '@/api/author/author';
import { pickerOptions } from '@/utils/index';

var approveMap = {
  0: '申请中',
  1: '审核通过',
  2: '审核拒绝'
};
export default {
  name: 'Author',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        userId: undefined,
        name: undefined,
        approveStatusArray: [],
        dateRange: undefined
      },
      approveMap: approveMap,
      pickerOptions: {
        shortcuts: []
      },
      searchTime: [],
      downloadLoading: false
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      listAuthor(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/author/authorDetail', query: { authorId: row.authorId } });
    },
    handleRecommendChange: function handleRecommendChange(row) {
      var _this2 = this;

      var text = row.isRecommend === 0 ? '取消' : '启用';
      this.$confirm('确认要"' + text + '""' + row.authorName + '"为推荐作者吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        recommendAuthor(row.authorId, row.isRecommend).then(function (response) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = _getIterator(_this2.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var v = _step.value;

              if (v.authorId === row.authorId) {
                var index = _this2.list.indexOf(v);
                _this2.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _this2.notifySuccess(text + '成功');
        }).catch(function (response) {
          _this2.notifyError(response.data.msg);
        });
      }).catch(function () {
        // 取消操作
        row.isRecommend = row.isRecommend === 1 ? 0 : 1;
      });
    },
    handleDownload: function handleDownload() {
      var _this3 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['用户名', '手机号码', '性别', '生日', '状态'];
        var filterVal = ['username', 'mobile', 'gender', 'birthday', 'status'];
        excel.export_json_to_excel2(tHeader, _this3.list, filterVal, '用户信息');
        _this3.downloadLoading = false;
      });
    }
  }
};