//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pickerOptions } from '@/utils/index';
import { userAmounts } from '@/api/stat/stat';

var amountTypeArr = ['充值', '电子作品', '实物作品', '装订成册'];

export default {
  name: 'UserAmounts',
  data: function data() {
    return {
      showSearch: true,
      amountTypeArr: amountTypeArr,
      list: [],
      total: 0,
      listLoading: true,
      searchTime: [],
      listQuery: {
        page: 1,
        size: 20,
        userId: undefined,
        dateRange: undefined,
        type: -1
      },
      pickerOptions: {
        shortcuts: []
      },
      typeOptions: [{ key: -1, display_name: '全部' }, { key: 0, display_name: '充值' }, { key: 1, display_name: '电子作品' }, { key: 2, display_name: '纸质作品' }, { key: 3, display_name: '装订成册' }]
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getDefaultSearchDate();
    this.getList();
  },

  methods: {
    getDefaultSearchDate: function getDefaultSearchDate() {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
      this.searchTime = [];
      this.searchTime.push(this.parseTime(start, '{y}-{m}-{d}'));
      this.searchTime.push(this.parseTime(end, '{y}-{m}-{d}'));
    },
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      userAmounts(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function (err) {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
        _this.notifyError(err.data.msg);
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetQuery: function resetQuery() {
      this.listQuery = {
        page: 1,
        size: 20,
        userId: undefined,
        dateRange: undefined,
        type: -1
      };
      this.getDefaultSearchDate();
      this.getList();
    }
  }
};