import _getIterator from 'babel-runtime/core-js/get-iterator';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listNotice, deleteNotice, resumingNotice, disablingNotice } from '@/api/system/notice';
import BackToTop from '@/components/BackToTop';

export default {
  name: 'Notice',
  components: { BackToTop: BackToTop },
  data: function data() {
    return {
      showSearch: true,
      single: true,
      ids: [],
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        title: undefined
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listNotice(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.single = selection.length != 1;
    },
    handleCreate: function handleCreate() {
      this.$router.push({ path: '/sys/noticeCreate' });
    },
    handleUpdate: function handleUpdate(row) {
      if (row.id) {
        this.$router.push({ path: '/sys/noticeCreate', query: { id: row.id } });
      } else {
        //顶部编辑
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = _getIterator(this.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var v = _step.value;

            if (v.id === this.ids[0]) {
              this.$router.push({ path: '/sys/noticeCreate', query: { id: v.id } });
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
    },
    handleStatusChange: function handleStatusChange(row) {
      var _this2 = this;

      var text = row.deleted === true ? '禁用' : '启用';
      this.$confirm('确认要"' + text + '"该公告吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.deleted = !row.deleted;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this3 = this;

      if (row.deleted === false) {
        // 停用改成启用
        resumingNotice(row.id).then(function (response) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = _getIterator(_this3.list), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var v = _step2.value;

              if (v.id === row.id) {
                var index = _this3.list.indexOf(v);
                _this3.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          _this3.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this3.notifyError(response.data.msg);
        });
      } else {
        // 启用改成停用
        disablingNotice(row.id).then(function (response) {
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = _getIterator(_this3.list), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var v = _step3.value;

              if (v.id === row.id) {
                var index = _this3.list.indexOf(v);
                _this3.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          _this3.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this3.notifyError(response.data.msg);
        });
      }
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/sys/noticeCreate', query: { id: row.id } });
    },
    handleDelete: function handleDelete(row) {
      var _this4 = this;

      this.$confirm('确认要删除ID为"' + row.id + '"的公告吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteNotice(row.id).then(function (response) {
          _this4.notifySuccess('删除成功');
          _this4.getList();
        }).catch(function (response) {
          _this4.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    }
  }
};