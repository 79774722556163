//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listMandocWorks, readMandocWorks, updateMandocWorks, addMandocWorksSale } from '@/api/manuscript/works';
import { listManuscriptTag } from '@/api/manuscript/tag';
import { listCategory } from "@/api/manuscript/category";
import { listStoryclass } from "@/api/manuscript/storyclass";
import { listAuthorLibrary, listAuthorCategory } from '@/api/author/author';
import { uploadPath } from '@/api/storage';

import { getParentAndCurrNode } from '@/utils/index.js';

import AuthImgPreview from '@/components/AuthImgPreview';
import ImageUpload from '@/components/ImageUpload';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: 'PaperWorks',
  components: { AuthImgPreview: AuthImgPreview, ImageUpload: ImageUpload, Treeselect: Treeselect },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      uploadPath: uploadPath,
      showSearch: true,
      categorys: [],
      categoryOptions: [],
      storyclassOptions: [],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        name: undefined,
        categoryId: undefined,
        storyclassId: undefined
      },
      preImageUrl: '',
      imageDialogVisible: false,
      tagList: [], //稿件标签
      dialogFormVisible: false,
      dataForm: {
        addTime: undefined,
        authorLibraryId: undefined,
        categoryId: undefined,
        id: undefined,
        otherTime: undefined,
        showTime: undefined,
        tagId: undefined,
        userCardPaths: [],
        worksCover: undefined,
        worksDesc: undefined,
        worksKeyword: undefined,
        worksName: undefined,
        worksPrice: 0,
        freightPrice: 0 //快递运费
      },
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      rules: {
        worksName: [{ required: true, message: '作品名称不能为空', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请选择作品分类', trigger: 'blur' }],
        storyclassId: [{ required: true, message: '请选择作品分类', trigger: 'blur' }],
        tagId: [{ required: true, message: '请选择作品标签', trigger: 'blur' }],
        worksDesc: [{ required: true, message: '作品简介不能为空', trigger: 'blur' }],
        worksKeyword: [{ required: true, message: '作品关键字不能为空', trigger: 'blur' }]
      },
      worksCoverRules: [{ required: true, message: '请上传作品封面', validator: this.worksCoverValidator }],
      authorIdRules: [{ required: true, message: '请选择作品作者', validator: this.authorIdValidator }],
      authorLibItem: {},
      authorDialogVisible: false,
      authorTotal: 0,
      authorListQuery: {
        page: 1,
        size: 10,
        authorName: undefined,
        deleted: 0,
        categoryId: undefined
      },
      authorList: [],
      authorCategorys: [], //作者库作者分类
      authorListLoading: false
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    listCategory().then(function (response) {
      //作品导航分类
      _this.categorys = response.data.data.list;
      _this.categoryOptions = _this.handleTree(response.data.data.list);
    });
    listStoryclass().then(function (response) {
      //作品分类
      _this.storyclassOptions = _this.handleTree(response.data.data.list, 'id', 'tid');
    });
    var param = { 'page': 1, 'size': 10000 };
    listManuscriptTag(param).then(function (response) {
      //作品标签
      _this.tagList = response.data.data.list;
    });
    listAuthorCategory().then(function (res) {
      //作者分类
      _this.authorCategorys = res.data.data.list;
    });
  },

  methods: {
    worksCoverValidator: function worksCoverValidator(rule, value, callback) {
      if (!this.dataForm.worksCover) {
        callback(new Error('请上传作品封面'));
      } else {
        callback();
      }
    },
    authorIdValidator: function authorIdValidator(rule, value, callback) {
      if (!this.dataForm.authorLibraryId) {
        callback(new Error('请选择作品作者'));
      } else {
        callback();
      }
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listMandocWorks(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
        _this2.notifyError(res.data.msg);
      });
    },

    /** 转换分类数据结构 */
    normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    formatAuthorCategoryName: function formatAuthorCategoryName(row, column) {
      var categoryName = '';
      var arrNodes = getParentAndCurrNode(this.authorCategorys, row.categoryId);
      for (var i = arrNodes.length - 1; i >= 0; i--) {
        categoryName += arrNodes[i].name + '/';
      }
      return categoryName ? categoryName.substring(0, categoryName.length - 1) : '';
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelect: function handleSelect(node) {
      // console.log(node)
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/manuscript/paperWorksDetail', query: { id: row.id } });
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    },
    getWorksCoverUrl: function getWorksCoverUrl(imgUrl) {
      this.dataForm.worksCover = imgUrl;
    },
    handleSelectAuthor: function handleSelectAuthor() {
      this.authorDialogVisible = !this.authorDialogVisible;
      this.getAuthorList();
    },
    getAuthorList: function getAuthorList() {
      var _this3 = this;

      this.authorListLoading = true;
      listAuthorLibrary(this.authorListQuery).then(function (response) {
        var authorDatas = response.data.data.list.map(function (v) {
          //去除不必要的属性，提高渲染速度
          return {
            authorLibraryId: v.authorLibraryId,
            authorName: v.authorName,
            authorTel: v.authorTel,
            categoryId: v.categoryId,
            authorDesc: v.authorDesc,
            createTime: v.createTime
          };
        });
        _this3.authorList = authorDatas;
        _this3.authorTotal = response.data.data.total;
        _this3.authorListLoading = false;
      }).catch(function () {
        _this3.authorList = [];
        _this3.authorTotal = 0;
        _this3.authorListLoading = false;
      });
    },
    handleAuthorFilter: function handleAuthorFilter() {
      this.authorListQuery.page = 1;
      this.getAuthorList();
    },

    /** 重置按钮操作 */
    resetAuthorQuery: function resetAuthorQuery() {
      this.resetForm("queryAuthorForm");
      this.handleAuthorFilter();
    },
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.dataForm.worksKeyword = this.keywords.toString();
    },
    showInput: function showInput() {
      var _this4 = this;

      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this4.$refs.newKeywordInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;
      if (newKeyword && this.keywords.indexOf(newKeyword) < 0) {
        this.keywords.push(newKeyword);
        this.dataForm.worksKeyword = this.keywords.toString();
      }
      this.newKeywordVisible = false;
      this.newKeyword = '';
    },
    handleSingleElection: function handleSingleElection(row) {
      this.authorLibItem = row;
    },
    handleConfirmSelectAuthor: function handleConfirmSelectAuthor() {
      this.dataForm.authorLibraryId = this.authorLibItem.authorLibraryId;
      this.dataForm.authorName = this.authorLibItem.authorName;
      this.authorDialogVisible = !this.authorDialogVisible;
    },
    reset: function reset() {
      this.dataForm = {
        addTime: undefined,
        authorLibraryId: undefined,
        categoryId: undefined,
        id: undefined,
        otherTime: undefined,
        showTime: undefined,
        tagId: undefined,
        userCardPaths: [],
        worksCover: undefined,
        worksDesc: undefined,
        worksKeyword: undefined,
        worksName: undefined,
        worksPrice: undefined,
        freightPrice: undefined
      };
      this.resetForm("dataForm");
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      readMandocWorks(row.id).then(function (res) {
        _this5.dataForm = res.data.data;
        _this5.keywords = [];
        if (_this5.dataForm.worksKeyword !== null && _this5.dataForm.worksKeyword !== '') {
          _this5.keywords = _this5.dataForm.worksKeyword.split(',');
        }
        _this5.dialogFormVisible = true;
        _this5.$nextTick(function () {
          _this5.$refs['dataForm'].clearValidate();
        });
      }).catch(function (response) {
        _this5.notifyError(response.data.msg);
      });
    },
    updateData: function updateData() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateMandocWorks(_this6.dataForm).then(function () {
            _this6.getList();
            _this6.reset();
            _this6.dialogFormVisible = false;
            _this6.authorLibItem = {}; //成功，置空
            _this6.notifySuccess('更新成功');
          }).catch(function (response) {
            _this6.notifyError(response.data.msg);
          });
        }
      });
    },
    handleAddSale: function handleAddSale(row) {
      var _this7 = this;

      this.$confirm('确认要将"' + row.worksName + '"添加为销售作品吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        addMandocWorksSale(row.id).then(function (response) {
          _this7.notifySuccess('添加成功');
          _this7.getList();
        }).catch(function (response) {
          _this7.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    }
  }
};