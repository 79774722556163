import request from '@/utils/request';
import Qs from 'qs';

/**
 * 会员统计
 * @param {*} params 
 * @returns 
 */
export function userStat(params) {
  return request({
    url: '/statistics/user/stat?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 会员充值统计
 * @param {*} params 
 * @returns 
 */
export function userRechargeStat(params) {
  return request({
    url: '/statistics/user/recharge/stat?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 会员账户明细分析
 * @param {*} params 
 * @returns 
 */
export function userAmounts(params) {
  return request({
    url: '/statistics/user/amounts?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 商品销售统计
 * @param {*} params 
 * @returns 
 */
export function worksSaleStat(params) {
  return request({
    url: '/statistics/works/sales/stat?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 商品分类标签销售统计
 * @param {*} params 
 * @returns 
 */
export function worksTagSaleStat(params) {
  return request({
    url: '/statistics/works/tag/sales/stat?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 商品访问购买率统计
 * @param {*} params 
 * @returns 
 */
export function worksSaleRecordStat(params) {
  return request({
    url: '/statistics/works/record/user/stat?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}