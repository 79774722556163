//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listPrintingOrder, printingOffer, listTaskPrinting, printingOrderDetail, printingCreate, printingUpdate, printingSubCreate, printingSubUpdate } from '@/api/printing/printing.js';
import { pickerOptions } from '@/utils/index';

var statusMap = {
  0: '竞标中',
  1: '已结束'
};

var textMap = {
  update: '编辑',
  create: '创建'
};

export default {
  name: 'PrintingOrder',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        invname: undefined,
        status: [],
        dateRange: undefined
      },
      statusMap: statusMap,
      textMap: textMap,
      pickerOptions: {
        shortcuts: []
      },
      searchTime: [],
      printingDialogVisible: false,
      listQueryPrinting: {
        cbillid: undefined,
        page: 1,
        size: 10,
        userId: undefined,
        custname: undefined,
        dateRange: undefined
      },
      printingList: [],
      printingTotal: 0,
      printingItem: {},
      offerForm: {
        cbillid: undefined,
        userId: undefined
      },
      printingLoading: false,
      downloadLoading: false,
      dialogFormVisible: false,
      dialogStatus: '',
      dataForm: {
        invcode: undefined,
        invname: undefined,
        iversionno: undefined,
        iprintno: undefined,
        refsaleprice: undefined,
        psnname: undefined,
        ctectorialmode: undefined,
        productsize: undefined,
        cbooksizeid: undefined,
        ncopies: undefined,
        cbindtype: undefined,
        nprintnum: undefined,
        itotalpagenum: undefined,
        spine: undefined,
        zhigui: undefined,
        completedate: undefined,
        deliverydate: undefined
      },
      rules: {
        invcode: [{ required: true, message: '存货编码不能为空', trigger: 'blur' }],
        invname: [{ required: true, message: '印单名称不能为空', trigger: 'blur' }]
      },
      dialogMaterialVisible: false,
      sublist: [],
      sublistLoading: false,
      cbillid: '', // 印单id
      dialogSubFormVisible: false,
      dataSubForm: {
        vclassname: undefined,
        iprintmethod: undefined,
        cpositivecolor: undefined,
        cnegativecolor: undefined,
        icolorvalue: undefined,
        imianshu: undefined,
        isize: undefined,
        ipagenum: undefined,
        ncopies: undefined,
        nsharenum: undefined,
        cailiao: undefined
      },
      rulesSublist: {
        vclassname: [{ required: true, message: '用途项目不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      listPrintingOrder(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/printing/printingOrderDetail', query: { cbillid: row.cbillid } });
    },
    handlePrintingFilter: function handlePrintingFilter() {
      this.listQueryPrinting.page = 1;
      this.getPrintingList();
    },
    resetPrintingQuery: function resetPrintingQuery() {
      this.resetForm("queryPrintingForm");
      this.searchTime = [];
      this.handlePrintingFilter();
    },
    getPrintingList: function getPrintingList() {
      var _this2 = this;

      this.listQueryPrinting.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQueryPrinting.dateRange = [];
        this.listQueryPrinting.dateRange.push(this.searchTime[0]);
        this.listQueryPrinting.dateRange.push(this.searchTime[1]);
      }
      this.listQueryPrinting.cbillid = this.offerForm.cbillid;
      this.printingLoading = true;
      listTaskPrinting(this.listQueryPrinting).then(function (response) {
        _this2.printingList = response.data.data.list;
        _this2.printingTotal = response.data.data.total;
        _this2.printingLoading = false;
      }).catch(function () {
        _this2.printingList = [];
        _this2.printingTotal = 0;
        _this2.printingLoading = false;
      });
    },
    handleOffer: function handleOffer(row) {
      this.offerForm.cbillid = row.cbillid;
      this.printingDialogVisible = true;
      this.searchTime = [];
      this.getPrintingList();
    },
    handleSingleElection: function handleSingleElection(row) {
      this.printingItem = row;
      this.offerForm.userId = row.userId;
    },
    handleConfirmSelectPrinting: function handleConfirmSelectPrinting() {
      var _this3 = this;

      if (!this.offerForm.userId) {
        return this.notifyError('请选择竞标印刷厂');
      }
      this.$confirm('确认选择"' + this.printingItem.custname + '"为承印方吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        printingOffer(_this3.offerForm.cbillid, _this3.offerForm.userId).then(function (response) {
          _this3.printingDialogVisible = false;
          _this3.notifySuccess('操作成功');
          _this3.offerForm.cbillid = _this3.offerForm.userId = undefined;
          _this3.printingItem = {};
          _this3.getList();
        }).catch(function (response) {
          _this3.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    dialogClose: function dialogClose() {
      //对话框关闭
      this.searchTime = [];
    },
    reset: function reset() {
      this.dataForm = {
        invcode: undefined,
        invname: undefined,
        iversionno: undefined,
        iprintno: undefined,
        refsaleprice: undefined,
        psnname: undefined,
        ctectorialmode: undefined,
        productsize: undefined,
        cbooksizeid: undefined,
        ncopies: undefined,
        cbindtype: undefined,
        nprintnum: undefined,
        itotalpagenum: undefined,
        spine: undefined,
        zhigui: undefined,
        completedate: undefined,
        deliverydate: undefined
      };
      this.resetForm("dataForm");
    },
    handleCreate: function handleCreate() {
      var _this4 = this;

      this.reset();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this5 = this;

      this.$refs.dataForm.validate(function (valid) {
        if (valid) {
          printingCreate(_this5.dataForm).then(function (res) {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.notifySuccess("新增印单成功");
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this6 = this;

      printingOrderDetail(row.cbillid).then(function (res) {
        _this6.dataForm = res.data.data;
        delete _this6.dataForm.sublist;
        delete _this6.dataForm.biddingPrinting;

        _this6.dialogStatus = 'update';
        _this6.dialogFormVisible = true;
        _this6.$nextTick(function () {
          _this6.$refs['dataForm'].clearValidate();
        });
      }).catch(function (response) {
        _this6.notifyError(response.data.msg);
      });
    },
    updateData: function updateData() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          printingUpdate(_this7.dataForm).then(function () {
            _this7.getList();
            _this7.dialogFormVisible = false;
            _this7.notifySuccess('更新印单成功');
          }).catch(function (response) {
            _this7.notifyError(response.data.msg);
          });
        }
      });
    },
    handleMaterial: function handleMaterial(row) {
      var _this8 = this;

      this.sublist = [];

      this.cbillid = row.cbillid;
      this.sublistLoading = true;
      printingOrderDetail(row.cbillid).then(function (res) {
        _this8.sublist = res.data.data.sublist;

        _this8.sublistLoading = false;
        _this8.dialogMaterialVisible = true;
      }).catch(function (response) {
        _this8.sublistLoading = false;
        _this8.notifyError(response.data.msg);
      });
    },
    getSublist: function getSublist() {
      var _this9 = this;

      this.sublistLoading = true;
      printingOrderDetail(this.cbillid).then(function (res) {
        _this9.sublist = res.data.data.sublist;

        _this9.sublistLoading = false;
      }).catch(function (response) {
        _this9.sublistLoading = false;
        _this9.notifyError(response.data.msg);
      });
    },
    handleSubCreate: function handleSubCreate() {
      var _this10 = this;

      this.resetSublist();
      this.dialogStatus = 'create';
      this.dialogSubFormVisible = true;
      this.$nextTick(function () {
        _this10.$refs['dataSubForm'].clearValidate();
      });
    },
    createSublistData: function createSublistData() {
      var _this11 = this;

      this.$refs.dataSubForm.validate(function (valid) {
        if (valid) {
          _this11.dataSubForm.cbillid = _this11.cbillid;
          printingSubCreate(_this11.dataSubForm).then(function (res) {
            _this11.getSublist();
            _this11.dialogSubFormVisible = false;
            _this11.notifySuccess("新增材料成功");
          }).catch(function (response) {
            _this11.notifyError(response.data.msg);
          });
        }
      });
    },
    handleSubUpdate: function handleSubUpdate(row) {
      var _this12 = this;

      this.dataSubForm = row;
      this.dialogStatus = 'update';
      this.dialogSubFormVisible = true;
      this.$nextTick(function () {
        _this12.$refs['dataSubForm'].clearValidate();
      });
    },
    updateSublistData: function updateSublistData() {
      var _this13 = this;

      this.$refs['dataSubForm'].validate(function (valid) {
        if (valid) {
          printingSubUpdate(_this13.dataSubForm).then(function () {
            _this13.getSublist();
            _this13.dialogSubFormVisible = false;
            _this13.notifySuccess('更新材料成功');
          }).catch(function (response) {
            _this13.notifyError(response.data.msg);
          });
        }
      });
    },
    resetSublist: function resetSublist() {
      this.dataSubForm = {
        vclassname: undefined,
        iprintmethod: undefined,
        cpositivecolor: undefined,
        cnegativecolor: undefined,
        icolorvalue: undefined,
        imianshu: undefined,
        isize: undefined,
        ipagenum: undefined,
        ncopies: undefined,
        nsharenum: undefined,
        cailiao: undefined
      };
      this.resetForm("dataSubForm");
    }
  }
};