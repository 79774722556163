var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.coupon
        ? _c(
            "div",
            { staticClass: "table-layout" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("优惠券名称")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("使用范围")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("最低消费")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("优惠金额")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 8 } },
                    [_vm._v("优惠券说明")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [_vm._v(_vm._s(_vm.coupon.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [_vm._v(_vm._s(_vm._f("formatType")(_vm.coupon)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [
                      _vm._v(
                        "满" + _vm._s(_vm.coupon.minConsumption) + "元可用"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [_vm._v("减免" + _vm._s(_vm.coupon.discount) + "元")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 8 } },
                    [_vm._v(_vm._s(_vm.coupon.desc))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("每人限领")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("当前状态")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("发行数量")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 4 } },
                    [_vm._v("剩余数量")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell-title", attrs: { span: 8 } },
                    [_vm._v("有效期")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [_vm._v(_vm._s(_vm.coupon.limitNumber))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [
                      _vm._v(
                        _vm._s(_vm.coupon.isOnSale === 0 ? "下架" : "上架")
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.coupon.total === 0 ? "不限" : _vm.coupon.total
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 4 } },
                    [_vm._v(_vm._s(_vm.coupon.surplus))]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "table-cell", attrs: { span: 8 } },
                    [
                      _vm._v(
                        _vm._s(
                          "自" +
                            this.coupon.startTime +
                            "至" +
                            this.coupon.endTime +
                            "有效"
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: true,
                  expression: "true"
                }
              ],
              ref: "queryForm",
              attrs: { model: _vm.listQuery, size: "small", inline: true }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户ID", prop: "userId" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入用户ID", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilter($event)
                      }
                    },
                    model: {
                      value: _vm.listQuery.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "userId", $$v)
                      },
                      expression: "listQuery.userId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用状态", prop: "statusArray" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "300px" },
                      attrs: { multiple: "", placeholder: "请选择使用状态" },
                      model: {
                        value: _vm.listQuery.statusArray,
                        callback: function($$v) {
                          _vm.$set(_vm.listQuery, "statusArray", $$v)
                        },
                        expression: "listQuery.statusArray"
                      }
                    },
                    _vm._l(_vm.useStatusOptions, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["GET /admin/coupon/user/list"],
                          expression: "['GET /admin/coupon/user/list']"
                        }
                      ],
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetQuery }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "优惠券ID",
              prop: "id",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "用户ID", prop: "userId" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "领取时间", prop: "createTime" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "使用状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatUseStatus")(scope.row.status)))
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "订单ID", prop: "orderId" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "使用时间", prop: "usedTime" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }