import request from '@/utils/request';

export function listMemberTag(query) {
  return request({
    url: '/user/tag/list',
    method: 'get',
    params: query
  });
}

export function createMemberTag(data) {
  return request({
    url: '/user/tag/create',
    method: 'post',
    data: data
  });
}

export function updateMemberTag(data) {
  return request({
    url: '/user/tag/update',
    method: 'put',
    data: data
  });
}

export function deleteMemberTag(id) {
  return request({
    url: '/user/tag/delete/' + id,
    method: 'delete'
  });
}

export function detailMemberTag(id) {
  return request({
    url: '/user/tag/read/' + id,
    method: 'get'
  });
}