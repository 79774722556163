//
//
//
//

import { getToken } from '@/utils/auth';

export default {
	name: "AuthImg",
	props: {
		authSrc: {
			type: String,
			default: ""
		}
	},
	mounted: function mounted() {
		var token = getToken();
		Object.defineProperty(Image.prototype, 'authsrc', {
			writable: true,
			enumerable: true,
			configurable: true
		});
		var img = this.$refs.img;
		var request = new XMLHttpRequest();
		request.responseType = 'blob';
		request.open('get', this.authSrc, true);
		request.setRequestHeader('AdminAuthorizationToken', token);
		request.setRequestHeader('WebHeader', 'web_pc');
		request.onreadystatechange = function (e) {
			if (request.readyState == XMLHttpRequest.DONE && request.status == 200) {
				img.src = URL.createObjectURL(request.response);
				img.onload = function () {
					URL.revokeObjectURL(img.src);
				};
			}
		};
		request.send(null);
	}
};