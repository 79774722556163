import _Promise from 'babel-runtime/core-js/promise';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/views/layout/Layout';

/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    perms: ['GET /aaa','POST /bbb']     will control the page perms (you can set multiple perms)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)(如果设置为true，则不会被 <keep-alive> 缓存(默认 false))
  }
**/
export var constantRouterMap = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/authredirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/errorPage/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/errorPage/401'));
    });
  },
  hidden: true
}, {
  path: '/manuscript-edit', // 三审系统，查看或编辑稿件
  component: function component() {
    return _Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/audit/manuscriptEdit'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      noCache: true
    }
  }]
}];

export default new Router({
  mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return { y: 0 };
  },
  routes: constantRouterMap
});

export var asyncRouterMap = [{
  path: '/member',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'memberManage',
  meta: {
    title: '会员管理',
    icon: 'peoples'
  },
  children: [{
    path: 'member',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/member/member'));
      });
    },
    name: 'Member',
    meta: {
      perms: ['GET /admin/user/list', 'GET /admin/user/read/{id}', 'PUT /admin/user/resuming/{id}', 'PUT /admin/user/disabling'],
      title: '会员管理',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'memberLabel',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/member/memberLabel'));
      });
    },
    name: 'MemberLabel',
    meta: {
      perms: ['GET /admin/user/tag/list', 'POST /admin/user/tag/create', 'PUT /admin/user/tag/update', 'DELETE /admin/user/tag/delete/{id}', 'GET /admin/user/tag/read/{id}'],
      title: '标签管理',
      icon: 'label',
      noCache: true
    }
  }, {
    path: 'mailTempldate',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/member/mailTemplate'));
      });
    },
    name: 'MailTempldate',
    meta: {
      perms: ['GET /admin/user/mail/list', 'PUT /admin/user/mail/update', 'GET /admin/user/mail/read/{key}'],
      title: '邮件模板管理',
      icon: 'email',
      noCache: true
    }
  }]
}, {
  path: '/author',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'authorManage',
  meta: {
    title: '作者管理',
    icon: 'author'
  },
  children: [{
    path: 'author',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/author/author'));
      });
    },
    name: 'Author',
    meta: {
      perms: ['GET /admin/author/list', 'GET /admin/author/read/{authorId}', 'PUT /admin/author/approve/{authorId}'],
      title: '申请作者管理',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'authorDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/author/authorDetail'));
      });
    },
    name: 'AuthorDetail',
    meta: {
      perms: ['GET /admin/author/read/{authorId}', 'PUT /admin/author/approve/{authorId}'],
      title: '作者详情',
      icon: 'user',
      noCache: true
    },
    hidden: true
  }, {
    path: 'authorCategory',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/author/authorCategory'));
      });
    },
    name: 'AuthorCategory',
    meta: {
      perms: ['PUT /admin/author/library/category/update', 'DELETE /admin/author/library/category/delete/{id}', 'GET /admin/author/library/category/read/{id}', 'POST /admin/author/library/category/create', 'GET /admin/author/library/category/list', 'PUT /admin/author/library/category/resuming/{id}', 'GET /admin/author/library/category/read/parent/{id}', 'PUT /admin/author/library/category/disabling/{id}'],
      title: '作者库分类',
      icon: 'list',
      noCache: true
    }
  }, {
    path: 'authorLibrary',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/author/authorLibrary'));
      });
    },
    name: 'AuthorLibrary',
    meta: {
      perms: ['PUT /admin/author/library/update', 'DELETE /admin/author/library/delete/{id}', 'GET /admin/author/library/read/{id}', 'POST /admin/author/library/create', 'GET /admin/author/library/list', 'PUT /admin/author/library/disabling/{id}', 'PUT /admin/author/library/resuming/{id}', 'PUT /admin/author/library/association/{authorId}/{authorApproveId}', 'GET /admin/author/list'],
      title: '作者库管理',
      icon: 'author',
      noCache: true
    }
  }]
}, {
  path: '/manuscript',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'manuscriptManage',
  meta: {
    title: '稿件管理',
    icon: 'dict'
  },
  children: [{
    path: 'storyclass',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/storyclass'));
      });
    },
    name: 'Storyclass',
    meta: {
      perms: ['GET /admin/storyclass/list', 'POST /admin/storyclass/create', 'POST /admin/storyclass/update', 'DELETE /admin/storyclass/delete/{id}', 'PUT /admin/storyclass/disabling/{id}', 'PUT /admin/storyclass/resuming/{id}', 'GET /admin/storyclass/read/parent/{id}'],
      title: '稿件分类',
      icon: 'list',
      noCache: true
    }
  }, {
    path: 'category',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/category'));
      });
    },
    name: 'Category',
    meta: {
      perms: ['GET /admin/category/list', 'POST /admin/category/create', 'POST /admin/category/update', 'DELETE /admin/category/delete/{id}', 'PUT /admin/category/disabling/{id}', 'PUT /admin/category/resuming/{id}', 'GET /admin/category/read/parent/{id}'],
      title: '作品导航分类',
      icon: 'list',
      noCache: true
    }
  }, {
    path: 'tag',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/tag'));
      });
    },
    name: 'ManuscriptTag',
    meta: {
      perms: ['GET /admin/manuscript/tag/list', 'POST /admin/manuscript/tag/create', 'PUT /admin/manuscript/tag/update', 'DELETE /admin/user/manuscript/delete/{id}', 'PUT /admin/manuscript/tag/resuming/{id}', 'PUT /admin/manuscript/tag/disabling/{id}'],
      title: '标签管理',
      icon: 'label',
      noCache: true
    }
  }, {
    path: 'keyword',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/keyword'));
      });
    },
    name: 'Keyword',
    meta: {
      perms: ['GET /admin/keyword/list', 'POST /admin/keyword/create', 'POST /admin/keyword/update', 'DELETE /admin/keyword/delete/{id}', 'PUT /admin/keyword/disabling/{id}', 'PUT /admin/keyword/resuming/{id}'],
      title: '关键字管理',
      icon: 'keyword',
      noCache: true
    }
  }, {
    path: 'works',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/works'));
      });
    },
    name: 'Works',
    meta: {
      perms: ['GET /admin/manuscript/works/list', 'GET /admin/manuscript/works/read/{manuscriptId}', 'PUT /admin/manuscript/works/update', 'POST /admin/manuscript/works/create', 'DELETE /admin/manuscript/works/delete/{id}', 'POST /admin/manuscript/works/add/sale/{manuscriptId}'],
      title: '投稿作品管理',
      icon: 'works',
      noCache: true
    }
  }, {
    path: 'worksDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/worksDetail'));
      });
    },
    name: 'WorksDetail',
    meta: {
      perms: ['POST /admin/manuscript/works/file/save/{manuscriptId}', 'GET /admin/manuscript/works/read/split/{manuscriptId}', 'PUT /admin/manuscript/works/auto/split/{manuscriptId}', 'PUT /admin/manuscript/works/validated/{manuscriptId}', 'PUT /admin/manuscript/works/approve/{manuscriptId}', 'PUT /admin/manuscript/works/update/split/{manuscriptId}', 'PUT /admin/manuscript/works/send/erp/{manuscriptId}'],
      title: '投稿作品详情',
      icon: 'works',
      noCache: true
    },
    hidden: true
  }, {
    path: 'paperWorks',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/paperWorks'));
      });
    },
    name: 'PaperWorks',
    meta: {
      perms: ['GET /admin/mandoc/works/list', 'GET /admin/mandoc/works/read/{manuscriptId}', 'PUT /admin/mandoc/works/update', 'POST /admin/mandoc/works/add/sale/{manuscriptId}'],
      title: '纸质作品管理',
      icon: 'paper-works',
      noCache: true
    }
  }, {
    path: 'paperWorksDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/paperWorksDetail'));
      });
    },
    name: 'PaperWorksDetail',
    meta: {
      perms: [],
      title: '纸质作品详情',
      icon: 'paper-works',
      noCache: true
    },
    hidden: true
  }, {
    path: 'worksSale',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/worksSale'));
      });
    },
    name: 'WorksSale',
    meta: {
      perms: ['GET /admin/manuscript/sale/works/list', 'GET /admin/manuscript/sale/works/read/{manuscriptId}', 'PUT /admin/manuscript/sale/works/disabling/{id}', 'PUT /admin/manuscript/sale/works/resuming/{id}', 'PUT /admin/manuscript/sale/works/update', 'PUT /admin/manuscript/sale/works/update/chapter', 'PUT /admin/manuscript/sale/works/recommend/{manuscriptId}/{isRecommend}'],
      title: '销售作品管理',
      icon: 'shopping',
      noCache: true
    }
  }, {
    path: 'worksSaleDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/manuscript/worksSaleDetail'));
      });
    },
    name: 'WorksSaleDetail',
    meta: {
      perms: ['GET /admin/manuscript/sale/works/read/split/{manuscriptId}'],
      title: '销售作品详情',
      icon: 'shopping',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/bookBinding',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'bookBindingManage',
  meta: {
    title: '书刊定制管理',
    icon: 'customized'
  },
  children: [{
    path: 'bookBinding',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bookbinding/index'));
      });
    },
    name: 'BookBinding',
    meta: {
      perms: ['GET /admin/bidding/list', 'GET /admin/bidding/read/{orderId}', 'PUT /admin/bidding/ship', 'PUT /admin/bidding/sure', 'PUT /admin/bidding/press/{orderId}/{number}'],
      title: '装订成册管理',
      icon: 'bookbinding',
      noCache: true
    }
  }, {
    path: 'category',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bookbinding/category'));
      });
    },
    name: 'Category',
    meta: {
      perms: ['GET /admin/bidding/option/list', 'PUT /admin/bidding/option/update'],
      title: '定制选项管理',
      icon: 'list',
      noCache: true
    }
  }]
}, {
  path: '/printing',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'printingManage',
  meta: {
    title: '印刷管理',
    icon: 'printing'
  },
  children: [{
    path: 'printingPlant',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/printing/printingPlant'));
      });
    },
    name: 'PrintingPlant',
    meta: {
      perms: ['GET /admin/printing/list', 'GET /admin/printing/read/{userId}', 'PUT /admin/printing/approve/{userId}'],
      title: '印厂会员管理',
      icon: 'printing-plant',
      noCache: true
    }
  }, {
    path: 'printingOrder',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/printing/printingOrder'));
      });
    },
    name: 'PrintingOrder',
    meta: {
      perms: ['GET /admin/press/task/list', 'GET /admin/press/task/read/{cbillid}', 'GET /admin/press/task/printing/list', 'PUT /admin/press/task/confirm/{cbillid}/{userId}', 'PUT /admin/press/task/update', 'POST /admin/press/task/create', 'PUT /admin/press/task/sub/update', 'POST /admin/press/task/sub/create'],
      title: '印单信息管理',
      icon: 'printing-order',
      noCache: true
    }
  }, {
    path: 'printingOrderDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/printing/printingOrderDetail'));
      });
    },
    name: 'PrintingOrderDetail',
    meta: {
      perms: ['GET /admin/press/task/printing/list'],
      title: '印单信息详情',
      icon: 'shoppprinting-ordering',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/recharge',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'rechargeManage',
  meta: {
    title: '充值套餐管理',
    icon: 'recharge'
  },
  children: [{
    path: 'recharge',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/recharge/recharge'));
      });
    },
    name: 'Recharge',
    meta: {
      perms: ['GET /admin/recharge/list', 'POST /admin/recharge/create', 'PUT /admin/recharge/update', 'DELETE /admin/recharge/delete/{id}', 'GET /admin/recharge/read/{id}', 'PUT /admin/recharge/disabling/{id}', 'PUT /admin/recharge/resuming/{id}'],
      title: '充值套餐管理',
      icon: 'recharge-item',
      noCache: true
    }
  }]
}, {
  path: '/promotion',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'promotionManage',
  meta: {
    title: '促销管理',
    icon: 'promotion'
  },
  children: [{
    path: 'coupon',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/promotion/coupon'));
      });
    },
    name: 'Coupon',
    meta: {
      perms: ['GET /admin/coupon/list', 'POST /admin/coupon/create', 'PUT /admin/coupon/update', 'DELETE /admin/coupon/delete/{id}', 'GET /admin/coupon/read/{id}', 'GET /admin/coupon/user/list', 'PUT /admin/coupon/sale/{couponId}/{isOnSale}'],
      title: '优惠券管理',
      icon: 'coupon',
      noCache: true
    }
  }, {
    path: 'couponDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/promotion/couponDetail'));
      });
    },
    name: 'CouponDetail',
    meta: {
      perms: ['GET /admin/coupon/read/{id}', 'GET /admin/coupon/user/list'],
      title: '优惠券详情',
      icon: 'tab',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/order',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'orderManage',
  meta: {
    title: '订单管理',
    icon: 'nested'
  },
  children: [{
    path: 'orderEbook',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/orderEbook'));
      });
    },
    name: 'OrderEbook',
    meta: {
      perms: ['GET /admin/order/one/list', 'GET /admin/order/one/read/{orderId}'],
      title: '电子书订单',
      icon: 'ebook',
      noCache: true
    }
  }, {
    path: 'orderEbookDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/orderEbookDetail'));
      });
    },
    name: 'OrderEbookDetail',
    meta: {
      perms: ['GET /admin/order/one/read/{orderId}'],
      title: '电子书订单详情',
      icon: 'ebook',
      noCache: true
    },
    hidden: true
  }, {
    path: 'orderPaperBook',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/orderPaperBook'));
      });
    },
    name: 'OrderPaperBook',
    meta: {
      perms: ['GET /admin/order/two/list', 'GET /admin/order/two/read/{orderId}', 'PUT /admin/order/two/ship'],
      title: '纸质书订单',
      icon: 'paper-works',
      noCache: true
    }
  }]
}, {
  path: '/comment',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'commentManage',
  meta: {
    title: '评论咨询管理',
    icon: 'component'
  },
  children: [{
    path: 'leavingMsg',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/comment/leavingMsg'));
      });
    },
    name: 'LeavingMsg',
    meta: {
      perms: ['GET /admin/message/list/feedback', 'PUT /admin/message/feedback/reply', 'GET /admin/message/feedback/read/{id}'],
      title: '留言管理',
      icon: 'message',
      noCache: true
    }
  }, {
    path: 'mail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/comment/mail'));
      });
    },
    name: 'Mail',
    meta: {
      perms: ['POST /admin/message/update', 'DELETE /admin/message/delete/{id}', 'GET /admin/message/read/{messageId}', 'POST /admin/message/create', 'GET /admin/message/list', 'GET /admin/message/user/list', 'POST /admin/message/user/create/{messageId}'],
      title: '站内信管理',
      icon: 'email',
      noCache: true
    }
  }, {
    path: 'mailCreate',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/comment/mailCreate'));
      });
    },
    name: 'MailCreate',
    meta: {
      perms: ['POST /admin/message/update', 'POST /admin/message/create', 'GET /admin/message/read/{messageId}'],
      title: '站内信',
      icon: 'email',
      noCache: true
    },
    hidden: true
  }, {
    path: 'mailDetail',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/comment/mailDetail'));
      });
    },
    name: 'MailDetail',
    meta: {
      perms: ['GET /admin/message/user/list', 'GET /admin/message/read/{messageId}'],
      title: '站内信详情',
      icon: 'email',
      noCache: true
    },
    hidden: true
  }]
}, {
  path: '/sys',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'sysManage',
  meta: {
    title: '系统管理',
    icon: 'system'
  },
  children: [{
    path: 'ad',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/ad'));
      });
    },
    name: 'Ad',
    meta: {
      perms: ['GET /admin/ad/list', 'POST /admin/ad/create', 'PUT /admin/ad/update', 'DELETE /admin/ad/delete/{id}', 'GET /admin/ad/read/{id}', 'PUT /admin/ad/disabling/{id}', 'PUT /admin/ad/resuming/{id}'],
      title: '广告管理',
      icon: 'ad',
      noCache: true
    }
  }, {
    path: 'notice',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/notice'));
      });
    },
    name: 'Notice',
    meta: {
      perms: ['GET /admin/notice/list', 'POST /admin/notice/create', 'DELETE /admin/notice/delete/{id}', 'GET /admin/notice/read/{id}', 'PUT /admin/notice/disabling/{id}', 'PUT /admin/notice/resuming/{id}'],
      title: '公告管理',
      icon: 'language',
      noCache: true
    }
  }, {
    path: 'noticeCreate',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/noticeCreate'));
      });
    },
    name: 'NoticeCreate',
    meta: {
      perms: ['POST /admin/notice/create', 'GET /admin/notice/read/{id}'],
      title: '公告',
      icon: 'link',
      noCache: true
    },
    hidden: true
  }, {
    path: 'faq',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/faq'));
      });
    },
    name: 'FAQ',
    meta: {
      perms: ['GET /admin/issue/list', 'POST /admin/issue/create', 'PUT /admin/issue/update', 'DELETE /admin/issue/delete/{id}'],
      title: '常见问题管理',
      icon: 'question',
      noCache: true
    }
  }, {
    path: 'rotationMap',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/rotationMap'));
      });
    },
    name: 'RotationMap',
    meta: {
      perms: ['GET /admin/carousel/list', 'POST /admin/carousel/create', 'PUT /admin/carousel/update', 'DELETE /admin/carousel/delete/{id}', 'GET /admin/carousel/read/{id}', 'PUT /admin/carousel/disabling/{id}', 'PUT /admin/carousel/resuming/{id}'],
      title: '轮播图管理',
      icon: 'rotation_map',
      noCache: true
    }
  }, {
    path: 'links',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/links'));
      });
    },
    name: 'Links',
    meta: {
      perms: ['GET /admin/blogroll/list', 'POST /admin/blogroll/create', 'PUT /admin/blogroll/update', 'DELETE /admin/blogroll/delete/{id}'],
      title: '友情链接管理',
      icon: 'link',
      noCache: true
    }
  }, {
    path: 'help',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/help'));
      });
    },
    name: 'Help',
    meta: {
      perms: ['GET /admin/system/params/list', 'PUT /admin/system/params/update', 'GET /admin/system/user/agreement/get', 'PUT /admin/system/novice/road/update', 'GET /admin/system/payment/method/get', 'GET /admin/system/novice/road/get', 'GET /admin/system/shopping/terms/get', 'PUT /admin/system/privacy/agreement/update', 'PUT /admin/system/user/agreement/update', 'PUT /admin/system/shopping/directory/update', 'GET /admin/system/privacy/agreement/get', 'GET /admin/system/shopping/directory/get', 'PUT /admin/system/shopping/terms/update', 'PUT /admin/system/payment/method/update'],
      title: '帮助中心',
      icon: 'system',
      noCache: true
    }
  }]
}, {
  path: '/authority',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'authorityManage',
  meta: {
    title: '权限管理',
    icon: 'component'
  },
  children: [{
    path: 'admin',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/authority/admin'));
      });
    },
    name: 'Admin',
    meta: {
      perms: ['GET /admin/admin/list', 'POST /admin/admin/create', 'PUT /admin/admin/update', 'DELETE /admin/admin/delete/{id}', 'PUT /admin/admin/resuming/{id}', 'PUT /admin/admin/disabling/{id}', 'PUT /admin/admin/reset/password/{id}'],
      title: '管理员管理',
      icon: 'people',
      noCache: true
    }
  }, {
    path: 'role',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/authority/role'));
      });
    },
    name: 'Role',
    meta: {
      perms: ['GET /admin/role/list', 'POST /admin/role/create', 'PUT /admin/role/update', 'DELETE /admin/role/delete/{id}', 'GET /admin/role/permissions/{roleId}', 'PUT /admin/role/updatePermissions'],
      title: '角色管理',
      icon: 'qq',
      noCache: true
    }
  }, {
    path: 'log',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/authority/log'));
      });
    },
    name: 'Log',
    meta: {
      perms: ['GET /admin/log/list'],
      title: '操作日志',
      icon: 'log',
      noCache: true
    }
  }]
}, {
  path: '/withdrawal',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'withdrawalManage',
  meta: {
    title: '提现管理',
    icon: 'recharge-item'
  },
  children: [{
    path: 'withdrawal',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/withdrawal/withdrawal'));
      });
    },
    name: 'Withdrawal',
    meta: {
      perms: ['GET /admin/account/trace/list', 'PUT /admin/account/trace/pay/mark/{id}', 'PUT /admin/account/trace/approve/{id}'],
      title: '提现管理',
      icon: 'recharge-item',
      noCache: true
    }
  }]
}, {
  path: '/statistics',
  component: Layout,
  redirect: 'noredirect',
  alwaysShow: true,
  name: 'statisticsManager',
  meta: {
    title: '统计分析',
    icon: 'stat'
  },
  children: [{
    path: 'userStat',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/statistics/userStat'));
      });
    },
    name: 'UserStat',
    meta: {
      perms: ['GET /admin/statistics/user/stat'],
      title: '会员统计',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'userRechargeStat',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/statistics/userRechargeStat'));
      });
    },
    name: 'UserRechargeStat',
    meta: {
      perms: ['GET /admin/statistics/user/recharge/stat'],
      title: '会员充值统计',
      icon: 'recharge-item',
      noCache: true
    }
  }, {
    path: 'userAmounts',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/statistics/userAmounts'));
      });
    },
    name: 'UserAmounts',
    meta: {
      perms: ['GET /admin/statistics/user/amounts'],
      title: '会员账户明细',
      icon: 'nested',
      noCache: true
    }
  }, {
    path: 'worksSaleStat',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/statistics/worksSaleStat'));
      });
    },
    name: 'StatWorksSale',
    meta: {
      perms: ['GET /admin/statistics/works/sales/stat'],
      title: '作品销售统计',
      icon: 'works',
      noCache: true
    }
  }, {
    path: 'worksTagSaleStat',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/statistics/worksTagSaleStat'));
      });
    },
    name: 'StatWorksTagSale',
    meta: {
      perms: ['GET /admin/statistics/works/tag/sales/stat'],
      title: '作品标签销售统计',
      icon: 'label',
      noCache: true
    }
  }, {
    path: 'worksSaleRecordStat',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/statistics/worksSaleRecordStat'));
      });
    },
    name: 'StatWorksSaleRecord',
    meta: {
      perms: ['GET /admin/statistics/works/tag/sales/stat'],
      title: '作品访问购买率统计',
      icon: 'shopping',
      noCache: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: 'noredirect',
  children: [{
    path: 'index',
    component: function component() {
      return _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/profile/index'));
      });
    },
    name: 'Profile',
    meta: { title: '个人中心', noCache: true }
  }],
  hidden: true
}, { path: '*', redirect: '/404', hidden: true }];