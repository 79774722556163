/**
 *@des 订单相关接口
 */
import request, { Method } from '@/utils/request.js';

/**
 * 选择作品章节准备提交订单
 */
export function orderPrepare(manuscriptId, data) {
  return request({
    url: '/order/prepare/' + manuscriptId,
    method: Method.POST,
    data: data,
    loading: true
  });
}

/**
 * 提交订单
 */
export function orderSubmit(data) {
  return request({
    url: '/order/submit/order',
    method: Method.POST,
    data: data
  });
}

/**
 * 余额支付
 */
export function walletPay(data) {
  return request({
    url: '/order/wallet/pay',
    method: Method.POST,
    data: data
  });
}

/**
 * PC网页扫码支付
 */
export function scanCodePay(orderSn, payMethod) {
  return request({
    url: '/order/native/pay/' + orderSn + '/' + payMethod,
    method: Method.POST,
    loading: true
  });
}

/**
 * 我的订单分页数据（电子商品）
 */
export function getElWorksOrderList(params) {
  return request({
    url: '/order/type/one/list',
    method: Method.GET,
    params: params
  });
}

/**
 * 我的订单详情（电子商品）
 */
export function getElWorksOrderDetail(sn) {
  return request({
    url: '/order/type/one/detail/' + sn,
    method: Method.GET
  });
}

/**
 * 取消订单
 */
export function orderCancel(sn) {
  return request({
    url: '/order/cancel/' + sn,
    method: Method.PUT
  });
}

/**
 * 我的订单分页数据（实物商品）
 */
export function getPaperWorksOrderList(params) {
  return request({
    url: '/order/type/two/list',
    method: Method.GET,
    params: params
  });
}

/**
 * 我的订单详情（实物商品）
 */
export function getPaperWorksOrderDetail(sn) {
  return request({
    url: '/order/type/two/detail/' + sn,
    method: Method.GET
  });
}

/**
 * 确认收货（实物商品）
 */
export function orderConfirmReceipt(sn) {
  return request({
    url: '/order/confirm/' + sn,
    method: Method.PUT
  });
}

/**
 * 删除订单
 */
export function orderDel(sn) {
  return request({
    url: '/order/remove/' + sn,
    method: Method.DELETE
  });
}

/**
 * 实体商品申请退货
 */
export function returnOrderSn(data) {
  return request({
    url: '/order/return/order',
    method: Method.POST,
    data: data
  });
}

/**
 * 退货物流信息填写
 */
export function returnLogistics(data) {
  return request({
    url: '/order/return/logistics',
    method: Method.POST,
    data: data
  });
}