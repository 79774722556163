var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: { model: _vm.listQuery, inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "会员昵称", prop: "nickname" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: {
                  placeholder: "请输入会员昵称",
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "nickname", $$v)
                  },
                  expression: "listQuery.nickname"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "会员ID", prop: "userId" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: {
                  placeholder: "请输入会员ID",
                  clearable: "",
                  size: "small"
                },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "userId", $$v)
                  },
                  expression: "listQuery.userId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "feedbackTable",
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            "highlight-current-row": "",
            "expand-row-keys": _vm.expands,
            "row-key": _vm.getRowKeys
          },
          on: { "expand-change": _vm.handleExpandChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "table-expand",
                        attrs: { "label-position": "left" }
                      },
                      [
                        _c("el-form-item", { attrs: { label: "反馈内容" } }, [
                          _c("span", [_vm._v(_vm._s(_vm.info.content))])
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "反馈图片" } },
                          _vm._l(_vm.info.picUrls, function(pic) {
                            return _c(
                              "el-image",
                              {
                                key: pic,
                                staticClass: "gallery",
                                attrs: {
                                  src: pic,
                                  "preview-src-list": _vm.info.picUrls
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "image-slot",
                                    attrs: { slot: "placeholder" },
                                    slot: "placeholder"
                                  },
                                  [
                                    _vm._v("\n                加载中"),
                                    _c("span", { staticClass: "dot" }, [
                                      _vm._v("...")
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              prop: "id",
              sortable: "",
              width: "90"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "会员ID",
              prop: "userId",
              width: "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "会员昵称", prop: "nickname" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "联系方式",
              prop: "mobile",
              width: "120"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "标题", prop: "title" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "反馈时间",
              prop: "createTime",
              width: "160"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "反馈状态",
              prop: "status",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.status === 1 ? "success" : "error"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.status === 1 ? "已处理" : "未处理")
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "处理说明",
              prop: "statusMsg",
              "min-width": "200"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "处理时间",
              prop: "updateTime",
              width: "160"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              width: "200",
              "class-name": "small-padding fixed-width",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status !== 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["PUT /admin/message/feedback/reply"],
                                expression:
                                  "['PUT /admin/message/feedback/reply']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleApprove(scope.row)
                              }
                            }
                          },
                          [_vm._v("反馈处理")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["GET /admin/message/feedback/read/{id}"],
                            expression:
                              "['GET /admin/message/feedback/read/{id}']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-document"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.approveDialogVisible,
            title: "反馈处理",
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.approveDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "approveForm",
              staticStyle: { width: "500px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.approveForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "处理说明", prop: "statusMsg" } },
                [
                  _c("el-input", {
                    attrs: { rows: 4, type: "textarea" },
                    model: {
                      value: _vm.approveForm.statusMsg,
                      callback: function($$v) {
                        _vm.$set(_vm.approveForm, "statusMsg", $$v)
                      },
                      expression: "approveForm.statusMsg"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.approveDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmApprove }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }