import _JSON$stringify from 'babel-runtime/core-js/json/stringify';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { detailAuthor, approveAuthor, updAuthor, approveAuthorPass, listAuthorCategory } from '@/api/author/author';
import AuthorImg from '@/components/AuthImg';
import { getParentAndCurrNode } from '@/utils/index.js';

export default {
  name: 'AuthorDetail',
  components: { AuthorImg: AuthorImg },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      author: {},
      preImageUrl: '',
      imageDialogVisible: false,
      approveDialogVisible: false,
      authorId: '',
      approveForm: {
        approveStatus: undefined,
        approveMsg: undefined,
        issystemCrea: false
      },
      rules: {
        approveStatus: [{ required: true, message: '审核状态不能为空！', trigger: 'blur' }],
        approveMsg: [{ required: true, message: '审核备注不能为空！', trigger: 'blur' }]
      },
      downloadLoading: false,
      updDialogVisible: false,
      isNext: false,
      authorLiblist: [],
      authorLibSelList: [],
      categorys: []
    };
  },
  created: function created() {
    var _this = this;

    this.init();

    listAuthorCategory().then(function (response) {
      _this.categorys = response.data.data.list;
    });
  },

  methods: {
    init: function init() {
      var _this2 = this;

      if (this.$route.query.authorId == null) {
        return;
      }
      this.authorId = this.$route.query.authorId;
      detailAuthor(this.$route.query.authorId).then(function (response) {
        _this2.author = response.data.data;
      });
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    },
    handleCheck: function handleCheck() {
      var _this3 = this;

      this.approveDialogVisible = true;
      this.isNext = false;
      this.$nextTick(function () {
        _this3.$refs['approveForm'].clearValidate();
      });
    },

    // 审核拒绝 or 审核通过前获取可能关联作者库列表（根据真实姓名匹配）
    confirmApprove: function confirmApprove() {
      var _this4 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          approveAuthor(_this4.authorId, _this4.approveForm).then(function (response) {
            if (_this4.approveForm.approveStatus === 2) {
              // 拒绝
              _this4.approveDialogVisible = false;
              _this4.notifySuccess('提交成功');
              _this4.init();
            } else {
              _this4.isNext = true;
              _this4.authorLiblist = response.data.data;
            }
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    },
    select: function select(selection, row) {
      if (selection.length > 1) {
        var del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
      this.authorLibSelList = selection;
      this.approveForm.issystemCrea = false;
    },
    selectAll: function selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
      this.authorLibSelList = selection;
      this.approveForm.issystemCrea = false;
    },
    changeSystemCreate: function changeSystemCreate(val) {
      console.log(val);
      if (val === true) {
        this.authorLibSelList = [];
        this.$refs.multipleTable.clearSelection();
      }
    },
    confirmApprovePass: function confirmApprovePass() {
      if (this.authorLibSelList.length === 0 && !this.approveForm.issystemCrea) {
        this.notifyInfo('请选择作者库记录并关联或勾选新增作者库记录并自动关联！');
        return;
      }
      var params = JSON.parse(_JSON$stringify(this.approveForm));
      params.issystemCrea = true;
      params.authorlibraryid = undefined;
      if (this.authorLibSelList.length > 0) {
        params.authorlibraryid = this.authorLibSelList[0].authorLibraryId;
        params.issystemCrea = false;
      }
      console.log(params);
      var that = this;
      var msg = this.approveForm.issystemCrea ? '是否确认新增作者库记录并自动关联？' : '是否确认关联选中的作者？';
      this.$confirm(msg, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        approveAuthorPass(that.authorId, params).then(function (response) {
          that.approveDialogVisible = false;
          that.notifySuccess('提交成功');
          that.init();
        }).catch(function (response) {
          that.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleUpdate: function handleUpdate() {
      this.updDialogVisible = true;
    },
    submitUpdate: function submitUpdate() {
      var _this5 = this;

      updAuthor(this.author).then(function (response) {
        _this5.updDialogVisible = false;
        _this5.notifySuccess('修改成功');
        _this5.init();
      }).catch(function (response) {
        _this5.notifyError(response.data.msg);
      });
    },
    formatCategoryName: function formatCategoryName(row, column) {
      var categoryName = '';
      var arrNodes = getParentAndCurrNode(this.categorys, row.categoryId);
      for (var i = arrNodes.length - 1; i >= 0; i--) {
        categoryName += arrNodes[i].name + '/';
      }
      return categoryName ? categoryName.substring(0, categoryName.length - 1) : '';
    }
  }
};