import _Promise from 'babel-runtime/core-js/promise';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listUser, detailUser, disablingUser, resumingUser } from '@/api/member/member';

export default {
  name: 'Member',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      showSearch: true,
      multiple: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        nickname: undefined,
        userId: undefined
      },
      downloadLoading: false,
      genderDic: ['未知', '男', '女'],
      disablingDialogVisible: false,
      disablingForm: {
        id: undefined,
        status: 1,
        statusMsg: undefined,
        disabledEndTime: undefined
      },
      rules: {
        disabledEndTime: [{ type: 'string', required: true, message: '请选择禁用截止日期', trigger: 'change' }],
        statusMsg: [{ required: true, message: '禁用说明不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    formateDisblingDate: function formateDisblingDate(dDate) {
      return this.parseTime(dDate, '{y}-{m}-{d}');
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listUser(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },

    /** 会员启用 */
    handleResuming: function handleResuming(row) {
      var _this2 = this;

      this.$confirm('确认要启用"' + row.nickname + '"会员吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        resumingUser(row.id).then(function (res) {
          _this2.notifySuccess('启用成功');
          _this2.getList();
        }).catch(function (response) {
          _this2.notifyError(response.data.msg);
        });
      });
    },

    /** 会员禁用 */
    handleDisabling: function handleDisabling(row) {
      var _this3 = this;

      this.disablingForm.id = row.id;
      this.disablingDialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['disablingForm'].clearValidate();
      });
    },
    confirmDisabling: function confirmDisabling() {
      var _this4 = this;

      this.$refs['disablingForm'].validate(function (valid) {
        if (valid) {
          if (_this4.disablingForm.disabledEndTime && _this4.disablingForm.disabledEndTime.indexOf('23:59:59') === -1) {
            _this4.disablingForm.disabledEndTime += ' 23:59:59';
          }
          disablingUser(_this4.disablingForm).then(function (response) {
            _this4.disablingDialogVisible = false;
            _this4.notifySuccess('禁用成功');
            _this4.getList();
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    },
    changeDisablingType: function changeDisablingType(val) {
      this.disablingForm.status = val;
    },
    handleDownload: function handleDownload() {
      var _this5 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['用户名', '手机号码', '性别', '生日', '状态'];
        var filterVal = ['username', 'mobile', 'gender', 'birthday', 'status'];
        excel.export_json_to_excel2(tHeader, _this5.list, filterVal, '用户信息');
        _this5.downloadLoading = false;
      });
    }
  }
};