var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _vm.messageBase
        ? _c(
            "el-card",
            [
              _c("div", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("消息中心：")
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-people"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "peoples",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("作者审核")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.authorApproveCounts,
                                    duration: 2600
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/author/list"],
                                  expression: "['GET /admin/author/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("author")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          { staticClass: "card-panel-icon-wrapper icon-works" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "works",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("作品审核")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.worksApproveCounts,
                                    duration: 3000
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/manuscript/works/list"],
                                  expression:
                                    "['GET /admin/manuscript/works/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("works")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-printing"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "printing",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("印厂审核")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.printingApproveCounts,
                                    duration: 3200
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/printing/list"],
                                  expression: "['GET /admin/printing/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("printing")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-message"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "message",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("用户留言")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.messageBase.feedBackCounts,
                                    duration: 3400
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/message/list/feedback"],
                                  expression:
                                    "['GET /admin/message/list/feedback']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("feedback")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-customized"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "customized",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("装订成册价格确认")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.sureBiddingCounts,
                                    duration: 2600
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/bidding/list"],
                                  expression: "['GET /admin/bidding/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("bookbinding")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-bookbinding"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "ship",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("装订成册发货确认")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.shipBiddingCounts,
                                    duration: 3000
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/bidding/list"],
                                  expression: "['GET /admin/bidding/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("bookbinding")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-withdrawal"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "withdrawal",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("提现审核")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.traceApproveCounts,
                                    duration: 3200
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/account/trace/list"],
                                  expression:
                                    "['GET /admin/account/trace/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("withdrawal")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-withdrawal-pay"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "recharge-item",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("提现支付")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.messageBase.tracePayCounts,
                                    duration: 3400
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/account/trace/list"],
                                  expression:
                                    "['GET /admin/account/trace/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("withdrawal")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          { staticClass: "card-panel-icon-wrapper icon-ship" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "ship",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("纸质作品发货确认")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.messageBase.shipOrderCounts,
                                    duration: 3600
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/order/two/list"],
                                  expression: "['GET /admin/order/two/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("paperWorksShip")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-customized"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "works",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("退货审批")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.examineOrderCounts,
                                    duration: 3600
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/order/two/list"],
                                  expression: "['GET /admin/order/two/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("paperWorksReturn")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-customized"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "recharge-item",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("待退款")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.messageBase.refundOrderCounts,
                                    duration: 3600
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-panel-unhandled" },
                                  [_vm._v("未处理")]
                                )
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["GET /admin/order/two/list"],
                                  expression: "['GET /admin/order/two/list']"
                                }
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.handleDo("paperWorksReturn")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-d-arrow-right card-panel-go"
                              })
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.todayBase
        ? _c(
            "el-card",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("div", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v("今日统计：")
              ]),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-people"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "peoples",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("会员新增数")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.todayBase.userAddCounts,
                                    duration: 2600
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          { staticClass: "card-panel-icon-wrapper icon-works" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "author",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("作者新增数")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.todayBase.authorAddCounts,
                                    duration: 3000
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-printing"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "peoples",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("作者库新增数")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val":
                                      _vm.todayBase.authorLibraryAddCounts,
                                    duration: 3200
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-message"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "works",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("作品新增数")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.todayBase.worksAddCounts,
                                    duration: 3400
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-customized"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "paper-works",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("纸质作品订单新增数")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.todayBase.twoAddCounts,
                                    duration: 2600
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-bookbinding"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "money",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("纸质作品销售金额")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("div", { staticClass: "card-panel-num" }, [
                                  _vm._v(
                                    "￥" +
                                      _vm._s(
                                        _vm._f("unitPrice")(
                                          _vm.totalBase.twoSalesMoney
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-withdrawal"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "bookbinding",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("装订成册订单新增数")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.todayBase.biddingAddCounts,
                                    duration: 3200
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-panel-icon-wrapper icon-withdrawal-pay"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "money",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("装订成册销售金额")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("div", { staticClass: "card-panel-num" }, [
                                  _vm._v(
                                    "￥" +
                                      _vm._s(
                                        _vm._f("unitPrice")(
                                          _vm.totalBase.biddingSalesMoney
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "panel-group", attrs: { gutter: 40 } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-people"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "ebook",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("电子作品订单新增数")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("count-to", {
                                  staticClass: "card-panel-num",
                                  attrs: {
                                    "start-val": 0,
                                    "end-val": _vm.todayBase.oneAddCounts,
                                    duration: 2600
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          { staticClass: "card-panel-icon-wrapper icon-works" },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "money",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("电子作品销售金额")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("div", { staticClass: "card-panel-num" }, [
                                  _vm._v(
                                    "￥" +
                                      _vm._s(
                                        _vm._f("unitPrice")(
                                          _vm.totalBase.oneSalesMoney
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-printing"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "recharge2",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("充值金额")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("div", { staticClass: "card-panel-num" }, [
                                  _vm._v(
                                    "￥" +
                                      _vm._s(
                                        _vm._f("unitPrice")(
                                          _vm.totalBase.rechargeMoney
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "card-panel-col",
                      attrs: { xs: 12, sm: 12, lg: 6 }
                    },
                    [
                      _c("div", { staticClass: "card-panel" }, [
                        _c(
                          "div",
                          {
                            staticClass: "card-panel-icon-wrapper icon-message"
                          },
                          [
                            _c("svg-icon", {
                              attrs: {
                                "icon-class": "withdrawal",
                                "class-name": "card-panel-icon"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-right" }, [
                          _c("div", { staticClass: "card-panel-description" }, [
                            _c("div", { staticClass: "card-panel-text" }, [
                              _vm._v("提现金额")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "card-panel-num-unhandled" },
                              [
                                _c("div", { staticClass: "card-panel-num" }, [
                                  _vm._v(
                                    "￥" +
                                      _vm._s(
                                        _vm._f("unitPrice")(
                                          _vm.totalBase.traceMoney
                                        )
                                      )
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c("div", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v("总数统计：")
          ]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "panel-group", attrs: { gutter: 40 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-people" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "peoples",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("会员总数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-panel-num-unhandled" },
                          [
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.totalBase.userTotalCounts,
                                duration: 2600
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-works" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "author",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("作者总数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-panel-num-unhandled" },
                          [
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.totalBase.authorTotalCounts,
                                duration: 3000
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-printing" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "peoples",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("作者库总数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-panel-num-unhandled" },
                          [
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val":
                                  _vm.totalBase.authorLibraryTotalCounts,
                                duration: 3200
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-message" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "works",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("作品总数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-panel-num-unhandled" },
                          [
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.totalBase.worksTotalCounts,
                                duration: 3400
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "panel-group", attrs: { gutter: 40 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card-panel-icon-wrapper icon-customized"
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "paper-works",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("纸质作品订单总数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-panel-num-unhandled" },
                          [
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.totalBase.twoTotalCounts,
                                duration: 2600
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card-panel-icon-wrapper icon-bookbinding"
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "money",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("纸质作品销售总金额")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-num-unhandled" }, [
                          _c("div", { staticClass: "card-panel-num" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm._f("unitPrice")(
                                    _vm.totalBase.twoSalesTotalMoney
                                  )
                                )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card-panel-icon-wrapper icon-withdrawal"
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "bookbinding",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("装订成册订单总数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-panel-num-unhandled" },
                          [
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.totalBase.biddingTotalCounts,
                                duration: 3200
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-panel-icon-wrapper icon-withdrawal-pay"
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "money",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("装订成册销售总金额")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-num-unhandled" }, [
                          _c("div", { staticClass: "card-panel-num" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm._f("unitPrice")(
                                    _vm.totalBase.biddingSalesTotalMoney
                                  )
                                )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "panel-group", attrs: { gutter: 40 } },
            [
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-people" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "ebook",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("电子作品订单总数")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-panel-num-unhandled" },
                          [
                            _c("count-to", {
                              staticClass: "card-panel-num",
                              attrs: {
                                "start-val": 0,
                                "end-val": _vm.totalBase.oneTotalCounts,
                                duration: 2600
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-works" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "money",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("电子作品销售总金额")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-num-unhandled" }, [
                          _c("div", { staticClass: "card-panel-num" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm._f("unitPrice")(
                                    _vm.totalBase.oneSalesTotalMoney
                                  )
                                )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-printing" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "recharge2",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("充值总金额")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-num-unhandled" }, [
                          _c("div", { staticClass: "card-panel-num" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm._f("unitPrice")(
                                    _vm.totalBase.rechargeTotalMoney
                                  )
                                )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "el-col",
                {
                  staticClass: "card-panel-col",
                  attrs: { xs: 12, sm: 12, lg: 6 }
                },
                [
                  _c("div", { staticClass: "card-panel" }, [
                    _c(
                      "div",
                      { staticClass: "card-panel-icon-wrapper icon-message" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class": "withdrawal",
                            "class-name": "card-panel-icon"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-panel-right" }, [
                      _c("div", { staticClass: "card-panel-description" }, [
                        _c("div", { staticClass: "card-panel-text" }, [
                          _vm._v("提现总金额")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "card-panel-num-unhandled" }, [
                          _c("div", { staticClass: "card-panel-num" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm._f("unitPrice")(
                                    _vm.totalBase.traceTotalMoney
                                  )
                                )
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }