var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: { model: _vm.listQuery, size: "small", inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: "请输入优惠券名称", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.name,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "使用类型", prop: "typesArray" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "260px" },
                  attrs: { multiple: "", placeholder: "请选择使用类型" },
                  model: {
                    value: _vm.listQuery.typesArray,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "typesArray", $$v)
                    },
                    expression: "listQuery.typesArray"
                  }
                },
                _vm._l(_vm.useTypeMap, function(key, value) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: key, value: value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "有效结束时间范围" } },
            [
              _c("el-date-picker", {
                staticClass: "filter-item",
                staticStyle: { width: "240px" },
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.searchTime,
                  callback: function($$v) {
                    _vm.searchTime = $$v
                  },
                  expression: "searchTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/coupon/list"],
                      expression: "['GET /admin/coupon/list']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["POST /admin/coupon/create"],
                      expression: "['POST /admin/coupon/create']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini"
                  },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["PUT /admin/coupon/update"],
                      expression: "['PUT /admin/coupon/update']"
                    }
                  ],
                  attrs: {
                    type: "success",
                    plain: "",
                    icon: "el-icon-edit",
                    size: "mini",
                    disabled: _vm.single
                  },
                  on: { click: _vm.handleUpdate }
                },
                [_vm._v("编辑")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "名称",
              prop: "name"
            }
          }),
          _vm._v("有效开始时间\n\n    "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "最低消费",
              prop: "minConsumption"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v("满" + _vm._s(scope.row.minConsumption) + "元可用")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "100px",
              label: "满减金额",
              prop: "discount"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v("减免" + _vm._s(scope.row.discount) + "元")]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "90",
              label: "每人限领",
              prop: "limitNumber"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        scope.row.limitNumber != 0
                          ? scope.row.limitNumber
                          : "不限"
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "90",
              label: "使用范围",
              prop: "type"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatGoodsType")(scope.row.type)))
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "90",
              label: "总数量",
              prop: "total"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(scope.row.total != 0 ? scope.row.total : "不限")
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "90",
              label: "剩余数量",
              prop: "surplus"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(scope.row.total != 0 ? scope.row.surplus : "-")
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "有效开始时间",
              prop: "startTime",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "有效结束时间",
              prop: "endTime",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "上架/下架",
              align: "center",
              prop: "isOnSale",
              width: "90"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [
                            "PUT /admin/coupon/sale/{couponId}/{isOnSale}"
                          ],
                          expression:
                            "['PUT /admin/coupon/sale/{couponId}/{isOnSale}']"
                        }
                      ],
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.handleStatusChange(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isOnSale,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isOnSale", $$v)
                        },
                        expression: "scope.row.isOnSale"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTime",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "200",
              label: "操作",
              "class-name": "small-padding fixed-width",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [
                              "GET /admin/coupon/read/{id}",
                              "GET /admin/coupon/user/list"
                            ],
                            expression:
                              "['GET /admin/coupon/read/{id}', 'GET /admin/coupon/user/list']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-tickets"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["PUT /admin/coupon/update"],
                            expression: "['PUT /admin/coupon/update']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["DELETE /admin/coupon/delete/{id}"],
                            expression: "['DELETE /admin/coupon/delete/{id}']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-delete"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDelete(scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "550px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "优惠券名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "name", $$v)
                      },
                      expression: "dataForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用范围" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.changeUseRange },
                      model: {
                        value: _vm.dataForm.type,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "type", $$v)
                        },
                        expression: "dataForm.type"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("全场通用")
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("指定分类")
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("指定作品")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dataForm.type === 1,
                      expression: "dataForm.type === 1"
                    }
                  ],
                  attrs: { label: "", prop: "valueId" }
                },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.categoryOptions,
                      normalizer: _vm.normalizer,
                      placeholder: "选择指定分类"
                    },
                    on: { select: _vm.handleSelectCategory },
                    model: {
                      value: _vm.dataForm.valueId,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "valueId", $$v)
                      },
                      expression: "dataForm.valueId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.dataForm.type === 2,
                      expression: "dataForm.type === 2"
                    }
                  ],
                  attrs: { prop: "worksName" }
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { placeholder: "请选择作品", readonly: true },
                      model: {
                        value: _vm.dataForm.worksName,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "worksName", $$v)
                        },
                        expression: "dataForm.worksName"
                      }
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.handleSelectWorks },
                        slot: "append"
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券说明", prop: "desc" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100" },
                    model: {
                      value: _vm.dataForm.desc,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "desc", $$v)
                      },
                      expression: "dataForm.desc"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "最低消费", prop: "minConsumption" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number", min: "0" },
                      model: {
                        value: _vm.dataForm.minConsumption,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "minConsumption", $$v)
                        },
                        expression: "dataForm.minConsumption"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "满减金额", prop: "discount" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number", min: "0" },
                      model: {
                        value: _vm.dataForm.discount,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "discount", $$v)
                        },
                        expression: "dataForm.discount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "每人限领", prop: "limitNumber" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number", min: "0" },
                      model: {
                        value: _vm.dataForm.limitNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "limitNumber", $$v)
                        },
                        expression: "dataForm.limitNumber"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("张")])],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", [_vm._v("如果为0，则用户领券数量不受限制")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券总数", prop: "total" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number", min: "0" },
                      model: {
                        value: _vm.dataForm.total,
                        callback: function($$v) {
                          _vm.$set(_vm.dataForm, "total", $$v)
                        },
                        expression: "dataForm.total"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("张")])],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", [_vm._v("如果为0，则优惠券总数不受限制")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "有效期", prop: "expireTime" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      align: "right",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                      "default-time": ["00:00:00", "23:59:59"],
                      "value-format": "yyyy-MM-dd HH:mm:ss"
                    },
                    on: { input: _vm.changeExipreTime },
                    model: {
                      value: _vm.dataForm.expireTime,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "expireTime", $$v)
                      },
                      expression: "dataForm.expireTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "50px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.worksDialogVisible,
            title: "选择作品",
            width: "1200px"
          },
          on: {
            "update:visible": function($event) {
              _vm.worksDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "queryFormWorks",
              attrs: { model: _vm.listQueryWorks, size: "small", inline: true }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "作品名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入作品名称", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilterWorks($event)
                      }
                    },
                    model: {
                      value: _vm.listQueryWorks.name,
                      callback: function($$v) {
                        _vm.$set(_vm.listQueryWorks, "name", $$v)
                      },
                      expression: "listQueryWorks.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作品类型", prop: "systemType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "260px" },
                      attrs: { placeholder: "请选择作品类型" },
                      model: {
                        value: _vm.listQueryWorks.systemType,
                        callback: function($$v) {
                          _vm.$set(_vm.listQueryWorks, "systemType", $$v)
                        },
                        expression: "listQueryWorks.systemType"
                      }
                    },
                    _vm._l(_vm.worksTypeArray, function(key, value) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: key, value: value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作品分类", prop: "categoryId" } },
                [
                  _c("treeselect", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      options: _vm.categoryOptions,
                      normalizer: _vm.normalizer,
                      placeholder: "选择作品分类"
                    },
                    on: { select: _vm.handleSelectCategory },
                    model: {
                      value: _vm.listQueryWorks.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQueryWorks, "categoryId", $$v)
                      },
                      expression: "listQueryWorks.categoryId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: { click: _vm.handleFilterWorks }
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetQueryWorks }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.worksListLoading,
                  expression: "worksListLoading"
                }
              ],
              ref: "authorTable",
              attrs: {
                data: _vm.worksSaleList,
                size: "small",
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": ""
              },
              on: { "row-click": _vm.handleSingleElection }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "55", label: "选择" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radio",
                            attrs: { label: scope.row.id },
                            model: {
                              value: _vm.worksSaleItem.id,
                              callback: function($$v) {
                                _vm.$set(_vm.worksSaleItem, "id", $$v)
                              },
                              expression: "worksSaleItem.id"
                            }
                          },
                          [_vm._v(" \n          ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作品ID",
                  prop: "id",
                  width: "90",
                  sortable: ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作品名称",
                  prop: "worksName",
                  "min-width": "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作品分类",
                  prop: "categoryId",
                  "min-width": "100",
                  formatter: _vm.formatWorksCategoryName
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作品价格",
                  prop: "worksPrice",
                  width: "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "标签名称",
                  prop: "tagName",
                  width: "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作品类型",
                  prop: "systemType",
                  width: "80"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.systemType == 0
                          ? _c("el-tag", [_vm._v("社内")])
                          : _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("社外")
                            ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "作者名称",
                  prop: "authorName",
                  width: "150"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.worksTotal > 0,
                expression: "worksTotal > 0"
              }
            ],
            attrs: {
              total: _vm.worksTotal,
              page: _vm.listQueryWorks.page,
              limit: _vm.listQueryWorks.size
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQueryWorks, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQueryWorks, "size", $event)
              },
              pagination: _vm.getWorksList
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "op-container",
              staticStyle: { "margin-top": "40px" }
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.worksDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmSelectWorks }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }