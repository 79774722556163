//
//
//
//
//
//
//
//
//
//
//

import { readWorks, validateWorks, unlockWorks } from '@/api/manuscript/works';

import { getToken } from '@/utils/auth';

export default {
  name: 'ManuscriptEdit',
  filters: {
    formatAttachmentName: function formatAttachmentName(attachmentName) {
      if (attachmentName) {
        return attachmentName.slice(attachmentName.lastIndexOf("_") + 1).replace('.old', '');
      }
      return '';
    }
  },
  data: function data() {
    return {
      vueid: "myid",
      baseUrl: process.env.BASE_API,
      html: "/static/pdfEditor/pdfEdit.html",
      worksId: 0,
      works: {},
      pdfEditVisible: true
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id == null) {
      return;
    }
    this.worksId = this.$route.query.id;
    if (!this.worksId) {
      this.notifyError("数据异常！");
      return;
    }
    //console.log('------------------' + this.worksId)
    this.init();

    var self = this;
    window[this.vueid] = function (val) {
      //html调用vue中的方法
      switch (val) {
        case 1:
          self.closePdfEditDialog(1);
          break;
        case 2:
          _this.notifySuccess("保存批注数据成功");
          break;
        case 3:
          _this.notifyError("保存批注数据失败，请重试");
          break;
        case 4:
          _this.notifyError("加载PDF失败");
          unlockWorks(_this.worksId).then(function (res) {
            _this.pdfEditVisible = false;
          }).catch(function (err) {
            _this.notifyError(err.data.msg);
          });
          break;
        case 5:
          self.closePdfEditDialog(2);
          break;
        case 5:
          _this.notifyError("在线编辑验证失败，请检查文件是否已锁定");
          break;
      }
    };
  },

  methods: {
    init: function init() {
      var _this2 = this;

      readWorks(this.worksId).then(function (response) {
        _this2.works = response.data.data;
        _this2.handlePdfLook(); // 默认为查看模式
      }).catch(function (response) {
        _this2.notifyError(response.data.msg);
      });
    },
    handlePdfEdit: function handlePdfEdit() {
      var _this3 = this;

      // pdf在线批注
      var that = this;
      validateWorks(this.works.id).then(function (res) {
        _this3.pdfEditVisible = true;
        setTimeout(function () {
          that.sendData2Html(that.works.filePaths[0], res.data.data); //上次批注数据
        }, 300);
      }).catch(function (response) {
        _this3.notifyError(response.data.msg);
      });
    },
    handlePdfLook: function handlePdfLook() {
      var _this4 = this;

      // pdf在线查看
      this.pdfEditVisible = true;
      setTimeout(function () {
        _this4.sendData2Html(_this4.works.filePaths[0], _this4.works.fileJsons); //上次批注数据
      }, 300);
    },
    sendData2Html: function sendData2Html(pdfUrl, jsonData) {
      var id = this.worksId;
      var myIframe = this.$refs['iframe'];
      if (myIframe) {
        var iframeWin = myIframe.contentWindow;
        iframeWin.postMessage({
          baseUrl: process.env.BASE_API,
          pdfUrl: pdfUrl,
          id: id,
          token: getToken(),
          jsonData: jsonData
        }, '*');
      }
    },
    closePdfEditDialog: function closePdfEditDialog(mtype) {
      var _this5 = this;

      var text = mtype === 1 ? '是否确认关闭在线编辑？' : '是否确认关闭？';
      this.$confirm(text, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (mtype === 1) {
          unlockWorks(_this5.worksId).then(function (response) {
            _this5.pdfEditVisible = false;
            _this5.closeWindow();
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        } else {
          _this5.pdfEditVisible = false;
          _this5.closeWindow();
        }
      }).catch(function () {// 取消操作
      });
    },
    closeWindow: function closeWindow() {
      var userAgent = navigator.userAgent;
      if (userAgent.indexOf("MSIE") > 0) {
        if (userAgent.indexOf("MSIE 6.0") > 0) {
          window.opener = null;
          window.close();
        } else {
          window.open("", "_top");
          window.top.close();
        }
      } else if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
        window.location.href = "about:blank "; //火狐默认状态非window.open的页面window.close是无效的
        //window.history.go(-2);
      } else {
        window.opener = null;
        window.open("about:blank", "_self");
        window.close();
      }
    }
  }
};