import request from '@/utils/request';
import Qs from 'qs';

export function listLink(params) {
  return request({
    url: '/blogroll/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createLink(data) {
  return request({
    url: '/blogroll/create',
    method: 'post',
    data: data
  });
}

export function updateLink(data) {
  return request({
    url: '/blogroll/update',
    method: 'put',
    data: data
  });
}

export function deleteLink(id) {
  return request({
    url: '/blogroll/delete/' + id,
    method: 'delete'
  });
}