var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "title1" }, [
        _c("div", { staticClass: "pl-5" }, [_vm._v("基本信息")]),
        _vm._v(" "),
        _c("i", {
          staticClass: "mr10",
          class: _vm.isShowCard ? "el-icon-arrow-up" : "el-icon-arrow-down",
          staticStyle: { "font-size": "20px", cursor: "pointer" },
          on: { click: _vm.showMailCard }
        })
      ]),
      _vm._v(" "),
      _vm.isShowCard && _vm.mail
        ? _c(
            "el-card",
            { staticClass: "card-box" },
            [
              _c(
                "el-row",
                { staticClass: "mb20 mt10", attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex", "font-size": "15px" } },
                      [
                        _c("span", { staticStyle: { width: "45px" } }, [
                          _vm._v("标题：")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { flex: "1", color: "#909399" } },
                          [_vm._v(_vm._s(_vm.mail.title))]
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("发布人："),
                      _c("span", [_vm._v(_vm._s(_vm.mail.creator))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("发布类型："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.mail.messageType === 1
                              ? "系统自动通知"
                              : "手动发布通知"
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("用户范围："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.mail.rangeType === 1 ? "全部用户" : "指定用户"
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("创建时间："),
                      _c("span", [_vm._v(_vm._s(_vm.mail.createTime))])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "mb20 mt20", attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "font-size": "15px",
                          "padding-right": "60px"
                        }
                      },
                      [
                        _c("span", { staticStyle: { width: "65px" } }, [
                          _vm._v("内    容：")
                        ]),
                        _vm._v(" "),
                        _c("editor-tiny-mce", {
                          ref: "tinyMce",
                          attrs: {
                            config: { width: 0, height: 280 },
                            value: _vm.mail.content,
                            disabled: true
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "el-card",
        {
          staticClass: "card-box",
          staticStyle: { "padding-left": "80px", "padding-right": "80px" }
        },
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              attrs: { model: _vm.listQuery, size: "small", inline: true }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员昵称", prop: "nickname" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入会员昵称", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleFilter($event)
                      }
                    },
                    model: {
                      value: _vm.listQuery.nickname,
                      callback: function($$v) {
                        _vm.$set(_vm.listQuery, "nickname", $$v)
                      },
                      expression: "listQuery.nickname"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发送时间" } },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "240px" },
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.searchTime,
                      callback: function($$v) {
                        _vm.searchTime = $$v
                      },
                      expression: "searchTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: { click: _vm.handleFilter }
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetQuery }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              attrs: {
                data: _vm.list,
                size: "small",
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "150",
                  label: "会员ID",
                  prop: "userId"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "会员昵称",
                  prop: "nickname",
                  "min-width": "240"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "是否已读",
                  prop: "isRead",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: scope.row.isRead ? "success" : "" }
                          },
                          [_vm._v(_vm._s(scope.row.isRead ? "已读" : "未读"))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "查看时间",
                  prop: "readTime",
                  "min-width": "180"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "发送时间",
                  align: "center",
                  prop: "createTime",
                  "min-width": "180"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0"
              }
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.size
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "size", $event)
              },
              pagination: _vm.getUserList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title mt20" }, [
      _c("div", { staticClass: "pl-5" }, [_vm._v("发送人列表")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }