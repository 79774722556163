//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mailDetail, mailCreate, mailUpdate } from '@/api/comment/mail';
import EditorTinyMce from '@/components/Editor';

export default {
  name: 'MailCreate',
  components: { EditorTinyMce: EditorTinyMce },
  data: function data() {
    return {
      mailForm: {
        title: undefined,
        content: undefined,
        rangeType: 1,
        messageType: 2
      },
      mailId: '',
      rules: {
        title: [{ required: true, message: '标题不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '内容不能为空', trigger: 'blur' }]
      }
    };
  },
  beforeCreate: function beforeCreate() {
    var _this = this;

    // 找到当前tab并修改当前tab显示的标题
    var currentView = this.$store.getters.visitedViews[this.$store.getters.visitedViews.findIndex(function (item) {
      return item.path === _this.$route.path;
    })];
    if (currentView != null) {
      currentView.title = this.$route.query.id == null ? '新增站内信' : '编辑站内信';
    }
  },
  created: function created() {
    var _this2 = this;

    this.init();
    this.$nextTick(function () {
      _this2.$refs.tinyMce.clearUrlPaths(); //清除Editor上传的文件路径
      _this2.$refs['mailForm'].clearValidate();
    });
  },

  methods: {
    init: function init() {
      var _this3 = this;

      if (this.$route.query.id == null) {
        return;
      }
      this.mailId = this.$route.query.id;
      mailDetail(this.mailId).then(function (response) {
        _this3.mailForm = response.data.data;
      }).catch(function (response) {
        _this3.notifyError(response.data.msg);
      });
    },
    handleCancel: function handleCancel() {
      this.$router.push({ path: '/comment/mail' });
    },
    handlePublish: function handlePublish() {
      var _this4 = this;

      this.mailForm.content = this.$refs.tinyMce.getContent();
      this.mailForm.contentPaths = this.$refs.tinyMce.getUrlPaths();

      this.$refs['mailForm'].validate(function (valid) {
        if (valid) {
          if (_this4.mailId) {
            mailUpdate(_this4.mailForm).then(function (response) {
              _this4.notifySuccess('修改成功');
              _this4.$router.push({ path: '/comment/mail' });
            }).catch(function (response) {
              _this4.notifyError(response.data.msg);
            });
          } else {
            mailCreate(_this4.mailForm).then(function (response) {
              _this4.notifySuccess('创建成功');
              _this4.$router.push({ path: '/comment/mail' });
            }).catch(function (response) {
              _this4.notifyError(response.data.msg);
            });
          }
        }
      });
    }
  }
};