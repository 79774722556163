var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "预览",
        width: "800px",
        "append-to-body": "",
        "before-close": _vm.modalClose
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("author-img", {
        staticStyle: {
          display: "block",
          "max-width": "100%",
          margin: "0 auto"
        },
        attrs: { authSrc: _vm.baseUrl + _vm.src }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }