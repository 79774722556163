import request from '@/utils/request';
import Qs from 'qs';

export function mailList(params) {
  return request({
    url: '/message/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function mailCreate(data) {
  return request({
    url: '/message/create',
    method: 'POST',
    data: data
  });
}

export function mailUpdate(data) {
  return request({
    url: '/message/update',
    method: 'PUT',
    data: data
  });
}

export function mailDelete(id) {
  return request({
    url: 'message/delete/' + id,
    method: 'DELETE'
  });
}

export function mailDetail(messageId) {
  return request({
    url: '/message/read/' + messageId,
    method: 'get'
  });
}

/**
 * 会员接收情况列表查询
 */
export function mailUserList(params) {
  return request({
    url: '/message/user/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 对指定用户发布站内信 
 */
export function mailSendByUsers(messageId, data) {
  return request({
    url: '/message/user/create/' + messageId,
    method: 'POST',
    data: data
  });
}