//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNotice, readNotice } from '@/api/system/notice';
import EditorTinyMce from '@/components/Editor';

export default {
  name: 'NoticeCreate',
  components: { EditorTinyMce: EditorTinyMce },
  data: function data() {
    return {
      noticeForm: {
        id: undefined,
        title: undefined,
        content: undefined,
        desc: undefined
      },
      noticeId: '',
      rules: {
        title: [{ required: true, message: '公告标题不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '公告内容不能为空', trigger: 'blur' }]
      }
    };
  },
  beforeCreate: function beforeCreate() {
    var _this = this;

    // 找到当前tab并修改当前tab显示的标题
    var currentView = this.$store.getters.visitedViews[this.$store.getters.visitedViews.findIndex(function (item) {
      return item.path === _this.$route.path;
    })];
    if (currentView != null) {
      currentView.title = this.$route.query.id == null ? '新增公告' : '公告详情';
    }
  },
  created: function created() {
    var _this2 = this;

    this.init();
    this.$nextTick(function () {
      _this2.$refs['noticeForm'].clearValidate();
    });
  },

  methods: {
    init: function init() {
      var _this3 = this;

      if (this.$route.query.id == null) {
        return;
      }
      this.noticeId = this.$route.query.id;
      readNotice(this.noticeId).then(function (response) {
        _this3.noticeForm = response.data.data;
      });
    },
    handleCancel: function handleCancel() {
      this.$router.push({ path: '/sys/notice' });
    },
    handlePublish: function handlePublish() {
      var _this4 = this;

      this.noticeForm.content = this.$refs.tinyMce.getContent();
      this.noticeForm.contentPaths = this.$refs.tinyMce.getUrlPaths();

      this.$refs['noticeForm'].validate(function (valid) {
        if (valid) {
          createNotice(_this4.noticeForm).then(function (response) {
            _this4.$refs.tinyMce.clearEditor(); //清除Editor内容
            _this4.notifySuccess('创建成功');
            _this4.$router.push({ path: '/sys/notice' });
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    }
  }
};