import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { readWorks, validateWorks, unlockWorks, approveWorks, readSplitWorks, autoSplitWorks, updateSplitWorks, sendWorsToErp } from '@/api/manuscript/works';
import { uploadWorksBackPath } from '@/api/storage';

import { getToken } from '@/utils/auth';
import FileDownload from "@/components/FileDownload";
import AuthImg from '@/components/AuthImg';
import FileUpload from '@/components/FileUpload';

export default {
  name: 'WorksDetail',
  components: { FileDownload: FileDownload, FileUpload: FileUpload, AuthImg: AuthImg },
  filters: {
    formatAttachmentName: function formatAttachmentName(attachmentName) {
      if (attachmentName) {
        return attachmentName.slice(attachmentName.lastIndexOf("_") + 1).replace('.old', '');
      }
      return '';
    }
  },
  data: function data() {
    return {
      vueid: "myid",
      baseUrl: process.env.BASE_API,
      uploadWorksBackPath: uploadWorksBackPath,
      html: "/static/pdfEditor/index.html",
      worksId: 0,
      works: {},
      imageDialogVisible: false,
      preImageUrl: '',
      pdfEditVisible: false,
      approveDialogVisible: false,
      approveForm: {
        approveStatus: undefined,
        approveMsg: undefined
      },
      rules: {
        approveStatus: [{ required: true, message: '审核状态不能为空！', trigger: 'blur' }],
        approveMsg: [{ required: true, message: '审核备注不能为空！', trigger: 'blur' }]
      },
      downloadVisible: false,
      activeName: '1',
      chapterListLoading: false,
      chapterForm: {
        chapterList: []
      },
      rulesChapter: {
        chapterIndex: [{ required: true, message: '章节序号不能为空！', trigger: 'blur' }],
        chapterName: [{ required: true, message: '章节名称不能为空！', trigger: 'blur' }],
        chapterPrice: [{ required: true, message: '章节价格不能为空！', trigger: 'blur' }],
        startPage: [{ required: true, message: '开始页码不能为空！', trigger: 'blur' }],
        endPage: [{ required: true, message: '结束页码不能为空！', trigger: 'blur' }]
      },
      rowNullData: {
        chapterIndex: 1,
        chapterName: '',
        chapterPrice: 0,
        startPage: 0,
        endPage: 0,
        manuscriptId: this.worksId
      },
      uploadFileTip: '确认要合并上传作品稿件？'
    };
  },
  created: function created() {
    var _this = this;

    if (this.$route.query.id == null) {
      return;
    }
    this.worksId = this.$route.query.id;
    this.init();
    this.getChapterDataList();

    var self = this;
    window[this.vueid] = function (val) {
      //html调用vue中的方法
      switch (val) {
        case 1:
          self.closePdfEditDialog(1);
          break;
        case 2:
          _this.notifySuccess("保存批注数据成功");
          break;
        case 3:
          _this.notifyError("保存批注数据失败，请重试");
          break;
        case 4:
          _this.notifyError("加载PDF失败");
          unlockWorks(_this.worksId).then(function (res) {
            _this.pdfEditVisible = false;
          }).catch(function (err) {
            _this.notifyError(err.data.msg);
          });
          break;
        case 5:
          self.closePdfEditDialog(2);
          break;
      }
    };
  },

  methods: {
    init: function init() {
      var _this2 = this;

      readWorks(this.worksId).then(function (response) {
        _this2.works = response.data.data;
        _this2.uploadFileTip = _this2.works.filePaths && _this2.works.filePaths.length > 0 ? '重新上传将会覆盖在线批注的作品稿件，确认上传？' : _this2.uploadFileTip;
      }).catch(function (response) {
        _this2.notifyError(response.data.msg);
      });
    },
    handleTabsClick: function handleTabsClick(tab, event) {
      this.activeName = tab.name;
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    },
    getUploadFileBackUrl: function getUploadFileBackUrl(fileUrl) {
      if (fileUrl) {
        this.works.filePaths = fileUrl ? Array.isArray(fileUrl) ? fileUrl : fileUrl.split(',') : [];
      }
    },
    handlePdfEdit: function handlePdfEdit() {
      var _this3 = this;

      // pdf在线批注
      var that = this;
      validateWorks(this.works.id).then(function (res) {
        _this3.pdfEditVisible = true;
        setTimeout(function () {
          that.sendData2Html(that.works.filePaths[0], res.data.data, false); //上次批注数据
        }, 300);
      }).catch(function (response) {
        _this3.notifyError(response.data.msg);
      });
    },
    handlePdfLook: function handlePdfLook() {
      var _this4 = this;

      // pdf在线查看
      this.pdfEditVisible = true;
      setTimeout(function () {
        _this4.sendData2Html(_this4.works.filePaths[0], _this4.works.fileJsons, true); //上次批注数据
      }, 300);
    },
    sendData2Html: function sendData2Html(pdfUrl, jsonData, isLook) {
      var id = this.worksId;
      var frame = this.$refs.iframe;
      frame.contentWindow.location.reload();
      if (frame.attachEvent) {
        frame.attachEvent('onload', function () {
          var iframeWin = frame.contentWindow;
          iframeWin.postMessage({
            baseUrl: process.env.BASE_API,
            pdfUrl: pdfUrl,
            id: id,
            token: getToken(),
            jsonData: jsonData,
            isLook: isLook
          }, '*');
        });
      } else {
        frame.onload = function () {
          var iframeWin = frame.contentWindow;
          iframeWin.postMessage({
            baseUrl: process.env.BASE_API,
            pdfUrl: pdfUrl,
            id: id,
            token: getToken(),
            jsonData: jsonData,
            isLook: isLook
          }, '*');
        };
      }
    },
    closePdfEditDialog: function closePdfEditDialog(mtype) {
      var _this5 = this;

      var text = mtype === 1 ? '是否确认关闭在线编辑？' : '是否确认关闭？';
      this.$confirm(text, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (mtype === 1) {
          unlockWorks(_this5.worksId).then(function (response) {
            _this5.pdfEditVisible = false;
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        } else {
          _this5.pdfEditVisible = false;
        }
      }).catch(function () {// 取消操作
      });
    },
    downloadFileItem: function downloadFileItem(fileUrl) {
      this.downloadVisible = true;
      this.$refs.fileDownload.downloadXhr(fileUrl);
    },
    handleCheck: function handleCheck() {
      var _this6 = this;

      this.approveDialogVisible = true;
      this.$nextTick(function () {
        _this6.$refs['approveForm'].clearValidate();
      });
    },
    confirmApprove: function confirmApprove() {
      var _this7 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          approveWorks(_this7.worksId, _this7.approveForm).then(function (response) {
            _this7.approveDialogVisible = false;
            _this7.notifySuccess('审核成功');
            _this7.init();
          }).catch(function (response) {
            _this7.notifyError(response.data.msg);
          });
        }
      });
    },
    handleErpCheck: function handleErpCheck() {
      var _this8 = this;

      //提交三审系统审核
      this.$confirm('确定将该稿件提交三审系统审核？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        sendWorsToErp(_this8.worksId).then(function (res) {
          _this8.notifySuccess('提交审核成功');
          _this8.works.approveOther = 1;
        }).catch(function (response) {
          _this8.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    getChapterDataList: function getChapterDataList() {
      var _this9 = this;

      //获取章节数据
      this.chapterListLoading = true;
      readSplitWorks(this.worksId).then(function (res) {
        _this9.chapterListLoading = false;
        _this9.chapterForm.chapterList = res.data.data;
      }).catch(function (response) {
        _this9.notifyError(response.data.msg);
        _this9.chapterListLoading = false;
      });
    },
    handleAutoSplit: function handleAutoSplit() {
      var _this10 = this;

      //自动拆分章节
      var msg = this.chapterForm.chapterList.length === 0 ? '确定要系统自动拆分章节吗？' : '章节数据已存在，重新拆分将会覆盖已拆分的章节数据。是否确认拆分？';
      this.$confirm(msg, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this10.$modal.loading("正在拆分，请稍候...");
        autoSplitWorks(_this10.worksId).then(function (res) {
          _this10.notifySuccess(res.data.msg);
          _this10.getChapterDataList();
          _this10.$modal.closeLoading();
        }).catch(function (response) {
          _this10.notifyError(response.data.msg);
          _this10.$modal.closeLoading();
        });
      }).catch(function () {// 取消操作
      });
    },
    addRow: function addRow(index, row) {
      var rowData = _Object$assign({}, this.rowNullData);
      if (index >= 0) {
        rowData.chapterIndex = row.chapterIndex + 1;
        this.chapterForm.chapterList.splice(index + 1, 0, rowData);
      } else {
        rowData.chapterIndex = this.chapterForm.chapterList.length + 1;
        this.chapterForm.chapterList.splice(this.chapterForm.chapterList.length, 0, rowData);
      }
    },
    handleDelRow: function handleDelRow(index, row) {
      var _this11 = this;

      this.$confirm('确认要删除改行数据吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this11.chapterForm.chapterList.splice(index, 1);
      }).catch(function () {// 取消操作
      });
    },
    moveUp: function moveUp(index, row) {
      // 上移
      //console.log('上移', index, row)
      if (index > 0) {
        var upData = this.chapterForm.chapterList[index - 1];
        this.chapterForm.chapterList.splice(index - 1, 1);
        this.chapterForm.chapterList.splice(index, 0, upData);
      } else {
        this.notifyError('已经是第一行，不可上移');
      }
    },
    moveDown: function moveDown(index, row) {
      // 下移
      //console.log('下移', index, row)
      if (index + 1 === this.chapterForm.chapterList.length) {
        this.notifyError('已经是最后一行，不可下移');
      } else {
        var downData = this.chapterForm.chapterList[index + 1];
        this.chapterForm.chapterList.splice(index + 1, 1);
        this.chapterList.splice(index, 0, downData);
      }
    },
    handleUpdateSplit: function handleUpdateSplit() {
      var _this12 = this;

      if (this.chapterForm.chapterList.length === 0) {
        return;
      }
      this.$refs.chapterForm.validate(function (valid) {
        if (valid) {
          updateSplitWorks(_this12.worksId, _this12.chapterForm.chapterList).then(function (res) {
            _this12.notifySuccess("更新成功");
          }).catch(function (response) {
            _this12.notifyError(response.data.msg);
          });
        }
      });
    }
  }
};