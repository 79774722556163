import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listAdmin, createAdmin, updateAdmin, disablingAdmin, resumingAdmin, deleteAdmin, resetPwd } from '@/api/authority/admin';
import { roleOptions } from '@/api/authority/role';
import { uploadPath } from '@/api/storage';
import { getToken } from '@/utils/auth';
import { validateUserName, validatePass } from '@/utils/validate';

var textMap = {
  update: '编辑',
  create: '创建'
};

export default {
  name: 'Admin',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      ids: [], // 选中数组
      showSearch: true,
      single: true,
      multiple: false,
      uploadPath: uploadPath,
      list: null,
      total: 0,
      roleOptions: null,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        username: undefined
      },
      dataForm: {
        id: undefined,
        username: undefined,
        password: undefined,
        avatar: undefined,
        roleIds: [],
        mail: undefined,
        tel: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: textMap,
      rules: {
        username: [{ required: true, message: '管理员名称不能为空', trigger: 'blur' }, { validator: validateUserName, trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }, { validator: validatePass, trigger: 'blur' }],
        roleIds: [{ required: true, message: '请选择角色类型', trigger: 'blur' }],
        mail: [{ type: "email", message: "请输入正确的邮箱地址", trigger: ["blur", "change"] }],
        tel: [{ pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur" }]
      },
      downloadLoading: false
    };
  },

  computed: {
    headers: function headers() {
      return {
        'AdminAuthorizationToken': getToken()
      };
    }
  },
  created: function created() {
    var _this = this;

    this.getList();

    roleOptions().then(function (response) {
      _this.roleOptions = response.data.data;
    });
  },

  methods: {
    formatRole: function formatRole(roleId) {
      if (this.roleOptions) {
        for (var i = 0; i < this.roleOptions.length; i++) {
          if (roleId === this.roleOptions[i].value) {
            return this.roleOptions[i].label;
          }
        }
      }
      return '';
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listAdmin(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    reset: function reset() {
      this.dataForm = {
        id: undefined,
        username: undefined,
        password: undefined,
        avatar: undefined,
        roleIds: [],
        mail: undefined,
        tel: undefined
      };
      this.resetForm("dataForm");
    },

    uploadAvatar: function uploadAvatar(response) {
      this.dataForm.avatar = response.data.url;
    },
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.reset();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createAdmin(_this4.dataForm).then(function (response) {
            _this4.getList();
            _this4.dialogFormVisible = false;
            _this4.notifySuccess('添加管理员成功');
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      if (row.id) {
        this.dataForm = _Object$assign({}, row);
      } else {
        //顶部编辑
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = _getIterator(this.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var v = _step.value;

            if (v.id === this.ids[0]) {
              this.dataForm = _Object$assign({}, v);
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateAdmin(_this6.dataForm).then(function () {
            _this6.getList();
            _this6.dialogFormVisible = false;
            _this6.notifySuccess('更新管理员成功');
          }).catch(function (response) {
            _this6.notifyError(response.data.msg);
          });
        }
      });
    },

    // 用户状态修改
    handleStatusChange: function handleStatusChange(row) {
      var _this7 = this;

      var text = row.deleted === true ? '禁用' : '启用';
      this.$confirm('确认要"' + text + '""' + row.username + '"用户吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.deleted = !row.deleted;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this8 = this;

      if (row.deleted === false) {
        // 停用改成启用
        resumingAdmin(row.id).then(function (response) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = _getIterator(_this8.list), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var v = _step2.value;

              if (v.id === row.id) {
                var index = _this8.list.indexOf(v);
                _this8.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      } else {
        // 启用改成停用
        disablingAdmin(row.id).then(function (response) {
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = _getIterator(_this8.list), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var v = _step3.value;

              if (v.id === row.id) {
                var index = _this8.list.indexOf(v);
                _this8.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      }
    },
    handleDelete: function handleDelete(row) {
      var _this9 = this;

      this.$confirm('确认要删除"' + row.username + '"用户吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteAdmin(row.id).then(function (response) {
          _this9.notifySuccess('删除管理员成功');
          _this9.getList();
        }).catch(function (response) {
          _this9.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleResetPwd: function handleResetPwd(row) {
      var _this10 = this;

      this.$confirm('确认要重置"' + row.username + '"用户的密码吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        resetPwd(row.id).then(function (response) {
          _this10.notifySuccess('重置密码成功');
        }).catch(function (response) {
          _this10.notifyError(response.data.errmsg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleDownload: function handleDownload() {
      var _this11 = this;

      this.$confirm('确认要导出数据吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this11.downloadLoading = true;
        _Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/vendor/Export2Excel'));
        }).then(function (excel) {
          var tHeader = ['管理员ID', '管理员名称', '管理员头像', "邮箱", "电话", "登录日期", "创建日期"];
          var filterVal = ['id', 'username', 'avatar', 'mail', 'tel', 'lastLoginTime', 'createTime'];
          excel.export_json_to_excel2(tHeader, _this11.list, filterVal, '管理员信息');
          _this11.downloadLoading = false;
        });
      }).catch(function () {// 取消操作

      });
    }
  }
};