import request from '@/utils/request';
import Qs from 'qs';

export function listCarousel(params) {
  return request({
    url: '/carousel/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createCarousel(data) {
  return request({
    url: '/carousel/create',
    method: 'post',
    data: data
  });
}

export function readCarousel(id) {
  return request({
    url: '/carousel/read/' + id,
    method: 'get'
  });
}

export function updateCarousel(data) {
  return request({
    url: '/carousel/update',
    method: 'put',
    data: data
  });
}

export function deleteCarousel(id) {
  return request({
    url: '/carousel/delete/' + id,
    method: 'delete'
  });
}

/**
 * 禁用
 */
export function disablingCarousel(id) {
  return request({
    url: '/carousel/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingCarousel(id) {
  return request({
    url: '/carousel/resuming/' + id,
    method: 'PUT'
  });
}