//
//
//
//
//
//
//
//
//
//
//
//
//

import { isExternal } from "@/utils/validate";

export default {
  name: "ImagePreview",
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: [Number, String],
      default: ""
    },
    height: {
      type: [Number, String],
      default: ""
    }
  },
  computed: {
    realSrc: function realSrc() {
      var real_src = this.src.split(",")[0];
      if (isExternal(real_src)) {
        return real_src;
      }
      return process.env.VUE_APP_BASE_API + real_src;
    },
    realSrcList: function realSrcList() {
      var real_src_list = this.src.split(",");
      var srcList = [];
      real_src_list.forEach(function (item) {
        if (isExternal(item)) {
          return srcList.push(item);
        }
        return srcList.push(process.env.VUE_APP_BASE_API + item);
      });
      return srcList;
    },
    realWidth: function realWidth() {
      return typeof this.width == "string" ? this.width : this.width + "px";
    },
    realHeight: function realHeight() {
      return typeof this.height == "string" ? this.height : this.height + "px";
    }
  }
};