import request from '@/utils/request';
import Qs from 'qs';

export function feedbackList(params) {
  return request({
    url: '/message/list/feedback?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function feedbackDetail(id) {
  return request({
    url: '/message/feedback/read/' + id,
    method: 'get'
  });
}

/**
 * 处理回复留言
 */
export function feedbackReply(data) {
  return request({
    url: '/message/feedback/reply',
    method: 'PUT',
    data: data
  });
}