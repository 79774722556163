var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.orderInfo
        ? _c(
            "el-card",
            { staticClass: "card-box" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("订单编号："),
                      _c("span", [_vm._v(_vm._s(_vm.orderInfo.orderSn))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("创建时间："),
                      _c("span", [_vm._v(_vm._s(_vm.orderInfo.createTime))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("订单状态："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("orderStatusFilter")(
                              _vm.orderInfo.orderStatus
                            )
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("订单金额："),
                      _c("span", [
                        _vm._v(_vm._s(_vm.orderInfo.orderPrice) + "元")
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("优惠金额："),
                      _c("span", [
                        _vm._v(_vm._s(_vm.orderInfo.couponPrice) + "元")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("实付金额："),
                      _c("span", [
                        _vm._v(_vm._s(_vm.orderInfo.actualPrice) + "元")
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("支付时间："),
                      _c("span", [_vm._v(_vm._s(_vm.orderInfo.payTime))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("支付方式："),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("payMethodFilter")(_vm.orderInfo.payMethod)
                          )
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 12 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("用户ID："),
                      _c("span", [_vm._v(_vm._s(_vm.orderInfo.userId))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("用户昵称："),
                      _c("span", [_vm._v(_vm._s(_vm.orderInfo.nickname))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "item" }, [
                      _vm._v("系统抽成金额："),
                      _c("span", [
                        _vm._v(_vm._s(_vm.orderInfo.systemMoney) + "元")
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c(
        "el-card",
        { staticClass: "card-box" },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.orderInfo.orderManuscriptList,
                size: "small",
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "100",
                  label: "作品名称",
                  prop: "worksName"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  label: "章节序号",
                  prop: "chapterIndex"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "200",
                  label: "章节名称",
                  prop: "chapterName"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "120",
                  label: "章节价格(元)",
                  prop: "price"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  label: "开始页码",
                  prop: "startPage"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  label: "结束页码",
                  prop: "endPage"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "是否支付",
                  prop: "isFinish",
                  width: "120"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: scope.row.isFinish ? "success" : "" }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.isFinish ? "已支付" : "未支付")
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "pl-5" }, [_vm._v("订单信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title mt20" }, [
      _c("div", { staticClass: "pl-5" }, [_vm._v("作品章节详情")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }