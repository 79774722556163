import request from '@/utils/request';

export function listMail(query) {
  return request({
    url: '/user/mail/list',
    method: 'get',
    params: query
  });
}

export function updateMail(data) {
  return request({
    url: '/user/mail/update',
    method: 'put',
    data: data
  });
}

export function detailMail(key) {
  return request({
    url: '/user/mail/read/' + key,
    method: 'get'
  });
}