//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mailDetail, mailUserList } from '@/api/comment/mail';
import EditorTinyMce from '@/components/Editor';
import { pickerOptions } from '@/utils/index';

export default {
  name: 'MailDetail',
  components: { EditorTinyMce: EditorTinyMce },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      isShowCard: true,
      mail: {},
      mailId: '',
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 10,
        messageId: 0,
        nickname: undefined,
        dateRange: undefined
      },
      pickerOptions: {
        shortcuts: []
      },
      searchTime: []
    };
  },
  created: function created() {
    this.init();
    this.getUserList();
    this.pickerOptions.shortcuts = pickerOptions;
  },

  methods: {
    init: function init() {
      var _this = this;

      if (this.$route.query.id == null) {
        return;
      }
      this.mailId = this.$route.query.id;
      this.listQuery.messageId = this.mailId;
      mailDetail(this.mailId).then(function (response) {
        _this.mail = response.data.data;
      });
    },
    getUserList: function getUserList() {
      var _this2 = this;

      this.listLoading = true;
      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      mailUserList(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getUserList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    showMailCard: function showMailCard() {
      this.isShowCard = !this.isShowCard;
    }
  }
};