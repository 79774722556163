//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { bindingOrderList, bindingOrderDetail, bindingOrderShip, bindingOrderSurePrice, bindingOrderPress } from '@/api/bookbinding/bookbinding.js';
import { pickerOptions } from '@/utils/index';
import FileDownload from "@/components/FileDownload";

var statusMap = { //100 订单生成，待确认；101 订单已确认，待支付；102，下单未支付用户取消，订单关闭；103，下单未支付超期，系统自动关闭订单；201 支付完成,商家未发货；301 商家发货，用户未确认；401 用户确认收货；402 系统自动确认收货
  100: '待确认',
  101: '待支付',
  102: '用户取消',
  103: '系统取消',
  201: '待发货',
  301: '已发货',
  401: '用户确认收货',
  402: '系统确认收货'
};
var payMethodMap = { //（0 余额 1 微信 2 支付宝）
  0: '余额',
  1: '微信',
  2: '支付宝'
};

export default {
  name: 'BookBinding',
  components: { FileDownload: FileDownload },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      showSearch: true,
      multiple: false,
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        orderSn: undefined,
        dateRange: undefined,
        orderStatusArray: [],
        payMethodArray: []
      },
      searchTime: [],
      pickerOptions: {
        shortcuts: []
      },
      statusMap: statusMap,
      payMethodMap: payMethodMap,
      orderDialogVisible: false,
      orderDetail: {},
      priceSureDialogVisible: false,
      orderSureForm: {
        freightPrice: undefined,
        orderId: 0,
        orderPrice: undefined
      },
      priceSureRules: {
        orderPrice: [{ required: true, message: '装订成册费用不能为空', trigger: 'blur' }, { pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: "装订成册费用为大于0 的数", trigger: "blur" }],
        freightPrice: [{ required: true, message: '快递费不能为空', trigger: 'blur' }, { pattern: /^\d+(\.\d{0,2})?$/, message: "快递费大于等于0的数", trigger: "blur" }]
      },
      shipDialogVisible: false,
      shipForm: {
        orderId: undefined,
        shipChannel: undefined,
        shipSn: undefined
      },
      shipRules: {
        shipChannel: [{ required: true, message: '快递公司不能为空', trigger: 'blur' }],
        shipSn: [{ required: true, message: '快递单号不能为空', trigger: 'blur' }]
      },
      downloadVisible: false
    };
  },

  filters: {
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    },
    payMethodFilter: function payMethodFilter(payMethod) {
      return payMethodMap[payMethod];
    }
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      bindingOrderList(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleDetail: function handleDetail(row) {
      var _this2 = this;

      // 详情
      bindingOrderDetail(row.id).then(function (res) {
        _this2.orderDetail = res.data.data;
        _this2.orderDialogVisible = true;
      }).catch(function (err) {
        _this2.notifyError(err.data.msg);
      });
    },
    handlePrintOrder: function handlePrintOrder(row) {
      var _this3 = this;

      //订单转印单
      this.$confirm('确认将订单"' + row.orderSn + '"转为印单?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        bindingOrderPress(row.id, row.number).then(function (response) {
          _this3.notifySuccess('订单转印单成功');
          _this3.getList();
        }).catch(function (response) {
          _this3.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleSurePrice: function handleSurePrice(row) {
      var _this4 = this;

      //装订价格确认
      this.orderSureForm.orderId = row.id;
      this.priceSureDialogVisible = true;
      this.$nextTick(function () {
        _this4.$refs['orderSureForm'].clearValidate();
      });
    },
    confirmPriceSure: function confirmPriceSure() {
      var _this5 = this;

      this.$refs['orderSureForm'].validate(function (valid) {
        if (valid) {
          bindingOrderSurePrice(_this5.orderSureForm).then(function (response) {
            _this5.priceSureDialogVisible = false;
            _this5.notifySuccess('装订价格确认成功');
            _this5.resetForm("orderSureForm");
            _this5.getList();
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    },
    handleShip: function handleShip(row) {
      var _this6 = this;

      //发货弹框
      this.shipForm.orderId = row.id;
      this.shipDialogVisible = true;
      this.$nextTick(function () {
        _this6.$refs['shipForm'].clearValidate();
      });
    },
    confirmShip: function confirmShip() {
      var _this7 = this;

      //发货弹框
      this.$refs['shipForm'].validate(function (valid) {
        if (valid) {
          bindingOrderShip(_this7.shipForm).then(function (response) {
            _this7.shipDialogVisible = false;
            _this7.notifySuccess('确认发货成功');
            _this7.getList();
          }).catch(function (response) {
            _this7.notifyError(response.data.msg);
          });
        }
      });
    },
    handleDownload: function handleDownload(row) {
      var fileUrl = this.baseUrl + '/storage/WebManuscriptParent/bidding_order_zip/' + row.id;
      this.downloadVisible = true;
      this.$refs.fileDownload.downloadXhr(fileUrl);
    }
  }
};