var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabsClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "作品信息", name: "1" } },
            [
              _c("div", { staticClass: "title" }, [
                _c("div", { staticClass: "pl-5" }, [_vm._v("基础信息")])
              ]),
              _vm._v(" "),
              _vm.works
                ? _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 12 } },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("作品名称："),
                              _c("span", [_vm._v(_vm._s(_vm.works.worksName))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("作品封面：\n              "),
                              _vm.works.worksCover
                                ? _c("img", {
                                    staticClass: "img-sm",
                                    attrs: {
                                      src: _vm.baseUrl + _vm.works.worksCover
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.previewImage(
                                          _vm.works.worksCover
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("作品ID："),
                              _c("span", [_vm._v(_vm._s(_vm.works.id))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("申请时间："),
                              _c("span", [_vm._v(_vm._s(_vm.works.updateTime))])
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 12 } },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("作品分类："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.works.categoryName))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("作品价格："),
                              _c("span", [_vm._v(_vm._s(_vm.works.worksPrice))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("标签名称："),
                              _c("span", [_vm._v(_vm._s(_vm.works.tagName))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("   关键字："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.works.worksKeyword))
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 12 } },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("创作时间："),
                              _c("span", [_vm._v(_vm._s(_vm.works.addTime))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("入展时间："),
                              _c("span", [_vm._v(_vm._s(_vm.works.showTime))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("其他时间："),
                              _c("span", [_vm._v(_vm._s(_vm.works.otherTime))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("是否上架："),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.works.isOnSale === 0 ? "否" : "是")
                                )
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 12 } },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("推荐作品："),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.works.isRecommend === 0 ? "否" : "是"
                                  )
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("销售作品："),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.works.isSale === 0 ? "否" : "是")
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("总章节数："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.works.totalChapter))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("   总页数："),
                              _c("span", [_vm._v(_vm._s(_vm.works.totalPage))])
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { attrs: { gutter: 12 } },
                        [
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("作者名称："),
                              _c("span", [_vm._v(_vm._s(_vm.works.authorName))])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("作品销量："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.works.salesVolume))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("收藏次数："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.works.collectTimes))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 6 } }, [
                            _c("div", { staticClass: "item" })
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        { staticClass: "mb20 mt10", attrs: { gutter: 12 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "font-size": "15px"
                                }
                              },
                              [
                                _c("span", { staticStyle: { width: "75px" } }, [
                                  _vm._v("作品简介：")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { flex: "1", color: "#909399" }
                                  },
                                  [_vm._v(_vm._s(_vm.works.worksDesc))]
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.works.userCardPaths &&
                      _vm.works.userCardPaths.length >= 0
                        ? _c(
                            "el-row",
                            { staticClass: "mt20", attrs: { gutter: 12 } },
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "font-size": "15px"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { width: "75px" } },
                                      [_vm._v("会员证：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { flex: "1" } },
                                      _vm._l(_vm.works.userCardPaths, function(
                                        item,
                                        index
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "mr20 mb20",
                                            staticStyle: {
                                              display: "inline-block"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.previewImage(item)
                                              }
                                            }
                                          },
                                          [
                                            _c("AuthImg", {
                                              staticClass: "img",
                                              attrs: {
                                                authSrc: _vm.baseUrl + item
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title mt20" }, [
                _c("div", { staticClass: "pl-5" }, [_vm._v("审核信息")])
              ]),
              _vm._v(" "),
              _vm.works
                ? _c(
                    "el-card",
                    { staticClass: "card-box" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 12 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("审核状态："),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.works.approveStatus === 0
                                      ? "申请中"
                                      : _vm.works.approveStatus === 1
                                      ? "审核通过"
                                      : "审核拒绝"
                                  )
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("审核时间："),
                              _c("span", [
                                _vm._v(_vm._s(_vm.works.approveTime))
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "item" }, [
                              _vm._v("审核说明："),
                              _c("span", [_vm._v(_vm._s(_vm.works.approveMsg))])
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title mt20" }, [
                _c("div", { staticClass: "pl-5" }, [_vm._v("作品附件：")])
              ]),
              _vm._v(" "),
              _vm.works
                ? _c("el-card", { staticClass: "card-box" }, [
                    _c(
                      "div",
                      [
                        _c("span", { staticStyle: { width: "75px" } }, [
                          _vm._v("原始作品稿件：")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.works.originalPaths, function(item, index) {
                          return _c(
                            "el-row",
                            { key: index, attrs: { gutter: 12 } },
                            [
                              _c(
                                "div",
                                { staticClass: "mt5" },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            href: "#",
                                            underline: false
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.downloadFileItem(
                                                "" + _vm.baseUrl + item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("formatAttachmentName")(
                                                  item
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.works.originalPaths &&
                    _vm.works.originalPaths.length > 1 &&
                    _vm.works.approveStatus === 0
                      ? _c("div", { staticClass: "mt20" }, [
                          _c("span", [_vm._v("作品稿件合并回传：")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt10" },
                            [
                              _c("FileUpload", {
                                attrs: {
                                  uploadUrl: _vm.uploadWorksBackPath,
                                  params: { manuscriptId: _vm.worksId },
                                  showConfirmDialog: true,
                                  showMsg: _vm.uploadFileTip,
                                  fileSize: 100,
                                  limit: 1
                                },
                                on: { input: _vm.getUploadFileBackUrl }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt20" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "mt20",
                            staticStyle: { width: "75px" }
                          },
                          [_vm._v("在线批注作品稿件：")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.works.filePaths, function(item, index) {
                          return _c(
                            "el-row",
                            { key: index, attrs: { gutter: 12 } },
                            [
                              _c(
                                "div",
                                { staticClass: "mt5" },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            href: "#",
                                            underline: false
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatAttachmentName")(
                                                item
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "30px"
                                          },
                                          attrs: {
                                            type: "primary",
                                            size: "mini"
                                          },
                                          on: { click: _vm.handlePdfEdit }
                                        },
                                        [_vm._v("在线编辑")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml20",
                                          attrs: {
                                            type: "success",
                                            size: "mini"
                                          },
                                          on: { click: _vm.handlePdfLook }
                                        },
                                        [_vm._v("在线查看")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.works && _vm.works.approveStatus === 0
                ? _c(
                    "div",
                    { staticClass: "op-container mt20 mb20" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: [
                                "PUT /admin/manuscript/works/approve/{manuscriptId}"
                              ],
                              expression:
                                "['PUT /admin/manuscript/works/approve/{manuscriptId}']"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.handleCheck }
                        },
                        [_vm._v("审核")]
                      ),
                      _vm._v(" "),
                      !_vm.works.approveOther
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: [
                                    "PUT /admin/manuscript/works/send/erp/{manuscriptId}"
                                  ],
                                  expression:
                                    "['PUT /admin/manuscript/works/send/erp/{manuscriptId}']"
                                }
                              ],
                              staticClass: "ml20",
                              attrs: { type: "success" },
                              on: { click: _vm.handleErpCheck }
                            },
                            [_vm._v("提交三审系统审核")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "章节信息", name: "2" } },
            [
              _c(
                "el-row",
                { staticClass: "mb10", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: [
                                "PUT /admin/manuscript/works/auto/split/{manuscriptId}"
                              ],
                              expression:
                                "['PUT /admin/manuscript/works/auto/split/{manuscriptId}']"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            plain: "",
                            icon: "el-icon-video-play",
                            size: "mini"
                          },
                          on: { click: _vm.handleAutoSplit }
                        },
                        [_vm._v("自动拆分章节")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.works.approveStatus === 0 || _vm.works.approveStatus === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 1.5 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                plain: "",
                                icon: "el-icon-plus",
                                size: "mini"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.addRow(-1)
                                }
                              }
                            },
                            [_vm._v("增加一行")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "chapterForm",
                  attrs: {
                    model: _vm.chapterForm,
                    rules: _vm.rulesChapter,
                    "auto-complete": "on"
                  }
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.chapterListLoading,
                          expression: "chapterListLoading"
                        }
                      ],
                      ref: "chapterTable",
                      attrs: {
                        data: _vm.chapterForm.chapterList,
                        size: "small",
                        "element-loading-text": "正在查询中...",
                        fit: "",
                        "highlight-current-row": ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "章节序号",
                          "show-overflow-tooltip": "",
                          width: "140"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "chapterList." +
                                        scope.$index +
                                        ".chapterIndex",
                                      rules: _vm.rulesChapter.chapterIndex
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        min: 1,
                                        placeholder: "请输入",
                                        clearable: ""
                                      },
                                      on: {
                                        focus: function($event) {
                                          return _vm.$refs.chapterForm.clearValidate(
                                            "chapterList." +
                                              scope.$index +
                                              ".chapterIndex"
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.chapterIndex,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "chapterIndex",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.chapterIndex"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "章节名称",
                          "show-overflow-tooltip": "",
                          "min-width": "150"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "chapterList." +
                                        scope.$index +
                                        ".chapterName",
                                      rules: _vm.rulesChapter.chapterName
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        placeholder: "请输入",
                                        clearable: ""
                                      },
                                      on: {
                                        focus: function($event) {
                                          return _vm.$refs.chapterForm.clearValidate(
                                            "chapterList." +
                                              scope.$index +
                                              ".chapterName"
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.chapterName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "chapterName",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.chapterName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "章节价格(元)",
                          "show-overflow-tooltip": "",
                          "min-width": "60"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "chapterList." +
                                        scope.$index +
                                        ".chapterPrice",
                                      rules: _vm.rulesChapter.chapterPrice
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        precision: 2,
                                        step: 0.1,
                                        min: 0,
                                        placeholder: "请输入"
                                      },
                                      on: {
                                        focus: function($event) {
                                          return _vm.$refs.chapterForm.clearValidate(
                                            "chapterList." +
                                              scope.$index +
                                              ".chapterPrice"
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.chapterPrice,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "chapterPrice",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.chapterPrice"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "开始页码",
                          "show-overflow-tooltip": "",
                          "min-width": "60"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "chapterList." +
                                        scope.$index +
                                        ".startPage",
                                      rules: _vm.rulesChapter.startPage
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        step: 1,
                                        min: 0,
                                        placeholder: "请输入"
                                      },
                                      on: {
                                        focus: function($event) {
                                          return _vm.$refs.chapterForm.clearValidate(
                                            "chapterList." +
                                              scope.$index +
                                              ".startPage"
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.startPage,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "startPage", $$v)
                                        },
                                        expression: "scope.row.startPage"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "结束页码",
                          "show-overflow-tooltip": "",
                          "min-width": "60"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "chapterList." +
                                        scope.$index +
                                        ".endPage",
                                      rules: _vm.rulesChapter.endPage
                                    }
                                  },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        step: 1,
                                        min: 0,
                                        placeholder: "请输入"
                                      },
                                      on: {
                                        focus: function($event) {
                                          return _vm.$refs.chapterForm.clearValidate(
                                            "chapterList." +
                                              scope.$index +
                                              ".endPage"
                                          )
                                        }
                                      },
                                      model: {
                                        value: scope.row.endPage,
                                        callback: function($$v) {
                                          _vm.$set(scope.row, "endPage", $$v)
                                        },
                                        expression: "scope.row.endPage"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          width: "240",
                          label: "操作",
                          "class-name": "small-padding fixed-width"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "下面增加一行",
                                      placement: "bottom",
                                      "hide-after": 2000
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-plus up-down-i",
                                      on: {
                                        click: function($event) {
                                          return _vm.addRow(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "下移一行",
                                      placement: "bottom",
                                      "hide-after": 2000
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-bottom up-down-i",
                                      on: {
                                        click: function($event) {
                                          return _vm.moveDown(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "上移一行",
                                      placement: "bottom",
                                      "hide-after": 2000
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-top up-down-i",
                                      on: {
                                        click: function($event) {
                                          return _vm.moveUp(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: "删除当前行",
                                      placement: "bottom",
                                      "hide-after": 2000
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-delete up-down-i",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDelRow(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.works.approveStatus === 0 || _vm.works.approveStatus === 1
                ? _c(
                    "div",
                    { staticClass: "op-container mt20 mb20" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: [
                                "PUT /admin/manuscript/works/update/split/{manuscriptId}"
                              ],
                              expression:
                                "['PUT /admin/manuscript/works/update/split/{manuscriptId}']"
                            }
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.handleUpdateSplit }
                        },
                        [_vm._v("更新")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.imageDialogVisible, width: "800px" },
          on: {
            "update:visible": function($event) {
              _vm.imageDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { padding: "30px 30px 20px" },
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v("预览 ")]
          ),
          _vm._v(" "),
          _vm.imageDialogVisible
            ? _c("AuthImg", {
                staticStyle: {
                  display: "block",
                  "max-width": "100%",
                  margin: "0 auto"
                },
                attrs: { authSrc: _vm.baseUrl + _vm.preImageUrl }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.approveDialogVisible, width: "600px" },
          on: {
            "update:visible": function($event) {
              _vm.approveDialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { padding: "30px 30px 20px" },
              attrs: { slot: "title" },
              slot: "title"
            },
            [_vm._v("作品审核 ")]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "approveForm",
              staticStyle: { width: "500px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.approveForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否通过", prop: "approveStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.approveForm.approveStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.approveForm, "approveStatus", $$v)
                        },
                        expression: "approveForm.approveStatus"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通过")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("拒绝")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审核备注", prop: "approveMsg" } },
                [
                  _c("el-input", {
                    attrs: { rows: 4, type: "textarea" },
                    model: {
                      value: _vm.approveForm.approveMsg,
                      callback: function($$v) {
                        _vm.$set(_vm.approveForm, "approveMsg", $$v)
                      },
                      expression: "approveForm.approveMsg"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.approveDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmApprove }
                },
                [_vm._v("审批")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "show-close": false,
            "close-on-click-modal": false,
            "destroy-on-close": true,
            "close-on-press-escape": false,
            visible: _vm.pdfEditVisible,
            fullscreen: "",
            top: "0vh"
          },
          on: {
            "update:visible": function($event) {
              _vm.pdfEditVisible = $event
            }
          }
        },
        [
          _c("div", {}, [
            _c("iframe", {
              ref: "iframe",
              staticClass: "myIframe",
              attrs: { src: _vm.html, scrolling: "no", frameborder: "0" }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c("FileDownload", {
        ref: "fileDownload",
        attrs: { visible: _vm.downloadVisible },
        on: {
          "update:visible": function($event) {
            _vm.downloadVisible = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }