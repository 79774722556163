//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCodeImg } from '@/api/login';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '@/utils/jsencrypt';

export default {
  name: 'Login',
  data: function data() {
    return {
      codeUrl: '',
      requestAnimId: 0,
      ctx: {},
      ts: 0,
      canvas: undefined,
      canvasW: undefined,
      canvasH: undefined,
      pixels: [],
      cookiePassword: '',
      loginForm: {
        username: '',
        password: '',
        isErp: 0,
        rememberMe: false,
        code: '',
        uuid: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
        code: [{ required: true, trigger: 'change', message: '验证码不能为空' }]
      },
      loading: false,
      redirect: undefined
    };
  },

  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCode();
    this.getCookie();
  },
  mounted: function mounted() {
    this.canvas = window.document.getElementById('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.initCanvas();
    this.moving();
  },

  methods: {
    initCanvas: function initCanvas() {
      this.canvasW = this.canvas.width = window.innerWidth;
      this.canvasH = this.canvas.height = window.innerHeight;
      for (var x = -400; x < 400; x += 5) {
        for (var z = -250; z < 250; z += 5) {
          this.pixels.push({
            x: x,
            y: 100,
            z: z
          });
        }
      }
      this.ts = 0;
    },
    moving: function moving(ts) {
      var that = this;
      this.requestAnimId = requestAnimationFrame(that.moving, that.canvas);
      that.ctx.fillStyle = '#17293a';
      that.ctx.fillRect(0, 0, that.canvasW, that.canvasH);
      that.render(ts);
    },
    render: function render(ts) {
      var imageData = this.ctx.getImageData(0, 0, this.canvasW, this.canvasH);
      var len = this.pixels.length;
      var fov = 250;
      var pixel, scale, x2d, y2d, c;
      for (var i = 0; i < len; i++) {
        pixel = this.pixels[i];
        scale = fov / (fov + pixel.z);
        x2d = pixel.x * scale + this.canvasW / 2;
        y2d = pixel.y * scale + this.canvasH / 2;
        if (x2d >= 0 && x2d <= this.canvasW && y2d >= 0 && y2d <= this.canvasH) {
          c = (Math.round(y2d) * imageData.width + Math.round(x2d)) * 4;
          imageData.data[c] = 17;
          imageData.data[c + 1] = 122;
          imageData.data[c + 2] = 181;
          imageData.data[c + 3] = 255;
        }
        pixel.z -= 0.4;
        pixel.y = this.canvasH / 14 + Math.sin(i / len * 15 + ts / 450) * 10;
        if (pixel.z < -fov) pixel.z += 2 * fov;
      }
      this.ctx.putImageData(imageData, 0, 0);
    },
    getCode: function getCode() {
      var _this = this;

      getCodeImg().then(function (res) {
        _this.codeUrl = 'data:image/gif;base64,' + res.data.data.img;
        _this.loginForm.uuid = res.data.data.uuid;
      });
    },
    getCookie: function getCookie() {
      var username = Cookies.get('username');
      var password = Cookies.get('password');
      var rememberMe = Cookies.get('rememberMe');
      var isErp = Cookies.get('isErp');
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
        isErp: isErp == undefined ? 0 : parseInt(isErp)
      };
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          Cookies.set("isErp", _this2.loginForm.isErp);
          if (_this2.loginForm.rememberMe) {
            Cookies.set("username", _this2.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(_this2.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', _this2.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove('username');
            Cookies.remove('password');
            Cookies.remove('rememberMe');
          }
          _this2.$store.dispatch('LoginByUsername', _this2.loginForm).then(function () {
            if (_this2.requestAnimId) {
              cancelAnimationFrame(_this2.requestAnimId);
            }

            _this2.loading = false;
            _this2.$router.push({ path: _this2.redirect || "/" }).catch(function () {});
          }).catch(function (response) {
            _this2.notifyError(response.data.msg);
            _this2.loading = false;
            _this2.getCode();
          });
        }
      });
    }
  }
};