import _getIterator from 'babel-runtime/core-js/get-iterator';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listAuthorLibrary, recommendAuthor, createAuthorLibrary, updateAuthorLibrary, readAuthorLibrary, resumingAuthorLibrary, disablingAuthorLibrary, deleteAuthorLibrary, listAuthorCategory, listAuthor, associationAuthor } from '@/api/author/author';
import { uploadPath } from '@/api/storage';
import { getParentAndCurrNode, pickerOptions } from '@/utils/index.js';

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import EditorTinyMce from '@/components/Editor';
import ImageUpload from '@/components/ImageUpload';

var textMap = {
  update: '编辑',
  create: '创建'
};

export default {
  name: 'AuthorLibrary',
  components: { Treeselect: Treeselect, EditorTinyMce: EditorTinyMce, ImageUpload: ImageUpload },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      uploadPath: uploadPath,
      showSearch: true,
      categorys: [],
      categoryOptions: [],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        authorName: undefined,
        categoryId: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: textMap,
      dataForm: {
        authorApproveId: undefined,
        authorAvatar: undefined,
        authorDesc: undefined,
        authorDetail: undefined,
        authorDetailPaths: [],
        authorLibraryId: undefined,
        authorName: undefined,
        authorTel: undefined,
        categoryId: undefined
      },
      activeName: '1',
      rules: {
        authorName: [{ required: true, message: '作者名称不能为空', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请选择作者分类', trigger: 'blur' }],
        authorDesc: [{ required: true, message: '作者简介不能为空', trigger: 'blur' }]
      },
      authorLibrarySelection: {},
      applyAuthorId: '',
      applyAuthorDialogVisible: false,
      applyAuthorTotal: 0,
      applyAuthorListQuery: {
        page: 1,
        size: 10,
        name: undefined,
        isFilter: 1,
        dateRange: undefined
      },
      applyAuthorList: [],
      applyAuthorListLoading: false,
      pickerOptions: {
        shortcuts: []
      },
      searchTime: []
    };
  },
  created: function created() {
    var _this = this;

    this.pickerOptions.shortcuts = pickerOptions;

    listAuthorCategory().then(function (response) {
      _this.categorys = response.data.data.list;
      _this.categoryOptions = _this.handleTree(_this.categorys);
      _this.getList();
    });
  },

  methods: {
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listAuthorLibrary(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },

    /** 转换分类数据结构 */
    normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children,
        isDisabled: node.level === 1
      };
    },
    formatCategoryName: function formatCategoryName(row, column) {
      var categoryName = '';
      var arrNodes = getParentAndCurrNode(this.categorys, row.categoryId);
      for (var i = arrNodes.length - 1; i >= 0; i--) {
        categoryName += arrNodes[i].name + '/';
      }
      return categoryName ? categoryName.substring(0, categoryName.length - 1) : '';
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelect: function handleSelect(node) {
      // console.log(node)
    },
    handleTabsClick: function handleTabsClick(tab, event) {
      this.activeName = tab.name;
    },
    getUploadImageUrl: function getUploadImageUrl(imgUrls) {
      this.dataForm.authorAvatar = imgUrls;
    },
    reset: function reset() {
      this.dataForm = {
        authorApproveId: undefined,
        authorAvatar: undefined,
        authorDesc: undefined,
        authorDetail: undefined,
        authorDetailPaths: [],
        authorLibraryId: undefined,
        authorName: undefined,
        authorTel: undefined,
        categoryId: undefined
      };
      this.resetForm("dataForm");
    },
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.reset();
      this.activeName = '1';
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.dataForm.authorDetail = this.$refs.tinyMce.getContent();
      this.dataForm.authorDetailPaths = this.$refs.tinyMce.getUrlPaths();
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createAuthorLibrary(_this4.dataForm).then(function (response) {
            _this4.getList();
            _this4.dialogFormVisible = false;
            _this4.notifySuccess('新增成功');
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      readAuthorLibrary(row.authorLibraryId).then(function (res) {
        _this5.dataForm = res.data.data;

        _this5.activeName = '1';
        _this5.dialogStatus = 'update';
        _this5.dialogFormVisible = true;
        _this5.$nextTick(function () {
          _this5.$refs['dataForm'].clearValidate();
        });
      }).catch(function (response) {
        _this5.notifyError(response.data.msg);
      });
    },
    updateData: function updateData() {
      var _this6 = this;

      this.dataForm.authorDetail = this.$refs.tinyMce.getContent();
      this.dataForm.authorDetailPaths = this.$refs.tinyMce.getUrlPaths();
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateAuthorLibrary(_this6.dataForm).then(function () {
            _this6.getList();
            _this6.dialogFormVisible = false;
            _this6.notifySuccess('更新成功');
          }).catch(function (response) {
            _this6.notifyError(response.data.msg);
          });
        }
      });
    },

    // 状态修改
    handleStatusChange: function handleStatusChange(row) {
      var _this7 = this;

      var text = row.deleted === true ? '禁用' : '启用';
      this.$confirm('确认要"' + text + '""' + row.authorName + '"作者吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.deleted = !row.deleted;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this8 = this;

      if (row.deleted === false) {
        // 停用改成启用
        resumingAuthorLibrary(row.authorLibraryId).then(function (response) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = _getIterator(_this8.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var v = _step.value;

              if (v.authorLibraryId === row.authorLibraryId) {
                var index = _this8.list.indexOf(v);
                _this8.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      } else {
        // 启用改成停用
        disablingAuthorLibrary(row.authorLibraryId).then(function (response) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = _getIterator(_this8.list), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var v = _step2.value;

              if (v.authorLibraryId === row.authorLibraryId) {
                var index = _this8.list.indexOf(v);
                _this8.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          _this8.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this8.notifyError(response.data.msg);
        });
      }
    },
    handleRecommendChange: function handleRecommendChange(row) {
      var _this9 = this;

      var text = row.isRecommend === 0 ? '取消' : '启用';
      this.$confirm('确认要"' + text + '""' + row.authorName + '"为推荐作者吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        recommendAuthor(row.authorLibraryId, row.isRecommend).then(function (response) {
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = _getIterator(_this9.list), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var v = _step3.value;

              if (v.authorLibraryId === row.authorLibraryId) {
                var index = _this9.list.indexOf(v);
                _this9.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          _this9.notifySuccess(text + '成功');
        }).catch(function (response) {
          _this9.notifyError(response.data.msg);
        });
      }).catch(function () {
        // 取消操作
        row.isRecommend = row.isRecommend === 1 ? 0 : 1;
      });
    },
    handleDelete: function handleDelete(row) {
      var _this10 = this;

      this.$confirm('确认要删除"' + row.authorName + '"作者吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteAuthorLibrary(row.authorLibraryId).then(function (response) {
          _this10.notifySuccess('删除成功');
          _this10.getList();
        }).catch(function (response) {
          _this10.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleRelationAuthor: function handleRelationAuthor(row) {
      this.applyAuthorDialogVisible = !this.applyAuthorDialogVisible;
      this.authorLibrarySelection = row;
      this.getApplyAuthorList(row.authorLibraryId);
    },
    getApplyAuthorList: function getApplyAuthorList() {
      var _this11 = this;

      this.applyAuthorListLoading = true;
      this.applyAuthorListQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.applyAuthorListQuery.dateRange = [];
        this.applyAuthorListQuery.dateRange.push(this.searchTime[0]);
        this.applyAuthorListQuery.dateRange.push(this.searchTime[1]);
      }
      listAuthor(this.applyAuthorListQuery).then(function (response) {
        var authorDatas = response.data.data.list.map(function (v) {
          //去除不必要的属性，提高渲染速度
          return {
            authorId: v.authorId,
            authorName: v.authorName,
            authorTel: v.authorTel,
            realName: v.realName,
            createTime: v.createTime
          };
        });
        _this11.applyAuthorList = authorDatas;
        _this11.applyAuthorTotal = response.data.data.total;
        _this11.applyAuthorListLoading = false;
      }).catch(function () {
        _this11.applyAuthorList = [];
        _this11.applyAuthorTotal = 0;
        _this11.applyAuthorListLoading = false;
      });
    },
    handleApplyAuthorFilter: function handleApplyAuthorFilter() {
      this.applyAuthorListQuery.page = 1;
      this.getApplyAuthorList();
    },

    /** 重置按钮操作 */
    resetApplyAuthorQuery: function resetApplyAuthorQuery() {
      this.resetForm("queryApplyAuthorForm");
      this.searchTime = [];
      this.handleApplyAuthorFilter();
    },
    handleSingleElection: function handleSingleElection(row) {
      this.applyAuthorId = row.authorId;
    },
    handleRelateApplyAuthor: function handleRelateApplyAuthor() {
      var _this12 = this;

      if (!this.applyAuthorId) {
        this.notifyInfo('选择需要关联的申请作者');
        return;
      }
      associationAuthor(this.authorLibrarySelection.authorLibraryId, this.applyAuthorId).then(function (res) {
        _this12.notifySuccess('关联成功');
        _this12.$refs['applyAuthorTable'].clearSelection(); //清空选中,不然会在下一个批量操作中记录上一次选中的数据
        _this12.applyAuthorDialogVisible = !_this12.applyAuthorDialogVisible;
        _this12.authorLibrarySelection = {};
        _this12.applyAuthorId = '';
        _this12.getList();
      }).catch(function (response) {
        _this12.notifyError(response.data.msg);
      });
    }
  }
};