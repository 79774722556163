import _toConsumableArray from "babel-runtime/helpers/toConsumableArray";
import _Set from "babel-runtime/core-js/set";
import _Object$assign from "babel-runtime/core-js/object/assign";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listRole, createRole, updateRole, deleteRole, getPermission, updatePermission as _updatePermission, roleMenuList, updPermissionV2, roleDataPermList, updDataPerm } from "@/api/authority/role";
import { title } from "echarts/lib/theme/dark";

var textMap = {
  update: "编辑",
  create: "创建"
};

export default {
  name: "Role",
  data: function data() {
    return {
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        name: undefined
      },
      dataForm: {
        id: undefined,
        name: undefined,
        desc: undefined
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: textMap,
      rules: {
        name: [{ required: true, message: "角色名称不能为空", trigger: "blur" }]
      },
      permissionDialogFormVisible: false,
      systemPermissions: null,
      assignedPermissions: null,
      permissionBody: {
        roleId: undefined,
        permissions: []
      },
      title: '',
      currRoleId: undefined,
      menuPermissions: [],
      menuPermVisible: false,
      menuPermProps: {
        children: "children",
        label: "name"
      },
      dataPermissions: [],
      dataPermVisible: false,
      dataPermProps: {
        children: "children",
        label: "name"
      },
      expandAllFlag: false,
      checkPermArr: []
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listRole(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    reset: function reset() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        desc: undefined
      };
      this.resetForm("dataForm");
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.reset();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          createRole(_this3.dataForm).then(function (response) {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.notifySuccess("添加角色成功");
          }).catch(function (response) {
            _this3.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs["dataForm"].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          updateRole(_this5.dataForm).then(function () {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.notifySuccess("更新成功");
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      var that = this;
      this.$confirm('是否确认删除角色名称为"' + row.name + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        // 确定操作
        deleteRole(row.id).then(function (response) {
          that.notifySuccess("删除角色成功");
          var index = _this6.list.indexOf(row);
          _this6.list.splice(index, 1);
        }).catch(function (response) {
          that.notifyError(response.data.msg);
        });
      }).catch(function () {
        // 取消操作
      });
    },

    // 菜单权限
    handleMenuPerm: function handleMenuPerm(row) {
      var _this7 = this;

      this.title = "<" + row.name + ">\u529F\u80FD\u6743\u9650\u914D\u7F6E";
      this.menuPermVisible = true;
      this.expandAllFlag = false;
      this.checkPermArr = [];
      this.currRoleId = row.id;
      this.menuPermissions = [];
      roleMenuList(row.id).then(function (response) {
        response.data.data.forEach(function (item, index) {
          var treeLevel1 = { id: item.id, parentId: item.tid, name: item.meta.title, level: 1, ispermission: item.ispermission, perms: '', children: [] };
          item.children.forEach(function (item1, index1) {
            treeLevel1.children.push({ id: item1.id, parentId: item1.tid, name: item1.meta.title, level: 2, ispermission: item1.ispermission, perms: '', children: [] });
            item1.meta.buttonList.forEach(function (item2, index2) {
              treeLevel1.children[index1].children.push({ id: parseInt(item2.id), parentId: item1.id, name: item2.name, ispermission: item2.ispermission, perms: item2.perms });
            });
          });

          _this7.menuPermissions.push(treeLevel1);
        });
        _this7.$nextTick(function () {
          _this7.checkTheNode(_this7.menuPermissions, 'menuTreeRef');
        });
        //console.log(this.menuPermissions);
      });
    },

    /** 更新菜单权限 */
    updMenuPermission: function updMenuPermission() {
      var _this8 = this;

      var data = { roleId: this.currRoleId, button: this.$refs.menuTreeRef.getCheckedKeys(true), menu: [] };
      var checkedNodes = this.$refs.menuTreeRef.getCheckedNodes(true);

      if (checkedNodes.length === 0) return this.notifyInfo('请选择菜单权限');
      // 获取所有按钮权限的上级菜单id（第二级菜单id）
      var menuIdSet = new _Set();
      checkedNodes.forEach(function (item) {
        menuIdSet.add(item.parentId);
      });
      // 获取第一级菜单id
      var topMenuIdSet = new _Set();
      this.menuPermissions.forEach(function (item) {
        item.children.forEach(function (item1) {
          if (menuIdSet.has(item1.id)) {
            topMenuIdSet.add(item1.parentId);
          }
        });
      });
      data.menu = [].concat(_toConsumableArray(topMenuIdSet), _toConsumableArray(menuIdSet)); // Or Array.from(menuIdSet);
      updPermissionV2(data).then(function () {
        _this8.notifySuccess("保存成功");
        _this8.menuPermVisible = false;
      }).catch(function (response) {
        _this8.notifyError(response.data.msg);
      });
    },

    // 数据权限
    handleDataPerm: function handleDataPerm(row) {
      var _this9 = this;

      this.title = "<" + row.name + ">\u6570\u636E\u6743\u9650\u914D\u7F6E";
      this.dataPermVisible = true;
      this.expandAllFlag = true;
      this.checkPermArr = [];
      this.currRoleId = row.id;
      roleDataPermList(row.id).then(function (response) {
        _this9.dataPermissions = response.data.data || [];
        _this9.$nextTick(function () {
          _this9.checkTheNode(_this9.dataPermissions, 'dataTreeRef');
        });
      });
    },

    /** 更新数据权限 */
    updDataPermission: function updDataPermission() {
      var _this10 = this;

      var data = { roleId: this.currRoleId, dataperission: this.$refs.dataTreeRef.getCheckedKeys() };
      updDataPerm(data).then(function () {
        _this10.notifySuccess("保存成功");
        _this10.dataPermVisible = false;
      }).catch(function (response) {
        _this10.notifyError(response.data.msg);
      });
    },

    /** 获取勾选的节点 */
    checkTheNode: function checkTheNode(val, treeRef) {
      var _this11 = this;

      if (val.length !== 0) {
        val.forEach(function (item) {
          if (item.ispermission == true) {
            //判断当前树是否被选中 选中的话就push到 全局变量 arr 里面
            _this11.checkPermArr.push(item.id);
          }
          if (item.children != null && item.children.length > 0) {
            //这里是判断下级是否还有数据 下级数据是用 children 包起来的
            _this11.checkTheNode(item.children, treeRef);
          }
        });
        //循环以后直接选中 el-tree
        this.$refs[treeRef].setCheckedKeys(this.checkPermArr);
      }
    },
    toggleExpandAll: function toggleExpandAll() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

      this.expandAllFlag = !this.expandAllFlag;
      if (type === 1) {
        // const treeList = this.menuPermissions;
        // for (let i = 0; i < treeList.length; i++) {
        //   this.$refs.menuTreeRef.store.nodesMap[treeList[i].id].expanded = this.expandAllFlag
        // }

        for (var i = 0; i < this.$refs.menuTreeRef.store._getAllNodes().length; i++) {
          this.$refs.menuTreeRef.store._getAllNodes()[i].expanded = this.expandAllFlag;
        }
      } else {
        var treeList = this.dataPermissions;
        for (var _i = 0; _i < treeList.length; _i++) {
          this.$refs.dataTreeRef.store.nodesMap[treeList[_i].id].expanded = this.expandAllFlag;
        }
      }
    },
    handlePermission: function handlePermission(row) {
      var _this12 = this;

      this.permissionDialogFormVisible = true;
      this.permissionBody.roleId = row.id;
      getPermission(row.id).then(function (response) {
        _this12.systemPermissions = response.data.data.systemPermissions;
        _this12.assignedPermissions = response.data.data.assignedPermissions;
      });
    },
    updatePermission: function updatePermission() {
      var _this13 = this;

      this.permissionBody.permissions = this.$refs.tree.getCheckedKeys(true);
      _updatePermission(this.permissionBody).then(function (response) {
        _this13.permissionDialogFormVisible = false;
        _this13.notifySuccess("授权成功");
      }).catch(function (response) {
        _this13.notifyError(response.data.msg);
      });
    }
  }
};