var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: { model: _vm.listQuery, size: "small", inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "印单名称", prop: "invname" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入印单名称", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.invname,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "invname", $$v)
                  },
                  expression: "listQuery.invname"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "竞标状态", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "请选择竞标状态" },
                  model: {
                    value: _vm.listQuery.status,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "status", $$v)
                    },
                    expression: "listQuery.status"
                  }
                },
                _vm._l(_vm.statusMap, function(key, value) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: key, value: value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间" } },
            [
              _c("el-date-picker", {
                staticClass: "filter-item",
                staticStyle: { width: "240px" },
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.searchTime,
                  callback: function($$v) {
                    _vm.searchTime = $$v
                  },
                  expression: "searchTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/press/task/list"],
                      expression: "['GET /admin/press/task/list']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["POST /admin/press/task/create"],
                      expression: "['POST /admin/press/task/create']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini"
                  },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "ID",
              prop: "cbillid",
              "min-width": "160",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "印单名称",
              prop: "invname",
              "min-width": "200"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "印单类型",
              prop: "pressType",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.pressType == 1
                      ? _c("el-tag", [_vm._v("ERP")])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("人工")
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "装订方式",
              prop: "cbindtype",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "开本",
              prop: "cbooksizeid",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "覆膜方式",
              prop: "ctectorialmode",
              "min-width": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "存货编号",
              prop: "invcode",
              "min-width": "120"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "版次",
              prop: "iversionno",
              width: "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "印刷数量",
              prop: "nprintnum",
              width: "90"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "成品尺寸",
              prop: "productsize",
              "min-widthwidth": "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "纸规",
              prop: "zhigui",
              "min-width": "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "印单报价",
              prop: "vmemoMandoc",
              width: "90"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "竞标状态",
              prop: "vmemoMandoc",
              width: "90"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.vmemoMandoc == 0
                      ? _c("el-tag", [_vm._v("竞标中")])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("已结束")
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              prop: "createTime",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180",
              label: "操作",
              "class-name": "small-padding fixed-width",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.pressType != 1 && scope.row.vmemoMandoc == 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["GET /admin/press/task/read/{cbillid}"],
                                expression:
                                  "['GET /admin/press/task/read/{cbillid}']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleUpdate(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.pressType != 1 && scope.row.vmemoMandoc == 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["GET /admin/press/task/read/{cbillid}"],
                                expression:
                                  "['GET /admin/press/task/read/{cbillid}']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleMaterial(scope.row)
                              }
                            }
                          },
                          [_vm._v("印单材料")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["GET /admin/press/task/read/{cbillid}"],
                            expression:
                              "['GET /admin/press/task/read/{cbillid}']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-tickets"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    scope.row.vmemoMandoc == 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: [
                                  "PUT /admin/press/task/confirm/{cbillid}/{userId}"
                                ],
                                expression:
                                  "['PUT /admin/press/task/confirm/{cbillid}/{userId}']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-circle-check"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleOffer(scope.row)
                              }
                            }
                          },
                          [_vm._v("中标印厂")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "1100px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "1040px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "right",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "存货编码", prop: "invcode" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataForm.invcode,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "invcode", $$v)
                              },
                              expression: "dataForm.invcode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "版次", prop: "iversionno" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.iversionno,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "iversionno", $$v)
                              },
                              expression: "dataForm.iversionno"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "售价", prop: "refsaleprice" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.refsaleprice,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "refsaleprice", $$v)
                              },
                              expression: "dataForm.refsaleprice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "印单名称", prop: "invname" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.invname,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "invname", $$v)
                              },
                              expression: "dataForm.invname"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "责任编辑", prop: "psnname" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataForm.psnname,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "psnname", $$v)
                              },
                              expression: "dataForm.psnname"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "覆膜方式", prop: "ctectorialmode" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.ctectorialmode,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "ctectorialmode", $$v)
                              },
                              expression: "dataForm.ctectorialmode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "成品尺寸", prop: "productsize" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataForm.productsize,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "productsize", $$v)
                              },
                              expression: "dataForm.productsize"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "开本", prop: "cbooksizeid" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.cbooksizeid,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "cbooksizeid", $$v)
                              },
                              expression: "dataForm.cbooksizeid"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "印张", prop: "ncopies" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataForm.ncopies,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "ncopies", $$v)
                              },
                              expression: "dataForm.ncopies"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "装订方式", prop: "cbindtype" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.cbindtype,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "cbindtype", $$v)
                              },
                              expression: "dataForm.cbindtype"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "印刷数量", prop: "nprintnum" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataForm.nprintnum,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "nprintnum", $$v)
                              },
                              expression: "dataForm.nprintnum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "总页数", prop: "itotalpagenum" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.itotalpagenum,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "itotalpagenum", $$v)
                              },
                              expression: "dataForm.itotalpagenum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "书脊", prop: "spine" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataForm.spine,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "spine", $$v)
                              },
                              expression: "dataForm.spine"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "纸规", prop: "zhigui" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataForm.zhigui,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "zhigui", $$v)
                              },
                              expression: "dataForm.zhigui"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "印完日期", prop: "completedate" }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.dataForm.completedate,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "completedate", $$v)
                              },
                              expression: "dataForm.completedate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "送货日期", prop: "deliverydate" }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.dataForm.deliverydate,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "deliverydate", $$v)
                              },
                              expression: "dataForm.deliverydate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["POST /admin/press/task/create"],
                          expression: "['POST /admin/press/task/create']"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["PUT /admin/press/task/update"],
                          expression: "['PUT /admin/press/task/update']"
                        }
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "材料列表",
            visible: _vm.dialogMaterialVisible,
            width: "1200px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogMaterialVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { staticClass: "mb10", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["POST /admin/press/task/sub/create"],
                          expression: "['POST /admin/press/task/sub/create']"
                        }
                      ],
                      attrs: {
                        type: "success",
                        plain: "",
                        icon: "el-icon-plus",
                        size: "mini"
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleSubCreate()
                        }
                      }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.sublistLoading,
                  expression: "sublistLoading"
                }
              ],
              ref: "sublistTable",
              attrs: {
                data: _vm.sublist,
                size: "small",
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "用途项目",
                  prop: "vclassname",
                  width: "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "印法说明",
                  prop: "iprintmethod",
                  "min-width": "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "正色",
                  prop: "cpositivecolor",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "反色",
                  prop: "cnegativecolor",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "色数",
                  prop: "icolorvalue",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "面数",
                  prop: "imianshu",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "开数",
                  prop: "isize",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "页数",
                  prop: "ipagenum",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "印张",
                  prop: "ncopies",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "共用数量",
                  prop: "nsharenum",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "材料名称",
                  prop: "cailiao",
                  width: "150"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "90",
                  label: "操作",
                  "class-name": "small-padding fixed-width",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["PUT /admin/press/task/update"],
                                expression: "['PUT /admin/press/task/update']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-edit"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleSubUpdate(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogSubFormVisible,
            width: "1100px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSubFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataSubForm",
              staticStyle: { width: "1040px" },
              attrs: {
                rules: _vm.rulesSublist,
                model: _vm.dataSubForm,
                "status-icon": "",
                "label-position": "right",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "用途项目", prop: "vclassname" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataSubForm.vclassname,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "vclassname", $$v)
                              },
                              expression: "dataSubForm.vclassname"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "印法说明", prop: "iprintmethod" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataSubForm.iprintmethod,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "iprintmethod", $$v)
                              },
                              expression: "dataSubForm.iprintmethod"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "正色", prop: "cpositivecolor" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataSubForm.cpositivecolor,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "cpositivecolor", $$v)
                              },
                              expression: "dataSubForm.cpositivecolor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "反色", prop: "cnegativecolor" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataSubForm.cnegativecolor,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "cnegativecolor", $$v)
                              },
                              expression: "dataSubForm.cnegativecolor"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "色数", prop: "icolorvalue" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataSubForm.icolorvalue,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "icolorvalue", $$v)
                              },
                              expression: "dataSubForm.icolorvalue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "面数", prop: "imianshu" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataSubForm.imianshu,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "imianshu", $$v)
                              },
                              expression: "dataSubForm.imianshu"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "开数", prop: "isize" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataSubForm.isize,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "isize", $$v)
                              },
                              expression: "dataSubForm.isize"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "页数", prop: "ipagenum" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataSubForm.ipagenum,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "ipagenum", $$v)
                              },
                              expression: "dataSubForm.ipagenum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "印张", prop: "ncopies" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataSubForm.ncopies,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "ncopies", $$v)
                              },
                              expression: "dataSubForm.ncopies"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "共用数量", prop: "nsharenum" }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.dataSubForm.nsharenum,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "nsharenum", $$v)
                              },
                              expression: "dataSubForm.nsharenum"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "材料名称", prop: "cailiao" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataSubForm.cailiao,
                              callback: function($$v) {
                                _vm.$set(_vm.dataSubForm, "cailiao", $$v)
                              },
                              expression: "dataSubForm.cailiao"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogSubFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createSublistData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateSublistData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.printingDialogVisible,
            title: "选择中标印刷厂",
            width: "1200px"
          },
          on: {
            "update:visible": function($event) {
              _vm.printingDialogVisible = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "queryPrintingForm",
              staticStyle: { "margin-bottom": "20px" },
              attrs: {
                model: _vm.listQueryPrinting,
                size: "small",
                inline: true
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员ID", prop: "userId" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { placeholder: "请输入会员ID", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handlePrintingFilter($event)
                      }
                    },
                    model: {
                      value: _vm.listQueryPrinting.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.listQueryPrinting, "userId", $$v)
                      },
                      expression: "listQueryPrinting.userId"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "印厂名称", prop: "custname" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入印厂名称", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handlePrintingFilter($event)
                      }
                    },
                    model: {
                      value: _vm.listQueryPrinting.custname,
                      callback: function($$v) {
                        _vm.$set(_vm.listQueryPrinting, "custname", $$v)
                      },
                      expression: "listQueryPrinting.custname"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "申请时间" } },
                [
                  _c("el-date-picker", {
                    staticClass: "filter-item",
                    staticStyle: { width: "240px" },
                    attrs: {
                      "picker-options": _vm.pickerOptions,
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.searchTime,
                      callback: function($$v) {
                        _vm.searchTime = $$v
                      },
                      expression: "searchTime"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini"
                      },
                      on: { click: _vm.handlePrintingFilter }
                    },
                    [_vm._v("搜索")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-refresh", size: "mini" },
                      on: { click: _vm.resetPrintingQuery }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.printingLoading,
                  expression: "printingLoading"
                }
              ],
              ref: "printingTable",
              attrs: {
                data: _vm.printingList,
                size: "small",
                "element-loading-text": "正在查询中...",
                border: "",
                fit: "",
                "highlight-current-row": ""
              },
              on: { "row-click": _vm.handleSingleElection }
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", width: "55", label: "选择" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            staticClass: "radio",
                            attrs: { label: scope.row.userId },
                            model: {
                              value: _vm.printingItem.userId,
                              callback: function($$v) {
                                _vm.$set(_vm.printingItem, "userId", $$v)
                              },
                              expression: "printingItem.userId"
                            }
                          },
                          [_vm._v(" \n          ")]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "会员ID",
                  prop: "userId",
                  width: "90",
                  sortable: ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "印厂名称",
                  prop: "custname",
                  "min-width": "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "法人",
                  prop: "legalbody",
                  width: "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系人",
                  prop: "linkman",
                  width: "100"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系方式",
                  prop: "phone",
                  width: "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "所属地区",
                  prop: "areaclname",
                  "min-width": "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "纳税人登记号",
                  prop: "taxpayerid",
                  "min-width": "120"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "报价金额",
                  prop: "vmemo",
                  width: "80"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "申请报价时间",
                  prop: "confirmTime",
                  width: "145"
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "合作次数",
                  prop: "joinTimes",
                  width: "80"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.printingTotal > 0,
                expression: "printingTotal > 0"
              }
            ],
            attrs: {
              total: _vm.printingTotal,
              page: _vm.listQueryPrinting.page,
              limit: _vm.listQueryPrinting.size
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQueryPrinting, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQueryPrinting, "size", $event)
              },
              pagination: _vm.getPrintingList
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "op-container",
              staticStyle: { "margin-top": "40px" }
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.printingDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirmSelectPrinting }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }