import request from '@/utils/request';
import Qs from 'qs';

export function listFaq(params) {
  return request({
    url: '/issue/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createFaq(data) {
  return request({
    url: '/issue/create',
    method: 'post',
    data: data
  });
}

export function updateFaq(data) {
  return request({
    url: '/issue/update',
    method: 'put',
    data: data
  });
}

export function deleteFaq(id) {
  return request({
    url: '/issue/delete/' + id,
    method: 'delete'
  });
}