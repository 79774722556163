//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listWorks, unlockWorks, readWorks, createWorks, updateWorks, deleteWorks, addWorksSale } from '@/api/manuscript/works';
import { listManuscriptTag } from '@/api/manuscript/tag';
import { listCategory } from "@/api/manuscript/category";
import { listStoryclass } from "@/api/manuscript/storyclass";
import { listAuthorLibrary, listAuthorCategory } from '@/api/author/author';
import { uploadPath, uploadWorksPath } from '@/api/storage';

import { getParentAndCurrNode } from '@/utils/index.js';

import AuthImgPreview from '@/components/AuthImgPreview';
import ImageUpload from '@/components/ImageUpload';
import FileUpload from '@/components/FileUpload';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

var approveStatuseMap = {
  0: '申请中',
  1: '审核通过',
  2: '审核拒绝'
};
var textMap = {
  update: '编辑',
  create: '创建'
};
var worksTypeArray = {
  0: '社内',
  1: '社外'
};

export default {
  name: 'Works',
  components: { AuthImgPreview: AuthImgPreview, ImageUpload: ImageUpload, FileUpload: FileUpload, Treeselect: Treeselect },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      uploadPath: uploadPath,
      uploadWorksPath: uploadWorksPath,
      showSearch: true,
      categorys: [],
      categoryOptions: [],
      storyclassOptions: [],
      worksTypeArray: worksTypeArray,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        name: undefined,
        approveStatusArray: undefined,
        categoryId: undefined,
        systemType: undefined,
        storyclassId: undefined
      },
      approveStatuseMap: approveStatuseMap,
      preImageUrl: '',
      imageDialogVisible: false,
      tagList: [], //稿件标签
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: textMap,
      dataForm: {
        addTime: undefined,
        authorLibraryId: undefined,
        categoryId: undefined,
        id: undefined,
        originalPaths: [],
        otherTime: undefined,
        showTime: undefined,
        tagId: undefined,
        userCardPaths: [],
        worksCover: undefined,
        worksDesc: undefined,
        worksKeyword: undefined,
        worksName: undefined,
        worksPrice: 0
      },
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      activeName: '1',
      rules: {
        worksName: [{ required: true, message: '作品名称不能为空', trigger: 'blur' }],
        // categoryId: [
        //   { required: true, message: '请选择作品导航分类', trigger: 'blur' }
        // ],
        storyclassId: [{ required: true, message: '请选择作品分类', trigger: 'blur' }],
        tagId: [{ required: true, message: '请选择作品标签', trigger: 'blur' }],
        // worksCover: [
        //   { required: true, message: '请上传作品封面', trigger: 'blur' }
        // ],
        worksDesc: [{ required: true, message: '作品简介不能为空', trigger: 'blur' }],
        worksKeyword: [{ required: true, message: '作品关键字不能为空', trigger: 'blur' }],
        authorLibraryId: [{ required: true, message: '请选择作品作者', trigger: 'blur' }],
        originalPaths: [{ type: 'array', required: true, message: '请上传作品稿件', trigger: 'change' }]
      },
      authorLibItem: {},
      authorDialogVisible: false,
      authorTotal: 0,
      authorListQuery: {
        page: 1,
        size: 10,
        authorName: undefined,
        deleted: 0,
        categoryId: undefined
      },
      authorList: [],
      authorCategorys: [], //作者库作者分类
      authorListLoading: false
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    listCategory().then(function (response) {
      //作品导航分类
      _this.categorys = response.data.data.list;
      _this.categoryOptions = _this.handleTree(response.data.data.list);
    });
    listStoryclass().then(function (response) {
      //作品分类
      _this.storyclassOptions = _this.handleTree(response.data.data.list, 'id', 'tid');
    });

    var param = { 'page': 1, 'size': 10000 };
    listManuscriptTag(param).then(function (response) {
      //作品标签
      _this.tagList = response.data.data.list;
    });
    listAuthorCategory().then(function (res) {
      //作者分类
      _this.authorCategorys = res.data.data.list;
    });
  },

  methods: {
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listWorks(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
        _this2.notifyError(res.data.msg);
      });
    },

    /** 转换分类数据结构 */
    normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    formatAuthorCategoryName: function formatAuthorCategoryName(row, column) {
      var categoryName = '';
      var arrNodes = getParentAndCurrNode(this.authorCategorys, row.categoryId);
      for (var i = arrNodes.length - 1; i >= 0; i--) {
        categoryName += arrNodes[i].name + '/';
      }
      return categoryName ? categoryName.substring(0, categoryName.length - 1) : '';
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelect: function handleSelect(node) {
      // console.log(node)
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/manuscript/worksDetail', query: { id: row.id } });
    },
    handleUnlock: function handleUnlock(row) {
      var _this3 = this;

      // 解锁
      this.$confirm('是否确认解锁作品名称为"' + row.worksName + '"的数据项？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        unlockWorks(row.id).then(function (response) {
          _this3.notifySuccess('解锁成功');
          _this3.getList();
        }).catch(function (response) {
          _this3.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleTabsClick: function handleTabsClick(tab, event) {
      this.activeName = tab.name;
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    },
    getWorksCoverUrl: function getWorksCoverUrl(imgUrl) {
      this.dataForm.worksCover = imgUrl;
    },
    getUserCardUrl: function getUserCardUrl(imgUrl) {
      this.dataForm.userCardPaths = imgUrl ? Array.isArray(imgUrl) ? imgUrl : imgUrl.split(',') : [];
    },
    getUploadFileUrl: function getUploadFileUrl(fileUrl) {
      this.dataForm.originalPaths = fileUrl ? Array.isArray(fileUrl) ? fileUrl : fileUrl.split(',') : [];
    },
    handleSelectAuthor: function handleSelectAuthor() {
      this.authorDialogVisible = !this.authorDialogVisible;
      this.getAuthorList();
    },
    getAuthorList: function getAuthorList() {
      var _this4 = this;

      this.authorListLoading = true;
      listAuthorLibrary(this.authorListQuery).then(function (response) {
        var authorDatas = response.data.data.list.map(function (v) {
          //去除不必要的属性，提高渲染速度
          return {
            authorLibraryId: v.authorLibraryId,
            authorName: v.authorName,
            authorTel: v.authorTel,
            categoryId: v.categoryId,
            authorDesc: v.authorDesc,
            createTime: v.createTime
          };
        });
        _this4.authorList = authorDatas;
        _this4.authorTotal = response.data.data.total;
        _this4.authorListLoading = false;
      }).catch(function () {
        _this4.authorList = [];
        _this4.authorTotal = 0;
        _this4.authorListLoading = false;
      });
    },
    handleAuthorFilter: function handleAuthorFilter() {
      this.authorListQuery.page = 1;
      this.getAuthorList();
    },

    /** 重置按钮操作 */
    resetAuthorQuery: function resetAuthorQuery() {
      this.resetForm("queryAuthorForm");
      this.handleAuthorFilter();
    },
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.dataForm.worksKeyword = this.keywords.toString();
    },
    showInput: function showInput() {
      var _this5 = this;

      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this5.$refs.newKeywordInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;
      if (newKeyword && this.keywords.indexOf(newKeyword) < 0) {
        this.keywords.push(newKeyword);
        this.dataForm.worksKeyword = this.keywords.toString();
      }
      this.newKeywordVisible = false;
      this.newKeyword = '';
    },
    handleSingleElection: function handleSingleElection(row) {
      this.authorLibItem = row;
    },
    handleConfirmSelectAuthor: function handleConfirmSelectAuthor() {
      this.dataForm.authorLibraryId = this.authorLibItem.authorLibraryId;
      this.dataForm.authorName = this.authorLibItem.authorName;
      this.authorDialogVisible = !this.authorDialogVisible;
    },
    reset: function reset() {
      this.dataForm = {
        addTime: undefined,
        authorLibraryId: undefined,
        categoryId: undefined,
        id: undefined,
        originalPaths: [],
        otherTime: undefined,
        showTime: undefined,
        tagId: undefined,
        userCardPaths: [],
        worksCover: undefined,
        worksDesc: undefined,
        worksKeyword: undefined,
        worksName: undefined,
        worksPrice: undefined
      };
      this.resetForm("dataForm");
    },
    handleCreate: function handleCreate() {
      var _this6 = this;

      this.reset();
      this.activeName = '1';
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.keywords = [];
      this.$nextTick(function () {
        _this6.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createWorks(_this7.dataForm).then(function (response) {
            _this7.getList();
            _this7.dialogFormVisible = false;
            _this7.authorLibItem = {};
            _this7.notifySuccess('新增成功');
          }).catch(function (response) {
            _this7.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this8 = this;

      //web端作者上传的，不能修改稿件附件
      readWorks(row.id).then(function (res) {
        _this8.dataForm = res.data.data;
        _this8.keywords = [];
        if (_this8.dataForm.worksKeyword !== null && _this8.dataForm.worksKeyword !== '') {
          _this8.keywords = _this8.dataForm.worksKeyword.split(',');
        }
        _this8.activeName = '1';
        _this8.dialogStatus = 'update';
        _this8.dialogFormVisible = true;
        _this8.$nextTick(function () {
          _this8.$refs['dataForm'].clearValidate();
        });
      }).catch(function (response) {
        _this8.notifyError(response.data.msg);
      });
    },
    updateData: function updateData() {
      var _this9 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateWorks(_this9.dataForm).then(function () {
            _this9.getList();
            _this9.dialogFormVisible = false;
            _this9.authorLibItem = {}; //成功，置空
            _this9.notifySuccess('更新成功');
          }).catch(function (response) {
            _this9.notifyError(response.data.msg);
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this10 = this;

      this.$confirm('确认要删除"' + row.worksName + '"作品吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteWorks(row.id).then(function (response) {
          _this10.notifySuccess('删除成功');
          _this10.getList();
        }).catch(function (response) {
          _this10.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleAddSale: function handleAddSale(row) {
      var _this11 = this;

      this.$confirm('确认要将"' + row.worksName + '"添加为销售作品吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        addWorksSale(row.id).then(function (response) {
          _this11.notifySuccess('添加成功');
          _this11.getList();
        }).catch(function (response) {
          _this11.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    }
  }
};