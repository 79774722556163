import _Object$keys from 'babel-runtime/core-js/object/keys';
import 'babel-polyfill'; // 兼容IE

import Vue from 'vue';

import Cookies from 'js-cookie';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/styles/element-variables.scss';
import '@/styles/index.scss'; // global css
import '@/styles/yc.scss';

import App from './App';
import router from './router/routers';
import store from './store';

import _i18n from './lang'; // Internationalization
import './icons'; // icon
import './router/index'; // permission control

import * as filters from './filters'; // global filters

import permission from '@/directive/permission/index.js'; // 权限判断指令

import modal from './utils/modal.js'; // 自定义全局弹框提示/加载/确定对话框文件
Vue.prototype.$modal = modal;

import { parseTime, resetForm, addDateRange, handleTree } from "@/utils/index";
import Pagination from '@/components/Pagination';
// 自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar";

// 全局方法挂载
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.handleTree = handleTree;

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});

Vue.directive('permission', permission);

Vue.prototype.notifySuccess = function (msg) {
  this.$notify.success({ title: '成功', message: msg });
};

Vue.prototype.notifyError = function (msg) {
  this.$notify.error({ title: '失败', message: msg });
};

Vue.prototype.notifyInfo = function (msg) {
  this.$notify.info({ message: msg });
};

// 全局组件挂载
Vue.component('Pagination', Pagination);
Vue.component('RightToolbar', RightToolbar);

// register global utility filters.
_Object$keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});