//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listWithdrawal, approveWithdrawal, payWithdrawalConfirm } from '@/api/withdrawal/withdrawal.js';
import { pickerOptions } from '@/utils/index';

var statusMap = {
  0: '申请中',
  1: '审核通过',
  2: '审核拒绝'
};

var payStatusMap = {
  0: '待支付',
  1: '支付完成'
};

export default {
  name: 'Withdrawal',
  filters: {
    statusFilter: function statusFilter(status) {
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        order: undefined,
        realnameBank: undefined,
        payStatus: undefined,
        approveStatusArray: [],
        dateRange: undefined
      },
      statusMap: statusMap,
      payStatusMap: payStatusMap,
      rules: {
        approveStatus: [{ required: true, message: '审核状态不能为空！', trigger: 'blur' }],
        approveMsg: [{ required: true, message: '审核说明不能为空！', trigger: 'blur' }]
      },
      searchTime: [],
      pickerOptions: {
        shortcuts: []
      },
      approveDialogVisible: false,
      approveForm: {
        id: undefined,
        approveStatus: undefined,
        approveMsg: undefined
      }
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      listWithdrawal(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleApprove: function handleApprove(row) {
      var _this2 = this;

      this.approveForm.id = row.id;
      this.approveDialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs['approveForm'].clearValidate();
      });
    },
    confirmApprove: function confirmApprove() {
      var _this3 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          approveWithdrawal(_this3.approveForm.id, _this3.approveForm).then(function (response) {
            _this3.approveDialogVisible = false;
            _this3.notifySuccess('审核成功');
            _this3.getList();
          }).catch(function (response) {
            _this3.notifyError(response.data.msg);
          });
        }
      });
    },
    handlePayConfirm: function handlePayConfirm(row) {
      var _this4 = this;

      this.$confirm('确认流水号"' + row.traceSn + '"已完成打款?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        payWithdrawalConfirm(row.id).then(function (response) {
          _this4.notifySuccess('确认支付完成成功');
          _this4.getList();
        }).catch(function (response) {
          _this4.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    }
  }
};