import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listFaq, createFaq, updateFaq, deleteFaq } from '@/api/system/faq';

export default {
  name: 'FAQ',
  data: function data() {
    return {
      ids: [], // 选中数组
      showSearch: true,
      single: true,
      multiple: false,
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        title: undefined
      },
      dataForm: {
        id: undefined,
        title: undefined,
        content: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        title: [{ required: true, message: '问题标题不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '问题内容不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listFaq(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    reset: function reset() {
      this.dataForm = {
        id: undefined,
        title: undefined,
        content: undefined
      };
      this.resetForm("dataForm");
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.reset();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createFaq(_this3.dataForm).then(function (response) {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.notifySuccess('创建成功');
          }).catch(function (response) {
            _this3.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      if (row.id) {
        this.dataForm = _Object$assign({}, row);
      } else {
        //顶部编辑
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = _getIterator(this.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var v = _step.value;

            if (v.id === this.ids[0]) {
              this.dataForm = _Object$assign({}, v);
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateFaq(_this5.dataForm).then(function () {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.notifySuccess('更新成功');
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      this.$confirm('确认要删除该条记录吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteFaq(row.id).then(function (response) {
          _this6.notifySuccess('删除成功');
          _this6.getList();
        }).catch(function (response) {
          _this6.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    }
  }
};