//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { changePassword } from "@/api/admin/profile";
import Cookies from 'js-cookie';

export default {
  data: function data() {
    var _this = this;

    var equalToPassword = function equalToPassword(rule, value, callback) {
      if (_this.user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      // 表单校验
      rules: {
        oldPassword: [{ required: true, message: "旧密码不能为空", trigger: "blur" }],
        newPassword: [{ required: true, message: "新密码不能为空", trigger: "blur" }, { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }],
        confirmPassword: [{ required: true, message: "确认密码不能为空", trigger: "blur" }, { required: true, validator: equalToPassword, trigger: "blur" }]
      }
    };
  },

  methods: {
    submit: function submit() {
      var _this2 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          changePassword(_this2.user.oldPassword, _this2.user.newPassword).then(function (res) {
            _this2.notifySuccess("修改成功");
            if (_this2.user.oldPassword != _this2.user.newPassword) {
              //密码不相同就删除cookie中保存的密码
              Cookies.remove('password');
            }
          }).catch(function (res) {
            _this2.notifyError(res.data.msg);
          });
        }
      });
    },
    close: function close() {
      this.$store.dispatch("delView", this.$route);
      this.$router.push({ path: "/" });
    }
  }
};