//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { feedbackList, feedbackDetail, feedbackReply } from '@/api/comment/leavingMsg';

export default {
  name: 'LeavingMsg',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      showSearch: true,
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        nickname: undefined,
        userId: undefined
      },
      info: {},
      expands: [],
      getRowKeys: function getRowKeys(row) {
        return row.id;
      },

      approveDialogVisible: false,
      approveForm: {
        id: undefined,
        statusMsg: undefined
      },
      rules: {
        statusMsg: [{ required: true, message: '处理说明不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      feedbackList(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleExpandChange: function handleExpandChange(row, expandedRows) {
      var that = this;
      if (expandedRows.length) {
        that.expands = [];
        if (row) {
          that.expands.push(row.id);
          that.getDetail(row.id);
        }
      } else {
        that.expands = [];
      }
    },
    handleDetail: function handleDetail(row) {
      this.$refs.feedbackTable.toggleRowExpansion(row);
    },
    getDetail: function getDetail(id) {
      var _this2 = this;

      feedbackDetail(id).then(function (res) {
        _this2.info = res.data.data;
        if (_this2.info.picUrls && _this2.info.picUrls.length > 0) {
          var arrPic = _this2.info.picUrls.map(function (item) {
            return _this2.baseUrl + item;
          });
          _this2.info.picUrls = arrPic;
        }
      }).catch(function (err) {
        _this2.notifyError(err.data.msg);
      });
    },
    handleApprove: function handleApprove(row) {
      var _this3 = this;

      this.approveDialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['approveForm'].clearValidate();
      });
      this.approveForm.id = row.id;
    },
    confirmApprove: function confirmApprove() {
      var _this4 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          feedbackReply(_this4.approveForm).then(function (response) {
            _this4.approveDialogVisible = false;
            _this4.notifySuccess('反馈处理成功');
            _this4.getList();
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    }
  }
};