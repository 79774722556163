import request from '@/utils/request';

export function listManuscriptTag(query) {
  return request({
    url: '/manuscript/tag/list',
    method: 'get',
    params: query
  });
}

export function createManuscriptTag(data) {
  return request({
    url: '/manuscript/tag/create',
    method: 'post',
    data: data
  });
}

export function updateManuscriptTag(data) {
  return request({
    url: '/manuscript/tag/update',
    method: 'put',
    data: data
  });
}

export function deleteManuscriptTag(id) {
  return request({
    url: '/manuscript/tag/delete/' + id,
    method: 'delete'
  });
}

export function detailManuscriptTag(id) {
  return request({
    url: '/manuscript/tag/read/' + id,
    method: 'get'
  });
}

/**
 * 禁用
 */
export function disablingManuscriptTag(id) {
  return request({
    url: '/manuscript/tag/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingManuscriptTag(id) {
  return request({
    url: '/manuscript/tag/resuming/' + id,
    method: 'PUT'
  });
}