var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [
            _vm._v(_vm._s(_vm.noticeId ? "公告详情" : "发布公告信息"))
          ]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "noticeForm",
              staticStyle: { "margin-right": "150px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.noticeForm,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公告标题", prop: "title" } },
                [
                  _vm.noticeId
                    ? _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.noticeForm.title,
                          callback: function($$v) {
                            _vm.$set(_vm.noticeForm, "title", $$v)
                          },
                          expression: "noticeForm.title"
                        }
                      })
                    : _c("el-input", {
                        model: {
                          value: _vm.noticeForm.title,
                          callback: function($$v) {
                            _vm.$set(_vm.noticeForm, "title", $$v)
                          },
                          expression: "noticeForm.title"
                        }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公告内容", prop: "content" } },
                [
                  _vm.noticeId
                    ? _c("editor-tiny-mce", {
                        ref: "tinyMce",
                        attrs: {
                          config: { width: 0, height: 360 },
                          value: _vm.noticeForm.content,
                          disabled: ""
                        }
                      })
                    : _c("editor-tiny-mce", {
                        ref: "tinyMce",
                        attrs: {
                          config: { width: 0, height: 360 },
                          value: _vm.noticeForm.content
                        }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "公告描述", prop: "desc" } },
                [
                  _vm.noticeId
                    ? _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.noticeForm.desc,
                          callback: function($$v) {
                            _vm.$set(_vm.noticeForm, "desc", $$v)
                          },
                          expression: "noticeForm.desc"
                        }
                      })
                    : _c("el-input", {
                        model: {
                          value: _vm.noticeForm.desc,
                          callback: function($$v) {
                            _vm.$set(_vm.noticeForm, "desc", $$v)
                          },
                          expression: "noticeForm.desc"
                        }
                      })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.noticeId
        ? _c(
            "div",
            {
              staticClass: "op-container",
              staticStyle: { "margin-right": "150px" }
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消")
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handlePublish }
                },
                [_vm._v(_vm._s(_vm.noticeId ? "更新" : "发布"))]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }