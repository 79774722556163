import _extends from "babel-runtime/helpers/extends";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";

export default {
  name: "Profile",
  components: {
    userAvatar: userAvatar,
    userInfo: userInfo,
    resetPwd: resetPwd
  },
  data: function data() {
    return {
      roleGroup: '',
      activeTab: 'userinfo'
    };
  },

  computed: _extends({}, mapGetters(['user', 'roles'])),
  created: function created() {
    this.roleGroup = this.roles ? this.roles.join(',') : '';
  },

  methods: {}
};