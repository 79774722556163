//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listOrderEbook } from '@/api/order/order.js';
import { pickerOptions } from '@/utils/index';

var statusMap = { //101 订单生成，未支付；102，下单未支付用户取消，订单关闭；103，下单未支付超期，系统自动关闭订单；201 支付完成
  101: '待支付',
  102: '用户取消',
  103: '系统取消',
  201: '已支付'
};
var payMethodMap = { //（0 余额 1 微信 2 支付宝）
  0: '余额',
  1: '微信',
  2: '支付宝'
};

export default {
  name: 'OrderEbook',
  filters: {
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    },
    payMethodFilter: function payMethodFilter(payMethod) {
      return payMethodMap[payMethod];
    }
  },
  data: function data() {
    return {
      showSearch: true,
      multiple: false,
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        orderSn: undefined,
        dateRange: undefined,
        orderStatusArray: [],
        payMethodArray: []
      },
      searchTime: [],
      pickerOptions: {
        shortcuts: []
      },
      statusMap: statusMap,
      payMethodMap: payMethodMap
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      listOrderEbook(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/order/orderEbookDetail', query: { id: row.id } });
    }
  }
};