//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listPrinting, printingDetail, printingApprove } from '@/api/printing/printing.js';
import { pickerOptions } from '@/utils/index';
import AuthorImg from '@/components/AuthImg';
import AuthImgPreview from '@/components/AuthImgPreview';

var approveMap = {
  0: '申请中',
  1: '审核通过',
  2: '审核拒绝'
};
export default {
  name: 'PrintingPlant',
  components: { AuthorImg: AuthorImg, AuthImgPreview: AuthImgPreview },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        userId: undefined,
        name: undefined,
        approveStatusArray: [],
        dateRange: undefined
      },
      approveMap: approveMap,
      pickerOptions: {
        shortcuts: []
      },
      searchTime: [],
      approveDialogVisible: false,
      approveForm: {
        id: undefined,
        approveStatus: undefined,
        approveMsg: undefined
      },
      rules: {
        approveStatus: [{ required: true, message: '审核状态不能为空！', trigger: 'blur' }],
        approveMsg: [{ required: true, message: '审核备注不能为空！', trigger: 'blur' }]
      },
      detailDialogVisible: false,
      printingDetail: {},
      imageDialogVisible: false,
      downloadLoading: false
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      listPrinting(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleDetail: function handleDetail(row) {
      var _this2 = this;

      printingDetail(row.userId).then(function (res) {
        _this2.printingDetail = res.data.data;
        _this2.detailDialogVisible = true;
      }).catch(function (err) {
        _this2.notifyError(err.data.msg);
      });
    },
    handleCheck: function handleCheck(row) {
      var _this3 = this;

      this.approveForm.id = row.userId;
      this.approveDialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['approveForm'].clearValidate();
      });
    },
    confirmApprove: function confirmApprove() {
      var _this4 = this;

      this.$refs['approveForm'].validate(function (valid) {
        if (valid) {
          printingApprove(_this4.approveForm.id, _this4.approveForm).then(function (response) {
            _this4.approveDialogVisible = false;
            _this4.notifySuccess('审核成功');
            _this4.getList();
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    }
  }
};