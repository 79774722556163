import request from '@/utils/request';
import Qs from 'qs';

export function listAd(params) {
  return request({
    url: '/ad/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createAd(data) {
  return request({
    url: '/ad/create',
    method: 'post',
    data: data
  });
}

export function readAd(id) {
  return request({
    url: '/ad/read/' + id,
    method: 'get'
  });
}

export function updateAd(data) {
  return request({
    url: '/ad/update',
    method: 'put',
    data: data
  });
}

export function deleteAd(id) {
  return request({
    url: '/ad/delete/' + id,
    method: 'delete'
  });
}

/**
 * 禁用
 */
export function disablingAd(id) {
  return request({
    url: '/ad/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingAd(id) {
  return request({
    url: '/ad/resuming/' + id,
    method: 'PUT'
  });
}