var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.listQuery,
            size: "small",
            inline: true,
            "label-width": "88px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "订单单号", prop: "orderSn" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入订单编号", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.orderSn,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "orderSn", $$v)
                  },
                  expression: "listQuery.orderSn"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "下单时间" } },
            [
              _c("el-date-picker", {
                staticClass: "filter-item",
                staticStyle: { width: "240px" },
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.searchTime,
                  callback: function($$v) {
                    _vm.searchTime = $$v
                  },
                  expression: "searchTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态", prop: "orderStatusArray" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { multiple: "", placeholder: "请选择订单状态" },
                  model: {
                    value: _vm.listQuery.orderStatusArray,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "orderStatusArray", $$v)
                    },
                    expression: "listQuery.orderStatusArray"
                  }
                },
                _vm._l(_vm.statusMap, function(key, value) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: key, value: value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "支付方式", prop: "payMethodArray" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "220px" },
                  attrs: { multiple: "", placeholder: "请选择支付方式" },
                  model: {
                    value: _vm.listQuery.payMethodArray,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "payMethodArray", $$v)
                    },
                    expression: "listQuery.payMethodArray"
                  }
                },
                _vm._l(_vm.payMethodMap, function(key, value) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: key, value: value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/order/one/list"],
                      expression: "['GET /admin/order/one/list']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "200",
              label: "订单编号",
              prop: "orderSn",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "用户ID",
              prop: "userId"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "80",
              label: "用户昵称",
              prop: "nickname"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "70",
              label: "收货人",
              prop: "consignee"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "min-width": "150",
              label: "订单状态",
              prop: "orderStatus"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-tag", [
                      _vm._v(
                        _vm._s(
                          _vm._f("orderStatusFilter")(scope.row.orderStatus)
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "订单金额",
              prop: "orderPrice"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "优惠金额",
              prop: "couponPrice"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "实付金额",
              prop: "actualPrice"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150",
              label: "支付时间",
              prop: "payTime"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "80",
              label: "支付方式",
              prop: "payMethod"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return scope.row.payMethod >= 0
                      ? [
                          _c("el-tag", [
                            _vm._v(
                              _vm._s(
                                _vm._f("payMethodFilter")(scope.row.payMethod)
                              )
                            )
                          ])
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150",
              label: "创建时间",
              prop: "createTime"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "min-width": "120",
              "class-name": "small-padding fixed-width",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["GET /admin/order/one/read/{orderId}"],
                            expression:
                              "['GET /admin/order/one/read/{orderId}']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-tickets"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    ),
                    _vm._v(" "),
                    scope.row.orderStatus == 201
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["PUT /admin/order/two/ship"],
                                expression: "['PUT /admin/order/two/ship']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-truck"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleShip(scope.row)
                              }
                            }
                          },
                          [_vm._v("发货")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.orderStatus == 500
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-circle-check"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleReturnCheck(scope.row)
                              }
                            }
                          },
                          [_vm._v("退货审核")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.orderStatus == 503
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-truck"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleReturnFinish(scope.row)
                              }
                            }
                          },
                          [_vm._v("退货完成")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "pdfDom",
            visible: _vm.orderDialogVisible,
            title: "订单详情",
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.orderDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { data: _vm.orderDetail, "label-position": "left" } },
            [
              _c("el-form-item", { attrs: { label: "订单编号" } }, [
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.orderSn))])
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "订单状态" } },
                [
                  _c("el-tag", [
                    _vm._v(
                      _vm._s(
                        _vm._f("orderStatusFilter")(_vm.orderDetail.orderStatus)
                      )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "订单用户" } }, [
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.nickname))])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "用户留言" } }, [
                _c("span", [_vm._v(_vm._s(_vm.orderDetail.message))])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货信息" } }, [
                _c("span", [
                  _vm._v("（收货人）" + _vm._s(_vm.orderDetail.consignee))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（手机号）" + _vm._s(_vm.orderDetail.mobile))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（地址）" + _vm._s(_vm.orderDetail.address))
                ])
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品信息" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.orderDetail.orderManuscriptList,
                        size: "small",
                        border: "",
                        fit: "",
                        "highlight-current-row": ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          "min-width": "150px",
                          label: "商品名称",
                          prop: "worksName"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          "min-width": "60px",
                          label: "价格(元)",
                          prop: "price"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          "min-width": "50px",
                          label: "数量",
                          prop: "number"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          "min-width": "50px",
                          label: "图片",
                          prop: "worksCover"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("img", {
                                  attrs: {
                                    src: _vm.baseUrl + scope.row.worksCover,
                                    width: "40",
                                    height: "40"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "费用信息" } }, [
                _c("span", [
                  _vm._v(
                    "（实际费用）" + _vm._s(_vm.orderDetail.actualPrice) + "元"
                  )
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "（快递费用）" +
                      _vm._s(_vm.orderDetail.freightPrice) +
                      "元 "
                  )
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "（优惠减免）" + _vm._s(_vm.orderDetail.couponPrice) + "元"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "支付信息" } }, [
                _c("span", [
                  _vm._v(
                    "（支付渠道）" +
                      _vm._s(
                        _vm._f("payMethodFilter")(_vm.orderDetail.payMethod)
                      )
                  )
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（支付时间）" + _vm._s(_vm.orderDetail.payTime))
                ])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "快递信息" } }, [
                _c("span", [
                  _vm._v("（快递公司）" + _vm._s(_vm.orderDetail.shipChannel))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（快递单号）" + _vm._s(_vm.orderDetail.shipSn))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v("（发货时间）" + _vm._s(_vm.orderDetail.shipTime))
                ])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "收货信息" } }, [
                _c("span", [
                  _vm._v(
                    "（确认收货时间）" + _vm._s(_vm.orderDetail.confirmTime)
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "退货申请信息" } },
                [
                  _c("span", [
                    _vm._v(
                      "（退货申请方式）" +
                        _vm._s(
                          _vm.orderDetail.refundMethod === 0
                            ? "余额退款"
                            : "银行卡退款"
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "（退货申请时间）" + _vm._s(_vm.orderDetail.returnTime)
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "（退货申请原因）" + _vm._s(_vm.orderDetail.returnReason)
                    )
                  ]),
                  _vm._v(" "),
                  _vm.orderDetail.refundMethod === 1
                    ? [
                        _c("span", [
                          _vm._v(
                            "（持卡人姓名）" +
                              _vm._s(_vm.orderDetail.accountName)
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "（银行卡开户行）" + _vm._s(_vm.orderDetail.bank)
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "（银行卡号）" + _vm._s(_vm.orderDetail.cardNumber)
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "退货审核信息" } }, [
                _c("span", [
                  _vm._v("（退货审核时间）" + _vm._s(_vm.orderDetail.auditTime))
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "（退货审核备注）" + _vm._s(_vm.orderDetail.rejectReason)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "退货快递信息" } }, [
                _c("span", [
                  _vm._v(
                    "（退货快递信息）" + _vm._s(_vm.orderDetail.returnLogistics)
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.orderDialogVisible = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.shipDialogVisible,
            title: "发货",
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.shipDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "shipForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                model: _vm.shipForm,
                rules: _vm.shipRules,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递公司", prop: "shipChannel" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.shipForm.shipChannel,
                      callback: function($$v) {
                        _vm.$set(_vm.shipForm, "shipChannel", $$v)
                      },
                      expression: "shipForm.shipChannel"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "快递编号", prop: "shipSn" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.shipForm.shipSn,
                      callback: function($$v) {
                        _vm.$set(_vm.shipForm, "shipSn", $$v)
                      },
                      expression: "shipForm.shipSn"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.shipDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmShip } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.returnedDialogVisible,
            title: "退货申请审核",
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.returnedDialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "returnForm",
              staticStyle: { width: "500px", "margin-left": "50px" },
              attrs: {
                rules: _vm.returnRules,
                model: _vm.returnForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否通过", prop: "examineResult" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.returnForm.examineResult,
                        callback: function($$v) {
                          _vm.$set(_vm.returnForm, "examineResult", $$v)
                        },
                        expression: "returnForm.examineResult"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("通过")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("拒绝")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "审核备注", prop: "rejectReason" } },
                [
                  _c("el-input", {
                    attrs: { rows: 4, type: "textarea" },
                    model: {
                      value: _vm.returnForm.rejectReason,
                      callback: function($$v) {
                        _vm.$set(_vm.returnForm, "rejectReason", $$v)
                      },
                      expression: "returnForm.rejectReason"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.returnedDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmReturn }
                },
                [_vm._v("审批")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }