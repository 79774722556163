import _extends from 'babel-runtime/helpers/extends';
import _Promise from 'babel-runtime/core-js/promise';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

import { asyncRouterMap, constantRouterMap } from '@/router/routers';
import { getUserMenu } from '@/api/login';
import Layout from '@/views/layout/Layout';

var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;

      return new _Promise(function (resolve) {
        getUserMenu().then(function (res) {
          res.data.data.forEach(function (item) {
            item.component = Layout;
            item.children.forEach(function (item2) {
              var component = "views" + (item.path + '/' + item2.path);
              item2.component = function () {
                return _Promise.resolve().then(function () {
                  return _interopRequireWildcard(require('@/' + component.replace(/\/+/g, '/') + '.vue'));
                });
              };
              item2.hidden = item2.ishidden;

              delete item2.ishidden;
              delete item2.redirect;
              delete item2.meta.buttonList;
              delete item2.alwaysshow;
              delete item2.no;
              delete item2.ispermission;
            });
          });
          res.data.data.push({ path: '*', redirect: '/404', hidden: true });

          var perms = data.perms;

          var accessedRouters = void 0;
          if (perms.includes('*:*:*')) {
            accessedRouters = res.data.data;
          } else {
            accessedRouters = filterAsyncRouter(asyncRouterMap, perms);
          }
          commit('SET_ROUTERS', accessedRouters);
          resolve();
        });
      });
    }
  }

  /**
   * 通过meta.perms判断是否与当前用户权限匹配
   * @param perms
   * @param route
   */
};function hasPermission(perms, route) {
  if (route.meta && route.meta.perms) {
    return perms.some(function (perm) {
      return route.meta.perms.includes(perm);
    });
  } else {
    return true;
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param perms
 */
function filterAsyncRouter(routes, perms) {
  var res = [];

  routes.forEach(function (route) {
    var tmp = _extends({}, route);
    if (tmp.children) {
      tmp.children = filterAsyncRouter(tmp.children, perms);
      if (tmp.children && tmp.children.length > 0) {
        res.push(tmp);
      }
    } else {
      if (hasPermission(perms, tmp)) {
        res.push(tmp);
      }
    }
  });

  return res;
}

export default permission;