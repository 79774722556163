import _getIterator from "babel-runtime/core-js/get-iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listSaleWorks, readSaleWorks, disablingSaleWorks, resumingSaleWorks, updateSaleWorks, recommendSaleWorks } from '@/api/manuscript/works';
import { listCategory } from "@/api/manuscript/category";
import { listStoryclass } from "@/api/manuscript/storyclass";
import { listManuscriptTag } from '@/api/manuscript/tag';
import { uploadPath } from '@/api/storage';

import AuthImgPreview from '@/components/AuthImgPreview';
import ImageUpload from '@/components/ImageUpload';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

var worksTypeArray = {
  0: '社内',
  1: '社外',
  2: '纸质书'
};

export default {
  name: 'WorksSale',
  components: { AuthImgPreview: AuthImgPreview, ImageUpload: ImageUpload, Treeselect: Treeselect },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      uploadPath: uploadPath,
      worksTypeArray: worksTypeArray,
      showSearch: true,
      categorys: [],
      categoryOptions: [],
      storyclassOptions: [],
      tagList: [],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        name: undefined,
        systemTypesArray: undefined,
        categoryId: undefined,
        storyclassId: undefined
      },
      preImageUrl: '',
      imageDialogVisible: false,
      dialogFormVisible: false,
      dataForm: {
        addTime: undefined,
        categoryId: undefined,
        id: undefined,
        otherTime: undefined,
        showTime: undefined,
        tagId: undefined,
        worksCover: undefined,
        worksDesc: undefined,
        worksKeyword: undefined,
        worksName: undefined,
        worksPrice: 0,
        freightPrice: 0
      },
      newKeywordVisible: false,
      newKeyword: '',
      keywords: [],
      rules: {
        worksName: [{ required: true, message: '作品名称不能为空', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请选择作品分类', trigger: 'blur' }],
        storyclassId: [{ required: true, message: '请选择作品分类', trigger: 'blur' }],
        tagId: [{ required: true, message: '请选择作品标签', trigger: 'blur' }],
        worksCover: [{ required: true, message: '请上传作品封面', trigger: 'blur' }],
        worksDesc: [{ required: true, message: '作品简介不能为空', trigger: 'blur' }],
        worksKeyword: [{ required: true, message: '作品关键字不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    listCategory().then(function (response) {
      //作品导航分类
      _this.categorys = response.data.data.list;
      _this.categoryOptions = _this.handleTree(response.data.data.list);
    });
    listStoryclass().then(function (response) {
      //作品分类
      _this.storyclassOptions = _this.handleTree(response.data.data.list, 'id', 'tid');
    });
    var param = { 'page': 1, 'size': 10000 };
    listManuscriptTag(param).then(function (response) {
      //作品标签
      _this.tagList = response.data.data.list;
    });
  },

  methods: {
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listSaleWorks(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
        _this2.notifyError(res.data.msg);
      });
    },

    /** 转换分类数据结构 */
    normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    handleSelect: function handleSelect(node) {
      // console.log(node)
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/manuscript/worksSaleDetail', query: { id: row.id } });
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    },
    handleStatusChange: function handleStatusChange(row) {
      var _this3 = this;

      var text = row.isOnSale === 0 ? '下架' : '上架';
      this.$confirm('确认要"' + text + '""' + row.worksName + '"作品吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.isOnSale = row.isOnSale == 1 ? 0 : 1;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this4 = this;

      if (row.isOnSale === 1) {
        // 上架
        resumingSaleWorks(row.id).then(function (response) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = _getIterator(_this4.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var v = _step.value;

              if (v.id === row.id) {
                var index = _this4.list.indexOf(v);
                _this4.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _this4.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.isOnSale = row.isOnSale == 1 ? 0 : 1;
          _this4.notifyError(response.data.msg);
        });
      } else {
        // 下架
        disablingSaleWorks(row.id).then(function (response) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = _getIterator(_this4.list), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var v = _step2.value;

              if (v.id === row.id) {
                var index = _this4.list.indexOf(v);
                _this4.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          _this4.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.isOnSale = row.isOnSale == 1 ? 0 : 1;
          _this4.notifyError(response.data.msg);
        });
      }
    },
    handleRecommendChange: function handleRecommendChange(row) {
      var that = this;
      var text = row.isRecommend === 0 ? '取消推荐' : '推荐';
      this.$confirm('确认要"' + text + '""' + row.worksName + '"作品吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        recommendSaleWorks(row.id, row.isRecommend).then(function (response) {
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = _getIterator(that.list), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var v = _step3.value;

              if (v.id === row.id) {
                var index = that.list.indexOf(v);
                that.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          that.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.isRecommend = row.isRecommend == 1 ? 0 : 1;
          that.notifyError(response.data.msg);
        });
      }).catch(function () {
        // 取消操作
        row.isRecommend = row.isRecommend == 1 ? 0 : 1;
      });
    },
    getWorksCoverUrl: function getWorksCoverUrl(imgUrl) {
      this.dataForm.worksCover = imgUrl;
    },
    handleClose: function handleClose(tag) {
      this.keywords.splice(this.keywords.indexOf(tag), 1);
      this.dataForm.worksKeyword = this.keywords.toString();
    },
    showInput: function showInput() {
      var _this5 = this;

      this.newKeywordVisible = true;
      this.$nextTick(function (_) {
        _this5.$refs.newKeywordInput.$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm() {
      var newKeyword = this.newKeyword;
      if (newKeyword && this.keywords.indexOf(newKeyword) < 0) {
        this.keywords.push(newKeyword);
        this.dataForm.worksKeyword = this.keywords.toString();
      }
      this.newKeywordVisible = false;
      this.newKeyword = '';
    },
    handleUpdate: function handleUpdate(row) {
      var _this6 = this;

      //web端作者上传的，不能修改稿件附件
      readSaleWorks(row.id).then(function (res) {
        _this6.dataForm = res.data.data;
        _this6.keywords = [];
        if (_this6.dataForm.worksKeyword !== null && _this6.dataForm.worksKeyword !== '') {
          _this6.keywords = _this6.dataForm.worksKeyword.split(',');
        }
        _this6.dialogFormVisible = true;
        _this6.$nextTick(function () {
          _this6.$refs['dataForm'].clearValidate();
        });
      }).catch(function (response) {
        _this6.notifyError(response.data.msg);
      });
    },
    updateData: function updateData() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateSaleWorks(_this7.dataForm).then(function () {
            _this7.getList();
            _this7.dialogFormVisible = false;
            _this7.notifySuccess('更新成功');
          }).catch(function (response) {
            _this7.notifyError(response.data.msg);
          });
        }
      });
    }
  }
};