import request from '@/utils/request';

// 登录方法
export function loginByUsername(params) {
  return request({
    url: '/login/login',
    method: 'post',
    data: params
  });
}

// 获取用户详细信息
export function getUserInfo() {
  return request({
    url: '/login/info',
    method: 'get'
  });
}

// 获取用户菜单
export function getUserMenu() {
  return request({
    url: '/login/menu',
    method: 'get'
  });
}

// 退出方法
export function logout() {
  return request({
    url: '/login/logout',
    method: 'post'
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/login/captchaImage',
    method: 'get'
  });
}