import _extends from 'babel-runtime/helpers/extends';
import router from './routers';
import store from '@/store';
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // getToken from cookie

NProgress.configure({ showSpinner: false }); // NProgress Configuration

// permission judge function
function hasPermission(perms, permissions) {
  if (perms.indexOf('*:*:*') >= 0) return true; // admin permission passed directly
  if (!permissions) return true;
  return perms.some(function (perm) {
    return permissions.indexOf(perm) >= 0;
  });
}

var whiteList = ['/login', '/auth-redirect']; // no redirect whitelist

router.beforeEach(function (to, from, next) {
  NProgress.start(); // start progress bar
  if (getToken()) {
    // determine if there has token
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (store.getters.perms.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetUserInfoData').then(function (res) {
          // 拉取user_info
          var perms = res.data.data.buttonpermissions; // note: perms must be a array! such as: ['GET /aaa','POST /bbb']
          store.dispatch('GenerateRoutes', { perms: perms }).then(function () {
            // 根据perms权限生成可访问的路由表
            router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表
            next(_extends({}, to, { replace: true })); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
          });
        }).catch(function (err) {
          store.dispatch('FedLogOut').then(function () {
            Message.error(err || '验证失败，请重新登录');
            next({ path: '/' });
          });
        });
      } else {
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        if (hasPermission(store.getters.perms, to.meta.perms)) {
          next();
        } else {
          next({ path: '/401', replace: true, query: { noGoBack: true } });
        }
        // 可删 ↑
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else if (to.path === '/manuscript-edit') {
      next('/login?redirect=' + to.path + '?id=' + to.query.id); // 三审系统，在线编辑或在线查看
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    } else {
      next('/login?redirect=' + to.path); // 否则全部重定向到登录页
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach(function () {
  NProgress.done(); // finish progress bar
});