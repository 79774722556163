import _Promise from 'babel-runtime/core-js/promise';
import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listCoupon, createCoupon, updateCoupon, deleteCoupon, onSaleCoupon } from '@/api/promotion/coupon';
import { listCategory } from "@/api/manuscript/category";
import { listSaleWorks, readSaleWorks } from '@/api/manuscript/works';

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getParentAndCurrNode, pickerOptions } from '@/utils/index.js';

var useTypeMap = {
  0: '全场通用',
  1: '指定分类',
  2: '指定作品'
};
var worksTypeArray = {
  0: '社内',
  1: '社外'
};

export default {
  name: 'Coupon',
  components: { Treeselect: Treeselect },
  filters: {
    formatGoodsType: function formatGoodsType(goodsType) {
      return goodsType === 0 ? '全场通用' : goodsType === 1 ? '指定分类' : '指定作品';
    }
  },
  data: function data() {
    return {
      worksTypeArray: worksTypeArray,
      ids: [], // 选中数组
      showSearch: true,
      single: true,
      multiple: false,
      useTypeMap: useTypeMap,
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        name: undefined,
        typesArray: undefined,
        endDateRange: undefined
      },
      pickerOptions: {
        shortcuts: []
      },
      searchTime: [],
      categorys: [],
      categoryOptions: [], //作品分类
      dataForm: {
        id: undefined,
        name: undefined,
        desc: undefined,
        total: 0,
        discount: 0,
        minConsumption: undefined,
        limitNumber: 1,
        type: 0,
        valueId: undefined,
        valueIds: [],
        isOnSale: 1,
        startTime: null,
        endTime: null,
        expireTime: [] //券有效时间
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '创建'
      },
      rules: {
        name: [{ required: true, message: '优惠券标题不能为空', trigger: 'blur' }],
        expireTime: [{ type: 'array', required: true, message: '请选择有效时间范围', trigger: 'blur' }],
        minConsumption: [{ required: true, message: '最低消费不能为空', trigger: 'blur' }, { pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: "最低消费金额为大于0 的数", trigger: "blur" }],
        discount: [{ required: true, message: '满减金额不能为空', trigger: 'blur' }, { pattern: /^\d+(\.\d{0,2})?$/, message: "满减金额大于等于0的数", trigger: "blur" }],
        limitNumber: [{ required: true, message: '每人限领数量不能为空', trigger: 'blur' }, { pattern: /^[0-9]\d*$/, message: "每人限领数量为大于等于0的正整数", trigger: "blur" }],
        total: [{ required: true, message: '优惠券总数不能为空', trigger: 'blur' }, { pattern: /^[0-9]\d*$/, message: "优惠券总数为大于等于0的正整数", trigger: "blur" }]
      },
      downloadLoading: false,
      worksDialogVisible: false,
      listQueryWorks: {
        page: 1,
        size: 10,
        name: undefined,
        systemType: undefined,
        categoryId: undefined
      },
      worksSaleList: [],
      worksTotal: 0,
      worksSaleItem: {},
      worksListLoading: false
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      this.listQuery.endDateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.endDateRange = [];
        this.listQuery.endDateRange.push(this.searchTime[0]);
        this.listQuery.endDateRange.push(this.searchTime[1]);
      }
      listCoupon(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.id;
      });
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    reset: function reset() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        desc: undefined,
        total: 0,
        discount: 0,
        minConsumption: undefined,
        limitNumber: 1,
        type: 0,
        valueId: undefined,
        valueIds: [],
        isOnSale: 1,
        startTime: null,
        endTime: null,
        expireTime: []
      };
    },
    changeExipreTime: function changeExipreTime(val) {
      if (val !== null && val instanceof Array && val.length === 2) {
        this.dataForm.startTime = val[0];
        this.dataForm.endTime = val[1];
        this.$forceUpdate(); // 强制更新界面
        this.$set(this.dataForm, 'expireTime', val);
      }
    },
    changeUseRange: function changeUseRange(val) {
      if ((val === 1 || val === 2) && this.categoryOptions.length === 0) {
        this.getCategoryList();
      }
    },
    getCategoryList: function getCategoryList() {
      var _this2 = this;

      // 获取启用状态下的所有分类
      var params = { 'deleted': 0 };
      listCategory(params).then(function (response) {
        _this2.categorys = response.data.data.list;
        _this2.categoryOptions = _this2.handleTree(response.data.data.list);
      });
    },

    /** 转换分类数据结构 */
    normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },
    handleSelectCategory: function handleSelectCategory() {},
    handleCreate: function handleCreate() {
      var _this3 = this;

      this.reset();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this3.$refs['dataForm'].clearValidate();
      });
      this.resetForm("dataForm");
    },
    createData: function createData() {
      var _this4 = this;

      this.rules.valueId = null;
      if (this.dataForm.type === 1) {
        //指定分类
        this.rules.valueId = [{ required: true, message: '指定分类不能为空', trigger: 'blur' }];
      } else if (this.dataForm.type === 2) {
        //this.rules.worksName = [{ required: true, message: '指定作品不能为空', trigger: 'change' }]
        if (!this.dataForm.worksName || this.dataForm.worksName === '') {
          return this.notifyInfo('请选择指定作品');
        }
      }
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createCoupon(_this4.dataForm).then(function (response) {
            _this4.getList();
            _this4.dialogFormVisible = false;
            _this4.notifySuccess('创建优惠券成功');
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      if (row.id) {
        this.dataForm = _Object$assign({}, row);
      } else {
        //顶部编辑
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = _getIterator(this.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var v = _step.value;

            if (v.id === this.ids[0]) {
              this.dataForm = _Object$assign({}, v);
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
      this.dataForm.expireTime = [];
      this.dataForm.expireTime[0] = this.dataForm.startTime;
      this.dataForm.expireTime[1] = this.dataForm.endTime;

      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this5.$refs['dataForm'].clearValidate();
      });

      if (this.dataForm.type === 1 || this.dataForm.type === 2) {
        // 查询作品分类
        this.getCategoryList();
        if (this.dataForm.type === 1) {
          this.dataForm.valueId = this.dataForm.valueId == 0 ? undefined : this.dataForm.valueId;
        } else {
          this.dataForm.worksId = this.dataForm.worksId == 0 ? undefined : this.dataForm.worksId;
          if (this.dataForm.worksId) {
            this.getWorksSaleDetail(this.dataForm.worksId);
          }
        }
      }
    },
    updateData: function updateData() {
      var _this6 = this;

      this.rules.valueId = null;
      if (this.dataForm.type === 1) {
        //指定分类
        this.rules.valueId = [{ required: true, message: '指定分类不能为空', trigger: 'blur' }];
      } else if (this.dataForm.type === 2) {
        if (!this.dataForm.worksName || this.dataForm.worksName === '') {
          return this.notifyInfo('请选择指定作品');
        }
      }
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateCoupon(_this6.dataForm).then(function () {
            _this6.getList();
            _this6.dialogFormVisible = false;
            _this6.notifySuccess('更新优惠券成功');
          }).catch(function (response) {
            _this6.notifyError(response.data.msg);
          });
        }
      });
    },
    handleDelete: function handleDelete(row) {
      var _this7 = this;

      this.$confirm('确认要删除"' + row.name + '"优惠券吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteCoupon(row.id).then(function (response) {
          _this7.notifySuccess('删除成功');
          _this7.getList();
        }).catch(function (response) {
          _this7.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/promotion/couponDetail', query: { id: row.id } });
    },
    handleStatusChange: function handleStatusChange(row) {
      var _this8 = this;

      var text = row.isOnSale == 0 ? '下架' : '上架';
      this.$confirm('确认要"' + text + '""' + row.name + '"优惠券吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this8.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.isOnSale = row.isOnSale == 0 ? 1 : 0;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this9 = this;

      onSaleCoupon(row.id, row.isOnSale).then(function (response) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = _getIterator(_this9.list), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var v = _step2.value;

            if (v.id === row.id) {
              var index = _this9.list.indexOf(v);
              _this9.list.splice(index, 1, row);
              break;
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        _this9.notifySuccess(text + '成功');
      }).catch(function (response) {
        row.isOnSale = row.isOnSale == 0 ? 1 : 0;
        _this9.notifyError(response.data.msg);
      });
    },
    handleSelectWorks: function handleSelectWorks() {
      this.worksDialogVisible = !this.worksDialogVisible;
      this.getWorksList();
    },
    formatWorksCategoryName: function formatWorksCategoryName(row, column) {
      var categoryName = '';
      var arrNodes = getParentAndCurrNode(this.categorys, row.categoryId);
      for (var i = arrNodes.length - 1; i >= 0; i--) {
        categoryName += arrNodes[i].name + '/';
      }
      return categoryName ? categoryName.substring(0, categoryName.length - 1) : '';
    },
    handleFilterWorks: function handleFilterWorks() {
      this.listQueryWorks.page = 1;
      this.getWorksList();
    },
    resetQueryWorks: function resetQueryWorks() {
      this.resetForm("queryFormWorks");
      this.handleFilterWorks();
    },
    handleSingleElection: function handleSingleElection(row) {
      this.worksSaleItem = row;
    },
    handleConfirmSelectWorks: function handleConfirmSelectWorks() {
      this.dataForm.worksId = this.worksSaleItem.id;
      this.dataForm.worksName = this.worksSaleItem.worksName;
      this.worksDialogVisible = !this.worksDialogVisible;
    },
    getWorksList: function getWorksList() {
      var _this10 = this;

      // 销售作品列表
      this.worksListLoading = true;
      listSaleWorks(this.listQueryWorks).then(function (response) {
        _this10.worksSaleList = response.data.data.list;
        _this10.worksTotal = response.data.data.total;
        _this10.worksListLoading = false;
      }).catch(function () {
        _this10.worksSaleList = [];
        _this10.worksTotal = 0;
        _this10.worksListLoading = false;
      });
    },
    getWorksSaleDetail: function getWorksSaleDetail(id) {
      var _this11 = this;

      readSaleWorks(id).then(function (response) {
        _this11.$set(_this11.dataForm, "worksName", response.data.data.worksName);
      }).catch(function (response) {
        _this11.notifyError(response.data.msg);
      });
    },
    handleDownload: function handleDownload() {
      var _this12 = this;

      this.downloadLoading = true;
      _Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/vendor/Export2Excel'));
      }).then(function (excel) {
        var tHeader = ['优惠券ID', '名称', '内容', '标签', '最低消费', '减免金额', '每人限领', '优惠券数量'];
        var filterVal = ['id', 'name', 'desc', 'tag', 'min', 'discount', 'limit', 'total'];
        excel.export_json_to_excel2(tHeader, _this12.list, filterVal, '优惠券信息');
        _this12.downloadLoading = false;
      });
    }
  }
};