import request from '@/utils/request';
import Qs from 'qs';

export function listKeyword(params) {
  return request({
    url: '/keyword/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createKeyword(data) {
  return request({
    url: '/keyword/create',
    method: 'post',
    data: data
  });
}

export function updateKeyword(data) {
  return request({
    url: '/keyword/update',
    method: 'put',
    data: data
  });
}

export function deleteKeyword(id) {
  return request({
    url: '/keyword/delete/' + id,
    method: 'delete'
  });
}

/**
 * 禁用
 */
export function disablingKeyword(id) {
  return request({
    url: '/keyword/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingKeyword(id) {
  return request({
    url: '/keyword/resuming/' + id,
    method: 'PUT'
  });
}