import request from '@/utils/request';
import Qs from 'qs';

export function listAuthor(params) {
  return request({
    url: '/author/list?' + Qs.stringify(params, { arrayFormat: 'repeat' }),
    method: 'get'
  });
}

export function detailAuthor(authorId) {
  return request({
    url: '/author/read/' + authorId,
    method: 'get'
  });
}

export function updAuthor(data) {
  return request({
    url: '/author/update',
    method: 'put',
    data: data
  });
}

/**
 * 审核 （审核拒绝 or 审核通过获取关联作者库列表）
 */
export function approveAuthor(authorId, data) {
  return request({
    url: '/author/approve/' + authorId,
    method: 'PUT',
    data: data
  });
}

/**
 * 审核通过并自动关联作者库
 */
export function approveAuthorPass(authorId, data) {
  return request({
    url: '/author/againapprove/' + authorId,
    method: 'PUT',
    data: data
  });
}

//---------------------作者库分类管理--------------------------------------
export function listAuthorCategory(params) {
  return request({
    url: '/author/library/category/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createAuthorCategory(data) {
  return request({
    url: '/author/library/category/create',
    method: 'post',
    data: data
  });
}

export function readAuthorCategory(id) {
  return request({
    url: '/author/library/category/read/' + id,
    method: 'get'
  });
}

export function updateAuthorCategory(data) {
  return request({
    url: '/author/library/category/update',
    method: 'put',
    data: data
  });
}

export function deleteAuthorCategory(id) {
  return request({
    url: '/author/library/category/delete/' + id,
    method: 'delete'
  });
}

/**
 * 作者库分类父层级详情查询
 * @param id 当前分类id
 */
export function readAuthorParentCategory(id) {
  return request({
    url: '/author/library/category/read/parent/' + id,
    method: 'get'
  });
}

/**
 * 禁用
 */
export function disablingAuthorCategory(id) {
  return request({
    url: '/author/library/category/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingAuthorCategory(id) {
  return request({
    url: '/author/library/category/resuming/' + id,
    method: 'PUT'
  });
}

//---------------------作者库管理--------------------------------------
export function listAuthorLibrary(params) {
  return request({
    url: '/author/library/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createAuthorLibrary(data) {
  return request({
    url: '/author/library/create',
    method: 'post',
    data: data
  });
}

export function readAuthorLibrary(id) {
  return request({
    url: '/author/library/read/' + id,
    method: 'get'
  });
}

export function updateAuthorLibrary(data) {
  return request({
    url: '/author/library/update',
    method: 'put',
    data: data
  });
}

export function deleteAuthorLibrary(id) {
  return request({
    url: '/author/library/delete/' + id,
    method: 'delete'
  });
}

/**
 * 禁用
 */
export function disablingAuthorLibrary(id) {
  return request({
    url: '/author/library/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingAuthorLibrary(id) {
  return request({
    url: '/author/library/resuming/' + id,
    method: 'PUT'
  });
}

/**
 * 作者库关联申请的作者
 * @param {申请成为作者Id} authorLibraryId 
 * @param {作者库中作者Id} authorApproveId 
 * @returns 
 */
export function associationAuthor(authorLibraryId, authorApproveId) {
  return request({
    url: '/author/library/association/' + authorLibraryId + '/' + authorApproveId,
    method: 'PUT'
  });
}

/**
 * 是否推荐作者(0 否，1 是)
 */
export function recommendAuthor(authorId, isRecommend) {
  return request({
    url: '/author/library/recommend/' + authorId + '/' + isRecommend,
    method: 'PUT'
  });
}