var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.listQuery,
            size: "small",
            inline: true,
            "label-width": "88px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "销售时间" } },
            [
              _c("el-date-picker", {
                staticClass: "filter-item",
                staticStyle: { width: "240px" },
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.searchTime,
                  callback: function($$v) {
                    _vm.searchTime = $$v
                  },
                  expression: "searchTime"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "统计类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "200px" },
                  attrs: { clearable: "", placeholder: "请选择统计类型" },
                  model: {
                    value: _vm.listQuery.type,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "type", $$v)
                    },
                    expression: "listQuery.type"
                  }
                },
                _vm._l(_vm.typeOptions, function(item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.display_name, value: item.key }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/statistics/works/sales/stat"],
                      expression: "['GET /admin/statistics/works/sales/stat']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "日期",
              prop: "dateStr",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "作品名称", prop: "worksName" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "标签名称",
              prop: "tagName",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品数量",
              prop: "orderCounts",
              width: "120"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单金额(元)",
              prop: "orderPrice",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "实付金额(元)",
              prop: "actualPrice",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150",
              label: "操作",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["GET /admin/statistics/works/sales/stat"],
                            expression:
                              "['GET /admin/statistics/works/sales/stat']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-data-line"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openEchart(scope.row)
                          }
                        }
                      },
                      [_vm._v("销售统计图")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogEchartVisible,
            title: _vm.title,
            width: "900px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogEchartVisible = $event
            }
          }
        },
        [
          _c("ve-histogram", {
            attrs: {
              extend: _vm.chartExtend,
              data: _vm.chartData,
              settings: _vm.chartSettings,
              loading: _vm.chartLoading
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }