//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mailList, mailDelete, mailSendByUsers } from '@/api/comment/mail';
import { listUser } from '@/api/member/member';
import { pickerOptions } from '@/utils/index';
import BackToTop from '@/components/BackToTop';

var mailTypeMap = {
  1: '系统自动通知',
  2: '手动发送通知'
};
export default {
  name: 'Mail',
  components: { BackToTop: BackToTop },
  data: function data() {
    return {
      showSearch: true,
      single: true,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        title: undefined,
        dateRange: undefined,
        messageTypeArray: []
      },
      mailTypeMap: mailTypeMap,
      pickerOptions: {
        shortcuts: []
      },
      searchTime: [],
      sendDialogVisible: false,
      sendForm: {
        messageId: '',
        userIds: ''
      },
      uIds: [],
      userDialogVisible: false,
      userList: [],
      userTotal: 0,
      userListLoading: false,
      userListQuery: {
        page: 1,
        size: 10,
        nickname: undefined,
        userId: undefined,
        statusArray: [0] //查询可用会员列表
      },
      genderDic: ['未知', '男', '女'],
      rules: {
        userIds: [{ required: true, message: '会员ID不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      mailList(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleCreate: function handleCreate() {
      this.$router.push({ path: '/comment/mailCreate' });
    },
    handleUpdate: function handleUpdate(row) {
      this.$router.push({ path: '/comment/mailCreate', query: { id: row.id } });
    },
    handleDetail: function handleDetail(row) {
      this.$router.push({ path: '/comment/mailDetail', query: { id: row.id } });
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;

      this.$confirm('确认要删除"' + row.title + '"站内信吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        mailDelete(row.id).then(function (response) {
          _this2.notifySuccess('删除成功');
          _this2.getList();
        }).catch(function (response) {
          _this2.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作
      });
    },
    handleSend: function handleSend(row) {
      if (row.rangeType === 1) {
        //全部用户
        this.$confirm('确认要给全部会员发送该通知吗?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {}).catch(function () {// 取消操作
        });
      } else {
        this.sendDialogVisible = !this.sendDialogVisible;
        this.sendForm.messageId = row.id;
      }
    },
    handleSelectUser: function handleSelectUser() {
      this.userDialogVisible = !this.userDialogVisible;
      this.getUserList();
    },
    getUserList: function getUserList() {
      var _this3 = this;

      this.userListLoading = true;
      listUser(this.userListQuery).then(function (response) {
        var userDatas = response.data.data.list.map(function (v) {
          //去除不必要的属性，提高渲染速度
          return {
            id: v.id,
            nickname: v.nickname,
            gender: v.gender,
            usernameMail: v.usernameMail,
            usernameMobile: v.usernameMobile,
            createTime: v.createTime
          };
        });
        _this3.userList = userDatas;
        _this3.userTotal = response.data.data.total;
        _this3.userListLoading = false;
      }).catch(function () {
        _this3.userList = [];
        _this3.userTotal = 0;
        _this3.userListLoading = false;
      });
    },
    handleUserFilter: function handleUserFilter() {
      this.userListQuery.page = 1;
      this.getUserList();
    },

    /** 重置按钮操作 */
    resetUserQuery: function resetUserQuery() {
      this.resetForm("queryUserForm");
      this.handleUserFilter();
    },
    getRowKeys: function getRowKeys(row) {
      return row.id;
    },
    handleSelectionChange: function handleSelectionChange(selection) {
      this.uIds = selection.map(function (item) {
        return item.id;
      });
      console.log(this.uIds);
    },
    handleSelectUserId: function handleSelectUserId() {
      this.userDialogVisible = !this.userDialogVisible;
      this.sendForm.userIds = this.uIds.join(',');
    },
    handleSendToUsers: function handleSendToUsers() {
      var _this4 = this;

      this.$refs['sendForm'].validate(function (valid) {
        if (valid) {
          mailSendByUsers(_this4.sendForm.messageId, { 'ids': _this4.sendForm.userIds.split(',') }).then(function (response) {
            _this4.notifySuccess('发送成功');
            _this4.$refs['userTable'].clearSelection(); //清空选中,不然会在下一个批量操作中记录上一次选中的数据
            _this4.sendForm.userIds = '';
            _this4.sendDialogVisible = !_this4.sendDialogVisible;
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    }
  }
};