//
//
//
//
//
//
//

import { getToken } from "@/utils/auth";

export default {
  name: "FileDownload",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      complete: 0
    };
  },

  methods: {
    downloadXhr: function downloadXhr(url) {
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.timeout = 180 * 1000; //超时设置xx秒
      xhr.setRequestHeader('AdminAuthorizationToken', getToken());
      xhr.addEventListener('progress', function (event) {
        if (event.total > 0) {
          var percentComplete = event.loaded / event.total;
          var temp = (percentComplete * 100).toFixed(2);
          that.complete = parseFloat(temp);
          if (that.complete === 100.00) {
            setTimeout(function () {
              that.$emit('update:visible', false); // 直接修改父组件的属性
              that.complete = 0;
            }, 1200);
          }
        } else {
          that.$emit('update:visible', false); // 直接修改父组件的属性
          that.complete = 0;
        }
      }, false);
      xhr.onload = function () {
        if (xhr.status === 200) {
          var blob = xhr.response;
          var objectUrl = URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.download = url.slice(url.lastIndexOf("_") + 1).replace('.old', '');
          a.href = objectUrl;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(objectUrl);
        } else {
          that.$emit('update:visible', false); // 直接修改父组件的属性
          that.complete = 0;
          that.$modal.msgError("\u4E0B\u8F7D\u6587\u4EF6\u5F02\u5E38\uFF0C\u8BF7\u7A0D\u540E\u518D\u8BD5");
        }
      }, xhr.ontimeout = function () {
        that.$emit('update:visible', false); // 直接修改父组件的属性
        that.complete = 0;
        that.$modal.msgError("\u4E0B\u8F7D\u6587\u4EF6\u8D85\u65F6\uFF0C\u8BF7\u91CD\u8BD5");
      }, xhr.onerror = function () {
        that.$emit('update:visible', false); // 直接修改父组件的属性
        that.complete = 0;
        that.$modal.msgError("\u4E0B\u8F7D\u6587\u4EF6\u51FA\u9519\uFF0C\u8BF7\u91CD\u8BD5");
      }, xhr.send();
    }
  }
};