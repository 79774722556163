import request from '@/utils/request';

export function listAdmin(query) {
  return request({
    url: '/admin/list',
    method: 'get',
    params: query
  });
}

export function createAdmin(data) {
  return request({
    url: '/admin/create',
    method: 'post',
    data: data
  });
}

export function detailAdmin(id) {
  return request({
    url: '/admin/read/' + id,
    method: 'get'
  });
}

export function updateAdmin(data) {
  return request({
    url: '/admin/update',
    method: 'PUT',
    data: data
  });
}

/**
 * 禁用管理员
 */
export function disablingAdmin(id) {
  return request({
    url: '/admin/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用管理员 
 */
export function resumingAdmin(id) {
  return request({
    url: '/admin/resuming/' + id,
    method: 'PUT'
  });
}

export function deleteAdmin(id) {
  return request({
    url: '/admin/delete/' + id,
    method: 'DELETE'
  });
}

/**
 * 重置管理员密码
 */
export function resetPwd(id) {
  return request({
    url: '/admin/reset/password/' + id,
    method: 'put'
  });
}