import request from '@/utils/request';
import Qs from 'qs';

export function listCoupon(params) {
  return request({
    url: '/coupon/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createCoupon(data) {
  return request({
    url: '/coupon/create',
    method: 'post',
    data: data
  });
}

export function readCoupon(id) {
  return request({
    url: '/coupon/read/' + id,
    method: 'get'
  });
}

export function updateCoupon(data) {
  return request({
    url: '/coupon/update',
    method: 'put',
    data: data
  });
}

export function deleteCoupon(id) {
  return request({
    url: '/coupon/delete/' + id,
    method: 'delete'
  });
}

/**
 * 上下架
 */
export function onSaleCoupon(couponId, isOnSale) {
  return request({
    url: '/coupon/sale/' + couponId + '/' + isOnSale,
    method: 'PUT'
  });
}

/**
 * 用户领用优惠劵情况列表
 */
export function listCouponUser(params) {
  return request({
    url: '/coupon/user/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}