//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '@/assets/logo/logo.png';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '数字传播应用平台',
      logo: Logo
    };
  }
};