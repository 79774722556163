import request from '@/utils/request';
import Qs from 'qs';

/**
 * 【装订成册订单管理】列表查询装订成册订单
 * @param {*} params 
 * @returns 
 */
export function bindingOrderList(params) {
  return request({
    url: '/bidding/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 【装订成册订单管理】详情查询装订成册订单
 * @param {*} params 
 * @returns 
 */
export function bindingOrderDetail(orderId) {
  return request({
    url: '/bidding/read/' + orderId,
    method: 'get'
  });
}

/**
 * 【装订成册订单管理】装订成册订单发货
 * @param {*} params 
 * @returns 
 */
export function bindingOrderShip(data) {
  return request({
    url: '/bidding/ship',
    method: 'put',
    data: data
  });
}

/**
 * 【装订成册订单管理】装订成册订单确认装订金额
 * @param {*} params 
 * @returns 
 */
export function bindingOrderSurePrice(data) {
  return request({
    url: '/bidding/sure',
    method: 'put',
    data: data
  });
}

/**
 * 【装订成册订单管理】装订成册订单确认订单转印单
 * @param {*} params 
 * @returns 
 */
export function bindingOrderPress(orderId, number) {
  return request({
    url: '/bidding/press/' + orderId + '/' + number,
    method: 'put'
  });
}

/**
 * 【装订成册管理】列表查询定制选项价格配置
 * @param {*} params 
 * @returns 
 */
export function bindingOptionList(params) {
  return request({
    url: '/bidding/option/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 【装订成册管理】更新定制选项价格配置
 * @param {*} params 
 * @returns 
 */
export function bindinOptionUpdate(data) {
  return request({
    url: '/bidding/option/update',
    method: 'put',
    data: data
  });
}