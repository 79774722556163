//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { returnLogistics } from '@/api/order.js';
import CommonDialog from '@/components/common/common_dialog.vue';

import * as RegExp from '@/utils/RegExp.js';

export default {
  name: 'OrderPaperReturnLogistics',
  components: {
    CommonDialog: CommonDialog
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    orderSn: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isOpen: false,
      form: {
        trackingCompany: '',
        trackingNumber: '',
        sn: ''
      },
      loading: false,
      dContent: ''
    };
  },

  watch: {
    show: function show(newVal, oldVal) {
      this.isOpen = newVal;
    }
  },
  methods: {
    handleSubmit: function handleSubmit() {
      if (RegExp.isEmpty(this.form.trackingCompany)) {
        return this.$msgWarning("请输入快递公司");
      }
      if (RegExp.isEmpty(this.form.trackingNumber)) {
        return this.$msgWarning("请输入快递单号");
      }

      this.dContent = '确定要提交退货快递信息吗？';
      this.$refs.dialog.showPop();
    },
    submitDialogCallback: function submitDialogCallback() {
      var _this = this;

      this.form.sn = this.orderSn;
      var params = {
        'returnLogistics': this.form.trackingCompany + '/' + this.form.trackingNumber,
        'sn': this.orderSn
      };
      this.loading = true;
      returnLogistics(params).then(function (res) {
        _this.$msgSuccess('快递信息提交成功');
        _this.loading = false;
        _this.resetForm();
        _this.closePop();
        _this.$emit('trackingSuccess');
      }).catch(function (err) {
        _this.loading = false;
        _this.$msgError(err.msg);
      });
    },
    closePop: function closePop() {
      this.isOpen = !this.isOpen;
      this.$emit('close');
    },
    resetForm: function resetForm() {
      this.form = {
        trackingCompany: '',
        trackingNumber: '',
        sn: ''
      };
    }
  }
};