import _Promise from 'babel-runtime/core-js/promise';
import { loginByUsername, logout, getUserInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';

var user = {
  state: {
    user: '',
    status: '',
    code: '',
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    perms: [],
    dataPermissions: [],
    setting: {
      articlePlatform: []
    }
  },

  mutations: {
    SET_CODE: function SET_CODE(state, code) {
      state.code = code;
    },
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_SETTING: function SET_SETTING(state, setting) {
      state.setting = setting;
    },
    SET_STATUS: function SET_STATUS(state, status) {
      state.status = status;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMS: function SET_PERMS(state, perms) {
      state.perms = perms;
    },
    SET_DATAPERMISSIONS: function SET_DATAPERMISSIONS(state, dataPermissions) {
      state.dataPermissions = dataPermissions;
    }
  },

  actions: {
    // 用户名登录
    LoginByUsername: function LoginByUsername(_ref, userInfo) {
      var commit = _ref.commit;

      return new _Promise(function (resolve, reject) {
        loginByUsername(userInfo).then(function (response) {
          var token = response.data.data.token;
          commit('SET_TOKEN', token);
          setToken(token);
          localStorage.setItem('token', token);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },


    // 获取用户信息
    GetUserInfoData: function GetUserInfoData(_ref2) {
      var commit = _ref2.commit,
          state = _ref2.state;

      return new _Promise(function (resolve, reject) {
        getUserInfo(state.token).then(function (response) {
          var data = response.data.data;

          if (data.buttonpermissions && data.buttonpermissions.length > 0) {
            // 验证返回的perms是否是一个非空数组
            commit('SET_PERMS', data.buttonpermissions);
          } else {
            reject('获取用户信息: 权限数组不能为空，请联系管理员 !');
          }

          commit('SET_ROLES', data.roles);
          commit('SET_NAME', data.admin.username);
          commit('SET_AVATAR', data.admin.avatar);
          commit('SET_USER', data.admin);
          commit('SET_DATAPERMISSIONS', data.dataPermissions || []);
          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },


    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
          state = _ref3.state;

      return new _Promise(function (resolve, reject) {
        logout(state.token).then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_PERMS', []);
          commit('SET_DATAPERMISSIONS', []);
          removeToken();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },


    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;

      return new _Promise(function (resolve) {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },


    // 动态修改权限
    ChangeRoles: function ChangeRoles(_ref5, role) {
      var commit = _ref5.commit,
          dispatch = _ref5.dispatch;

      return new _Promise(function (resolve) {
        commit('SET_TOKEN', role);
        setToken(role);
        getUserInfo(role).then(function (response) {
          var data = response.data;
          commit('SET_ROLES', data.roles);
          commit('SET_PERMS', data.perms);
          commit('SET_NAME', data.name);
          commit('SET_AVATAR', data.avatar);
          commit('SET_INTRODUCTION', data.introduction);
          commit('SET_DATAPERMISSIONS', data.dataPermissions || []);
          dispatch('GenerateRoutes', data); // 动态修改权限后 重绘侧边菜单
          resolve();
        });
      });
    }
  }
};

export default user;