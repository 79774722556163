//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listOrderPaperBook, orderPaperBookDetail, orderPaperBookShip, orderPaperBookReturnCheck, orderPaperBookRefund } from '@/api/order/order.js';
import { pickerOptions } from '@/utils/index';

var statusMap = { // 101 订单生成，未支付；102，下单未支付用户取消，订单关闭；103，下单未支付超期，系统自动关闭订单；201 支付完成，商家未发货；301 商家发货，用户未确认；401 用户确认收货；402 系统自动确认收货
  101: '待支付',
  102: '用户取消',
  103: '系统取消',
  201: '待发货',
  301: '已发货',
  401: '用户确认收货',
  402: '系统确认收货',
  500: '退货申请待审核',
  501: '退货申请驳回',
  502: '退货申请通过，待退货',
  503: '用户退货中',
  504: '用户退货完成'
};
var payMethodMap = { // （0 余额 1 微信 2 支付宝）
  0: '余额',
  1: '微信',
  2: '支付宝'
};

export default {
  name: 'OrderPaperBook',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      showSearch: true,
      multiple: false,
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        orderSn: undefined,
        dateRange: undefined,
        orderStatusArray: [],
        payMethodArray: []
      },
      searchTime: [],
      pickerOptions: {
        shortcuts: []
      },
      statusMap: statusMap,
      payMethodMap: payMethodMap,
      orderDialogVisible: false,
      orderDetail: {},
      shipDialogVisible: false,
      shipForm: {
        orderId: undefined,
        shipChannel: undefined,
        shipSn: undefined
      },
      shipRules: {
        shipChannel: [{ required: true, message: '快递公司不能为空', trigger: 'blur' }],
        shipSn: [{ required: true, message: '快递单号不能为空', trigger: 'blur' }]
      },
      returnedDialogVisible: false,
      returnForm: {
        orderId: undefined,
        examineResult: undefined,
        rejectReason: undefined
      },
      returnRules: {
        examineResult: [{ required: true, message: '审核状态不能为空！', trigger: 'blur' }],
        rejectReason: [{ required: true, message: '审核备注不能为空！', trigger: 'blur' }]
      }
    };
  },

  filters: {
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    },
    payMethodFilter: function payMethodFilter(payMethod) {
      return payMethodMap[payMethod];
    }
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      listOrderPaperBook(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    },
    handleDetail: function handleDetail(row) {
      var _this2 = this;

      // 详情
      orderPaperBookDetail(row.id).then(function (res) {
        _this2.orderDetail = res.data.data;
        _this2.orderDialogVisible = true;
      }).catch(function (err) {
        _this2.notifyError(err.data.msg);
      });
    },
    handleShip: function handleShip(row) {
      var _this3 = this;

      // 发货弹框
      this.shipForm.orderId = row.id;
      this.shipDialogVisible = true;
      this.$nextTick(function () {
        _this3.$refs['shipForm'].clearValidate();
      });
    },
    confirmShip: function confirmShip() {
      var _this4 = this;

      // 发货弹框
      this.$refs['shipForm'].validate(function (valid) {
        if (valid) {
          orderPaperBookShip(_this4.shipForm).then(function (response) {
            _this4.shipDialogVisible = false;
            _this4.notifySuccess('确认发货成功');
            _this4.getList();
          }).catch(function (response) {
            _this4.notifyError(response.data.msg);
          });
        }
      });
    },
    handleReturnCheck: function handleReturnCheck(row) {
      var _this5 = this;

      // 退货审核
      this.returnForm.orderId = row.id;
      this.returnedDialogVisible = true;
      this.$nextTick(function () {
        _this5.$refs['returnForm'].clearValidate();
      });
    },
    confirmReturn: function confirmReturn() {
      var _this6 = this;

      // 退货审核提交
      this.$refs['returnForm'].validate(function (valid) {
        if (valid) {
          orderPaperBookReturnCheck(_this6.returnForm).then(function (response) {
            _this6.returnedDialogVisible = false;
            _this6.notifySuccess('退货申请审核成功');
            _this6.getList();
          }).catch(function (response) {
            _this6.notifyError(response.data.msg);
          });
        }
      });
    },
    handleReturnFinish: function handleReturnFinish(row) {
      var _this7 = this;

      // 退货完成
      this.$confirm('是否确定退货完成?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        orderPaperBookRefund(row.id).then(function (response) {
          _this7.notifySuccess('退货完成');
          _this7.getList();
        });
      });
    }
  }
};