import request from '@/utils/request';
import Qs from 'qs';

export function listUser(params) {
  return request({
    url: '/user/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function detailUser(id) {
  return request({
    url: '/user/read/' + id,
    method: 'get'
  });
}

/**
 * 禁用
 */
export function disablingUser(data) {
  return request({
    url: '/user/disabling',
    method: 'PUT',
    data: data
  });
}

/**
 * 启用 
 */
export function resumingUser(id) {
  return request({
    url: '/user/resuming/' + id,
    method: 'PUT'
  });
}