/**
 * Created by jiachenpan on 16/11/18.
 */
export function isExternal(path) {
  return (/^(https?:|mailto:|tel:)/.test(path)
  );
}

export function isvalidUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/* 合法uri*/
export function validateURL(textval) {
  var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}

/* 小写字母*/
export function validateLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/* 大写字母*/
export function validateUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/* 大小写字母*/
export function validateAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * validate email
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function notIntOrDecimal(rule, value, callback) {
  if (!value) {
    return callback(new Error('输入不可以为空'));
  }
  // 校驗(小數/數字)正則表達式
  var pattern = /^[0-9]+([.]{1}[0-9]+){0,1}$/;
  if (pattern.test(value)) {
    callback();
  } else {
    callback(new Error('请输入正整数或者小数'));
  }
}

// 验证是否整数
export function isInteger(rule, value, callback) {
  if (!value) {
    return callback(new Error('输入不可以为空'));
  }
  setTimeout(function () {
    if (!Number(value)) {
      callback(new Error('请输入正整数'));
    } else {
      var re = /^[0-9]*[1-9][0-9]*$/;
      var rsCheck = re.test(value);
      if (!rsCheck) {
        callback(new Error('请输入正整数'));
      } else {
        callback();
      }
    }
  }, 0);
}

export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}

export function validateUserName(rule, value, callback) {
  // const reg = /^(?!_)(?!.*?_$)[A-Za-z0-9_]+$/u // 只允许英文字母、数字、下划线，且下划线不能在首尾
  var reg = /^(?!(?:[\0-\t\x0B\f\x0E-\u2027\u202A-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])*?_$)[0-9A-Z_a-z]+$/; // 只允许英文字母、数字、下划线，且下划线不能在结尾
  if (value === '') {
    callback(new Error("请输入用户名"));
  } else if (value.length < 3 || value.length > 30) {
    callback(new Error("用户名长度必须介于 3 和 30 之间"));
  } else if (!reg.test(value)) {
    callback(new Error('只允许输入英文字母、数字、下划线，且下划线不能在结尾'));
  } else {
    callback();
  }
}

export function validatePass(rule, value, callback) {
  var reg = /^\w+$/;
  if (value === '') {
    callback(new Error("请输入密码"));
  } else if (value.length < 6 || value.length > 20) {
    callback(new Error("用户密码长度必须介于 6 和 20 之间"));
  } else if (!reg.test(value)) {
    callback(new Error('只能是英文字母或数字或下划线组成'));
  } else {
    callback();
  }
}

export function validatePercent(rule, value, callback) {
  if (value === '') {
    callback(new Error('请输入数值'));
  } else if (value > 100) {
    callback(new Error('输入的数值需小于或等于100'));
  } else if (!/^(?:[1-9]\d*|0)$/.test(value)) {
    callback(new Error('请输入正整数或零'));
  } else {
    callback();
  }
}

// 验证金额 (^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)
export var ValidateAmount = function ValidateAmount(rule, value, callback) {
  var required = rule.required;

  if (value === '') {
    if (required) callback('请输入');else callback();
    // ^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$ 不输入0
  } else if (!/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(value)) {
    callback('请输入数字，金额输入不能小于0，最多只可保留2位小数');
  } else {
    callback();
  }
};
// 可输入0，保留2小数
export var ValidateZero = function ValidateZero(rule, value, callback) {
  var required = rule.required;

  if (value === '') {
    if (required) callback('请输入');else callback();
  } else if (!/^\d+(\.\d{0,2})?$/.test(value)) {
    callback('请输入正确的数值（最多只能保留两位小数）');
  } else if (value > 10000000) {
    callback('单次输入不得大于10000000');
  } else {
    callback();
  }
};
// 不可以输入0,两位小数
export function ValidatorMoney(rule, value, callback) {
  var required = rule.required;

  var reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
  if (value === '') {
    if (required) callback('请输入');else callback();
  } else if (!reg.test(value)) {
    callback('请输入正确数值，最多保留两位小数');
  } else {
    callback();
  }
}

// 校验大于0的整数
export function ValidatorInt(rule, value, callback) {
  var required = rule.required;

  var reg = /^[1-9]*[1-9][0-9]*$/;
  if (value === '') {
    if (required) callback('请输入');else callback();
  } else if (!reg.test(value)) {
    callback('请输入大于 0 的整数');
  } else {
    callback();
  }
}
// 校验大于等于0的整数
export function ValidatorIntZero(rule, value, callback) {
  var required = rule.required;

  var reg = /^(0|[1-9][0-9]*)$/;
  if (value === '') {
    if (required) callback('请输入');else callback();
  } else if (!reg.test(value)) {
    callback('请输入大于或等于 0 的整数');
  } else {
    callback();
  }
}