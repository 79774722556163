import { render, staticRenderFns } from "./help.vue?vue&type=template&id=74ef0446&scoped=true&"
import script from "./help.vue?vue&type=script&lang=js&"
export * from "./help.vue?vue&type=script&lang=js&"
import style0 from "./help.vue?vue&type=style&index=0&id=74ef0446&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ef0446",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\zgsf\\UI\\shufa-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('74ef0446', component.options)
    } else {
      api.reload('74ef0446', component.options)
    }
    module.hot.accept("./help.vue?vue&type=template&id=74ef0446&scoped=true&", function () {
      api.rerender('74ef0446', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src\\views\\sys\\help.vue"
export default component.exports