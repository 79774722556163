//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { findNoviceRoad, updateNoviceRoad, findShopDirectory, updateShopDirectory, findPayMethod, updatePayMethod, findPrivacyRule, updatePrivacyRule, findShopTerms, updateShopTerms, findUserAgreementRule, updateUserAgreementRule, getParamsList, setParams } from '@/api/system/setting';
import { validatePercent } from '@/utils/validate';
import EditorTinyMce from '@/components/Editor';

export default {
  name: 'Help',
  components: { EditorTinyMce: EditorTinyMce },
  data: function data() {
    return {
      tabs: [{ tabName: 'noviceRoad', labelName: '新手上路', permission: 'PUT /admin/system/novice/road/update' }, { tabName: 'shopDirectory', labelName: '购物指南', permission: 'PUT /admin/system/shopping/directory/update' }, { tabName: 'shopTerms', labelName: '购物条款', permission: 'PUT /admin/system/shopping/terms/update' }, { tabName: 'payMethod', labelName: '支付方式', permission: 'PUT /admin/system/payment/method/update' }, { tabName: 'userAgreement', labelName: '用户协议', permission: 'PUT /admin/system/user/agreement/update' }, { tabName: 'privacyAgreement', labelName: '隐私协议', permission: 'PUT /admin/system/privacy/agreement/update' }, { tabName: 'systemSet', labelName: '其他设置', permission: '' }],
      tabIndex: 0,
      activeName: 'noviceRoad',
      info: {},
      systemSetForm: {
        paramList: []
      },
      rules: {
        keyContent: [{ required: true, message: '内容不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getNoviceRoad();
  },

  methods: {
    handleTabClick: function handleTabClick(tab, event) {
      var _this = this;

      if (this.tabIndex === tab.index) {
        return;
      }
      this.info = {};
      this.tabIndex = tab.index;
      this.activeName = tab.name;
      switch (this.activeName) {
        case 'noviceRoad':
          this.getNoviceRoad();
          break;
        case 'shopDirectory':
          findShopDirectory().then(function (response) {
            _this.info = response.data.data;
          }).catch(function (e) {
            _this.notifyError(e.data.msg);
          });
          break;
        case 'shopTerms':
          findShopTerms().then(function (response) {
            _this.info = response.data.data;
          }).catch(function (e) {
            _this.notifyError(e.data.msg);
          });
          break;
        case 'payMethod':
          findPayMethod().then(function (response) {
            _this.info = response.data.data;
          }).catch(function (e) {
            _this.notifyError(e.data.msg);
          });
          break;
        case 'userAgreement':
          findUserAgreementRule().then(function (response) {
            _this.info = response.data.data;
          }).catch(function (e) {
            _this.notifyError(e.data.msg);
          });
          break;
        case 'privacyAgreement':
          findPrivacyRule().then(function (response) {
            _this.info = response.data.data;
          }).catch(function (e) {
            _this.notifyError(e.data.msg);
          });
          break;
        case 'systemSet':
          this.getParamList();
          break;
      }
    },
    getNoviceRoad: function getNoviceRoad() {
      var _this2 = this;

      //新手上路
      findNoviceRoad().then(function (response) {
        _this2.info = response.data.data;
      }).catch(function (e) {
        _this2.notifyError(e.data.msg);
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      this.info.keyContent = this.$refs.tinyMce.getContent();
      this.info.keyContentPaths = this.$refs.tinyMce.getUrlPaths();
      this.$refs['myForm'].validate(function (valid) {
        if (valid) {
          switch (_this3.activeName) {
            case 'noviceRoad':
              updateNoviceRoad(_this3.info).then(function (res) {
                _this3.notifySuccess('更新成功');
                _this3.$refs.tinyMce.clearUrlPaths(); //清除Editor中的临时图片路径
              }).catch(function (e) {
                _this3.notifyError(e.data.msg);
              });
              break;
            case 'shopDirectory':
              updateShopDirectory(_this3.info).then(function (res) {
                _this3.notifySuccess('更新成功');
                _this3.$refs.tinyMce.clearUrlPaths(); //清除Editor中的临时图片路径
              }).catch(function (e) {
                _this3.notifyError(e.data.msg);
              });
              break;
            case 'shopTerms':
              updateShopTerms(_this3.info).then(function (res) {
                _this3.notifySuccess('更新成功');
                _this3.$refs.tinyMce.clearUrlPaths(); //清除Editor中的临时图片路径
              }).catch(function (e) {
                _this3.notifyError(e.data.msg);
              });
              break;
            case 'payMethod':
              updatePayMethod(_this3.info).then(function (res) {
                _this3.notifySuccess('更新成功');
                _this3.$refs.tinyMce.clearUrlPaths(); //清除Editor中的临时图片路径
              }).catch(function (e) {
                _this3.notifyError(e.data.msg);
              });
              break;
            case 'userAgreement':
              updateUserAgreementRule(_this3.info).then(function (res) {
                _this3.notifySuccess('更新成功');
                _this3.$refs.tinyMce.clearUrlPaths(); //清除Editor中的临时图片路径
              }).catch(function (e) {
                _this3.notifyError(e.data.msg);
              });
              break;
            case 'privacyAgreement':
              updatePrivacyRule(_this3.info).then(function (res) {
                _this3.notifySuccess('更新成功');
                _this3.$refs.tinyMce.clearUrlPaths(); //清除Editor中的临时图片路径
              }).catch(function (e) {
                _this3.notifyError(e.data.msg);
              });
              break;
          }
        }
      });
    },
    getParamList: function getParamList() {
      var _this4 = this;

      getParamsList().then(function (res) {
        _this4.systemSetForm.paramList = res.data.data;
        for (var i = 0; i < _this4.systemSetForm.paramList.length; i++) {
          var rule = [{ required: true, message: '请输入数值', trigger: 'blur' }, { validator: validatePercent, trigger: ['blur', 'change'] }];
          _this4.systemSetForm.paramList[i].rules = rule;
        }
      }).catch(function (e) {
        _this4.notifyError(e.data.msg);
      });
    },
    handleSettings: function handleSettings(item) {
      var _this5 = this;

      this.$refs['systemSetForm'].validate(function (valid) {
        if (valid) {
          setParams(item).then(function (res) {
            _this5.notifySuccess('更新成功');
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    }
  }
};