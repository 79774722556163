import request from '@/utils/request';

// 用户密码修改
export function changePassword(oldPassword, newPassword) {
  var PasswordBody = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  return request({
    url: '/profile/password',
    method: 'put',
    data: PasswordBody
  });
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/profile/modify',
    method: 'put',
    data: data
  });
}