import _JSON$stringify from 'babel-runtime/core-js/json/stringify';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getPaperWorksOrderDetail, orderCancel, orderDel, orderConfirmReceipt } from '@/api/order.js';

import { unitPrice } from '@/utils/filters.js';
import djs from '@/components/order/djs.vue';
import CommonDialog from '@/components/common/common_dialog.vue';
import OrderPaperApplyReturn from '@/components/my/order_paper_apply_return.vue';
import OrderPaperReturnLogistics from '@/components/my/order_paper_return_logistics.vue';

export default {
  name: 'OrderPaperDetail',
  components: {
    djs: djs,
    CommonDialog: CommonDialog,
    OrderPaperApplyReturn: OrderPaperApplyReturn,
    OrderPaperReturnLogistics: OrderPaperReturnLogistics
  },
  data: function data() {
    return {
      isShowApplyReturn: true,
      isShowReturnLogistics: true,
      orderSn: '',
      orderInfo: {},
      dContent: '',
      dType: 1
    };
  },

  filters: {
    parseMoney: function parseMoney(val) {
      return unitPrice(val, '￥');
    },
    getOrderStatus: function getOrderStatus(status) {
      // 101 订单生成，未支付；102，下单未支付用户取消，订单关闭；103，下单未支付超期，系统自动关闭订单；201 支付完成，商家未发货；301 商家发货，用户未确认；401 用户确认收货；402 系统自动确认收货
      switch (status) {
        case 101:
          return '待支付';
        case 102:
        case 103:
          return '已取消';
        case 201:
          return '待发货';
        case 301:
          return '待收货';
        case 401:
        case 402:
          return '已完成';
        case 500:
          return '退货申请待审核';
        case 501:
          return '退货申请驳回';
        case 502:
          return '退货申请通过，待退货';
        case 503:
          return '用户退货中';
        case 504:
          return '用户退货完成';
      }
    }
  },
  computed: {},
  created: function created() {
    if (this.$route.query.orderSn) {
      this.orderSn = this.$route.query.orderSn;
      this.getDetail();
    }
  },

  methods: {
    getDetail: function getDetail() {
      var _this = this;

      getPaperWorksOrderDetail(this.orderSn).then(function (res) {
        _this.orderInfo = res.data;
      }).catch(function (err) {
        _this.$msgError(err.msg);
      });
    },
    handlePay: function handlePay() {
      this.$router.replace({
        path: '/web/works/works_order_pay',
        query: {
          orderNum: this.orderSn,
          reqInfo: this.$Base64.encode(_JSON$stringify({ 'payMoney': this.orderInfo.actualPrice }))
        }
      });
    },
    handleCancelOrder: function handleCancelOrder() {
      this.dContent = '确定要取消该订单吗？';
      this.dType = 1;
      this.$refs.dialog.showPop();
    },
    handleConfirmReceipt: function handleConfirmReceipt() {
      this.dContent = '确定已收货吗？';
      this.dType = 2;
      this.$refs.dialog.showPop();
    },
    handleDelOrder: function handleDelOrder() {
      this.dContent = '确定要删除该订单吗？';
      this.dType = 3;
      this.$refs.dialog.showPop();
    },
    submitDialogCallback: function submitDialogCallback() {
      var _this2 = this;

      if (this.dType === 1) {
        orderCancel(this.orderSn).then(function (res) {
          _this2.$msgSuccess("订单取消成功");
          _this2.getDetail();
        }).catch(function (err) {
          _this2.$msgError(err.msg);
        });
      } else if (this.dType === 2) {
        orderConfirmReceipt(this.orderSn).then(function (res) {
          _this2.$msgSuccess("确认收货成功");
          _this2.getDetail();
        }).catch(function (err) {
          _this2.$msgError(err.msg);
        });
      } else if (this.dType === 3) {
        orderDel(this.orderSn).then(function (res) {
          _this2.$msgSuccess("订单删除成功");
          _this2.$router.go(-1);
        }).catch(function (err) {
          _this2.$msgError(err.msg);
        });
      }
    },
    handleApplyReturn: function handleApplyReturn() {
      // 申请退货
      this.isShowApplyReturn = !this.isShowApplyReturn;
    },
    goToWorksDetail: function goToWorksDetail(manuscriptId) {
      this.$router.push({ path: '/web/works/works_detail', query: { id: manuscriptId } });
    },
    djsCallback: function djsCallback() {
      //倒计时结束回调
      this.orderInfo.orderStatus = 103;
    },
    handleLogisticsInfor: function handleLogisticsInfor() {
      // 退货物流信息填写
      this.isShowReturnLogistics = !this.isShowReturnLogistics;
    }
  }
};