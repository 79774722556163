//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listOrderEbookDetail } from '@/api/order/order';

var statusMap = { //101 订单生成，未支付；102，下单未支付用户取消，订单关闭；103，下单未支付超期，系统自动关闭订单；201 支付完成
  101: '未支付',
  102: '用户取消',
  103: '系统取消',
  201: '已支付'
};
var payMethodMap = { //（0 余额 1 微信 2 支付宝）
  0: '余额',
  1: '微信',
  2: '支付宝'
};

export default {
  name: 'OrderEbookDetail',
  data: function data() {
    return {
      baseUrl: process.env.BASE_API,
      orderId: 0,
      orderInfo: {}
    };
  },

  filters: {
    orderStatusFilter: function orderStatusFilter(status) {
      return statusMap[status];
    },
    payMethodFilter: function payMethodFilter(payMethod) {
      return payMethodMap[payMethod];
    }
  },
  created: function created() {
    if (this.$route.query.id == null) {
      return;
    }
    this.orderId = this.$route.query.id;
    this.init();
  },

  methods: {
    init: function init() {
      var _this = this;

      listOrderEbookDetail(this.orderId).then(function (response) {
        _this.orderInfo = response.data.data;
      }).catch(function (response) {
        _this.notifyError(response.data.msg);
      });
    }
  }
};