//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import store from '@/store';
import { VueCropper } from 'vue-cropper';
import { uploadFile } from '@/api/storage';
import { updateUserProfile } from "@/api/admin/profile";

export default {
  components: {
    VueCropper: VueCropper
  },
  props: {
    user: {
      type: Object
    }
  },
  data: function data() {
    return {
      open: false, // 是否显示弹出层
      visible: false, // 是否显示cropper
      title: "修改头像", // 弹出层标题
      options: {
        img: store.getters.avatar, //裁剪图片的地址
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true // 固定截图框大小 不允许改变
      },
      previews: {},
      tmpImgName: 'avatar.png' // 选择图片的文件名称
    };
  },

  methods: {
    editCropper: function editCropper() {
      // 编辑头像
      this.open = true;
    },
    modalOpened: function modalOpened() {
      // 打开弹出层结束时的回调
      this.visible = true;
    },
    requestUpload: function requestUpload() {// 覆盖默认的上传行为
    },
    rotateLeft: function rotateLeft() {
      // 向左旋转
      this.$refs.cropper.rotateLeft();
    },
    rotateRight: function rotateRight() {
      // 向右旋转
      this.$refs.cropper.rotateRight();
    },
    changeScale: function changeScale(num) {
      // 图片缩放
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    beforeUpload: function beforeUpload(file) {
      var _this = this;

      // 上传预处理
      if (file.type.indexOf("image/") == -1) {
        this.notifyError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        var reader = new FileReader();
        this.tmpImgName = file.name;
        reader.readAsDataURL(file);
        reader.onload = function () {
          _this.options.img = reader.result;
        };
      }
    },
    uploadImg: function uploadImg() {
      var _this2 = this;

      // 上传图片
      this.$refs.cropper.getCropData(function (data) {
        var file = _this2.dataURLtoFile(data, _this2.tmpImgName);
        var formData = new FormData();
        formData.append('file', file);
        uploadFile(formData).then(function (res) {
          _this2.open = false;
          _this2.options.img = res.data.data.url;
          store.commit('SET_AVATAR', _this2.options.img);
          _this2.visible = false;

          _this2.user.avatar = _this2.options.img;
          updateUserProfile(_this2.user).then(function (res) {
            store.commit('SET_USER', _this2.user);
            _this2.notifySuccess('修改成功');
          }).catch(function (res) {
            _this2.notifyError(res.data.msg);
          });
        }).catch(function (res) {
          _this2.notifyError(res.data.msg);
        });
      });
    },
    dataURLtoFile: function dataURLtoFile(dataurl, filename) {
      // 将base64转换为文件
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    realTime: function realTime(data) {
      // 实时预览
      this.previews = data;
    },
    closeDialog: function closeDialog() {
      // 关闭窗口
      this.options.img = store.getters.avatar;
      this.visible = false;
    }
  }
};