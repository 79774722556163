import request from '@/utils/request';
import Qs from 'qs';

export function listNotice(params) {
  return request({
    url: '/notice/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createNotice(data) {
  return request({
    url: '/notice/create',
    method: 'post',
    data: data
  });
}

export function readNotice(id) {
  return request({
    url: '/notice/read/' + id,
    method: 'get'
  });
}

export function updateNotice(data) {
  return request({
    url: '/notice/update',
    method: 'put',
    data: data
  });
}

export function deleteNotice(id) {
  return request({
    url: '/notice/delete/' + id,
    method: 'delete'
  });
}

/**
 * 禁用
 */
export function disablingNotice(id) {
  return request({
    url: '/notice/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingNotice(id) {
  return request({
    url: '/notice/resuming/' + id,
    method: 'PUT'
  });
}