import request from '@/utils/request';
import Qs from 'qs';

export function listPrinting(params) {
  return request({
    url: '/printing/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 印刷厂会员详情查询
 * @param {*} userId 
 * @returns 
 */
export function printingDetail(userId) {
  return request({
    url: '/printing/read/' + userId,
    method: 'get'
  });
}

/**
 * 审核印刷厂会员
 * @param {*} userId 
 * @returns 
 */
export function printingApprove(userId, data) {
  return request({
    url: '/printing/approve/' + userId,
    method: 'put',
    data: data
  });
}

/**
 * 列表查询印单信息
 * @param {*} params 
 * @returns 
 */
export function listPrintingOrder(params) {
  return request({
    url: '/press/task/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 印单信息详情查询
 * @param {*} params 
 * @returns 
 */
export function printingOrderDetail(cbillid) {
  return request({
    url: '/press/task/read/' + cbillid,
    method: 'get'
  });
}

/**
 * 竞标印刷厂列表查询
 * @param {*} params 
 * @returns 
 */
export function listTaskPrinting(params) {
  return request({
    url: '/press/task/printing/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 确认提交指定印刷厂的报价
 * @param {*} params 
 * @returns 
 */
export function printingOffer(cbillid, userId) {
  return request({
    url: '/press/task/confirm/' + cbillid + '/' + userId,
    method: 'put'
  });
}

/**
 * 人工印单主表添加
 * @param {*} data 
 * @returns 
 */
export function printingCreate(data) {
  return request({
    url: '/press/task/create',
    method: 'post',
    data: data
  });
}

/**
 * 人工印单主表修改
 * @param {*} data 
 * @returns 
 */
export function printingUpdate(data) {
  return request({
    url: '/press/task/update',
    method: 'put',
    data: data
  });
}

/**
 * 人工印单材料子表添加
 * @param {*} data 
 * @returns 
 */
export function printingSubCreate(data) {
  return request({
    url: '/press/task/sub/create',
    method: 'post',
    data: data
  });
}

/**
 * 人工印单材料子表修改
 * @param {*} data 
 * @returns 
 */
export function printingSubUpdate(data) {
  return request({
    url: '/press/task/sub/update',
    method: 'put',
    data: data
  });
}