//
//
//
//
//
//
//

import { isExternal as _isExternal } from '@/utils/validate';

export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExternal: function isExternal() {
      return _isExternal(this.iconClass);
    },
    iconName: function iconName() {
      return '#icon-' + this.iconClass;
    },
    svgClass: function svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    },
    styleExternalIcon: function styleExternalIcon() {
      return {
        mask: 'url(' + this.iconClass + ') no-repeat 50% 50%',
        '-webkit-mask': 'url(' + this.iconClass + ') no-repeat 50% 50%'
      };
    }
  }
};