//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pickerOptions } from '@/utils/index';
import { listLog } from '@/api/authority/log';

export default {
  name: 'Log',
  data: function data() {
    return {
      showSearch: true,
      list: [],
      total: 0,
      listLoading: true,
      searchTime: [],
      listQuery: {
        page: 1,
        size: 20,
        adminId: undefined,
        methodDesc: undefined,
        dateRange: undefined
      },
      pickerOptions: {
        shortcuts: []
      }
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    // this.getDefaultSearchDate()
    this.getList();
  },

  methods: {
    getDefaultSearchDate: function getDefaultSearchDate() {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
      this.searchTime = [];
      this.searchTime.push(this.parseTime(start, '{y}-{m}-{d}'));
      this.searchTime.push(this.parseTime(end, '{y}-{m}-{d}'));
    },
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      this.listLoading = true;
      listLog(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.searchTime = [];
      this.handleFilter();
    }
  }
};