import request from '@/utils/request';
import Qs from 'qs';

export function listRecharge(params) {
  return request({
    url: '/recharge/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createRecharge(data) {
  return request({
    url: '/recharge/create',
    method: 'post',
    data: data
  });
}

export function readRecharge(id) {
  return request({
    url: '/recharge/read/' + id,
    method: 'get'
  });
}

export function updateRecharge(data) {
  return request({
    url: '/recharge/update',
    method: 'put',
    data: data
  });
}

export function deleteRecharge(id) {
  return request({
    url: '/recharge/delete/' + id,
    method: 'delete'
  });
}

/**
 * 禁用
 */
export function disablingRecharge(id) {
  return request({
    url: '/recharge/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingRecharge(id) {
  return request({
    url: '/recharge/resuming/' + id,
    method: 'PUT'
  });
}