import request from '@/utils/request';
import Qs from 'qs';

export function listStoryclass(params) {
  return request({
    url: '/storyclass/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createStoryclass(data) {
  return request({
    url: '/storyclass/create',
    method: 'post',
    data: data
  });
}

export function readStoryclass(id) {
  return request({
    url: '/storyclass/read/' + id,
    method: 'get'
  });
}

export function updateStoryclass(data) {
  return request({
    url: '/storyclass/update',
    method: 'put',
    data: data
  });
}

export function deleteStoryclass(id) {
  return request({
    url: '/storyclass/delete/' + id,
    method: 'delete'
  });
}

/** 稿件分类树形列表 */
export function getStoryclassTree(data) {
  return request({
    url: '/storyclass/getBaseStoryclassTrre',
    method: 'post',
    data: data
  });
}