import _Promise from 'babel-runtime/core-js/promise';
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

// create an axios instance
var service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (store.getters.token) {
    // 让每个请求携带token-- ['AdminAuthorizationToken']为自定义key 请根据实际情况自行修改
    config.headers['AdminAuthorizationToken'] = getToken();
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  _Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  // console.log('结果返回：' + JSON.stringify(response))
  var res = response.data;
  var code = res.code || 200;
  if (code === 403) {
    MessageBox.alert('系统未登录，请重新登录', '错误', {
      confirmButtonText: '确定',
      type: 'error'
    }).then(function () {
      store.dispatch('FedLogOut').then(function () {
        location.reload();
      });
    });
    return _Promise.reject('error');
  } else if (code !== 200) {
    // 其他属于业务错误，留给具体页面处理
    return _Promise.reject(response);
  } else {
    return response;
  }
}, function (error) {
  console.log('err' + error); // for debug
  Message({
    message: '登录连接超时（后台不能连接，请联系系统管理员）',
    type: 'error',
    duration: 5 * 1000
  });
  return _Promise.reject(error);
});

export default service;