import _Object$assign from 'babel-runtime/core-js/object/assign';
import _getIterator from 'babel-runtime/core-js/get-iterator';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 稿件分类
 */
import { listStoryclass, createStoryclass, updateStoryclass, deleteStoryclass, readStoryclass, getStoryclassTree } from '@/api/manuscript/storyclass';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

var statusMap = {
  0: '启用',
  1: '禁用'
};

export default {
  name: 'Category',
  components: { Treeselect: Treeselect },
  data: function data() {
    return {
      statusMap: statusMap,
      loading: true,
      showSearch: true,
      categoryList: [],
      categoryOptions: [],
      title: '',
      open: false,
      isExpandAll: true, // 是否展开，默认全部展开
      refreshTable: true, // 重新渲染表格状态
      listQuery: {
        name: undefined,
        deleted: undefined
      },
      form: {},
      rules: {
        tid: [{ required: true, message: '上级分类不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '分类名称不能为空', trigger: 'blur' }],
        no: [{ required: true, message: '显示排序不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.loading = true;
      listStoryclass(this.listQuery).then(function (response) {
        _this.categoryList = _this.handleTree(response.data.data.list, 'id', 'tid');
        _this.loading = false;
      });
    },

    /** 转换分类数据结构 */
    normalizer: function normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.name,
        children: node.children
      };
    },

    // 取消按钮
    cancel: function cancel() {
      this.open = false;
      this.reset();
    },

    // 表单重置
    reset: function reset() {
      this.form = {
        id: undefined,
        tid: undefined,
        name: undefined,
        level: undefined,
        no: undefined,
        desc: undefined
      };
      this.resetForm('form');
    },

    /** 搜索按钮操作 */
    handleQuery: function handleQuery() {
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },

    /** 新增按钮操作 */
    handleCreate: function handleCreate(row) {
      var _this2 = this;

      this.reset();
      if (row != undefined) {
        this.title = '添加分类';
        this.form.tid = row.id;
        getStoryclassTree().then(function (response) {
          _this2.categoryOptions = response.data.data;
        });
      } else {
        // 添加一级分类
        this.title = '添加一级分类';
        this.form.tid = -1;
      }
      this.open = true;
    },

    /** 展开/折叠操作 */
    toggleExpandAll: function toggleExpandAll() {
      var _this3 = this;

      this.refreshTable = false;
      this.isExpandAll = !this.isExpandAll;
      this.$nextTick(function () {
        _this3.refreshTable = true;
      });
    },
    handleSelect: function handleSelect(node) {
      // console.log(node)
    },
    findCategoryByTid: function findCategoryByTid(categoryOptions, tid) {
      var found = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = _getIterator(categoryOptions), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var category = _step.value;

          if (category.id == tid) {
            return category; // 如果找到匹配的tid，直接返回该项
          }

          if (category.children && category.children.length > 0) {
            var childFound = this.findCategoryByTid(category.children, tid, found);
            if (childFound) {
              return childFound; // 如果在子分类中找到匹配的tid，返回该项
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return found; // 如果在所有分类中都没有找到匹配的tid，返回null或其他默认值
    },

    /** 修改按钮操作 */
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.reset();
      this.title = '修改分类';
      this.open = true;
      if (row.tid > 0) {
        getStoryclassTree().then(function (response) {
          _this4.categoryOptions = response.data.data;
        });
      }

      readStoryclass(row.id).then(function (response) {
        _this4.form = _Object$assign({}, response.data.data);
      });
    },

    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this5 = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this5.form.id != undefined) {
            delete _this5.form['children']; // 删除属性
            updateStoryclass(_this5.form).then(function (response) {
              _this5.notifySuccess('修改成功');
              _this5.open = false;
              _this5.getList();
            }).catch(function (response) {
              _this5.notifyError(response.data.msg);
            });
          } else {
            var parentData = _this5.findCategoryByTid(_this5.categoryOptions, _this5.form.tid);
            if (parentData != null) {
              _this5.form.type = parentData.type;
              createStoryclass(_this5.form).then(function (response) {
                _this5.notifySuccess('新增成功');
                _this5.open = false;
                _this5.getList();
              }).catch(function (response) {
                _this5.notifyError(response.data.msg);
              });
            } else {
              _this5.notifyError('数据错误');
            }
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete: function handleDelete(row) {
      var _this6 = this;

      console.log(row);
      this.$confirm('是否确认删除名称为"' + row.name + '"的数据项？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteStoryclass(row.id).then(function (response) {
          _this6.notifySuccess('删除成功');
          _this6.getList();
        }).catch(function (response) {
          _this6.notifyError(response.data.msg);
        });
      }).catch(function () {
        // 取消操作
      });
    }
  }
};