//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'v-charts/lib/style.css';
import VeHistogram from 'v-charts/lib/histogram';
import { pickerOptions } from '@/utils/index';
import { userStat } from '@/api/stat/stat';

export default {
  name: 'StatUsers',
  components: {
    VeHistogram: VeHistogram
  },
  data: function data() {
    return {
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      searchTime: [],
      pickerOptions: {
        shortcuts: []
      },
      listQuery: {
        page: 1,
        size: 20,
        type: 3,
        dateRange: undefined
      },
      chartQuery: {
        page: 0,
        size: 0,
        type: 3,
        dateRange: undefined
      },
      typeOptions: [{ key: 5, display_name: '按年' }, { key: 4, display_name: '按季' }, { key: 3, display_name: '按月' }, { key: 2, display_name: '按周' }, { key: 1, display_name: '按日' }],
      dialogEchartVisible: false,
      chartLoading: false,
      chartData: {},
      chartSettings: {},
      chartExtend: {}
    };
  },
  created: function created() {
    this.pickerOptions.shortcuts = pickerOptions;
    this.getDefaultSearchDate();
    this.getList();
  },

  methods: {
    getDefaultSearchDate: function getDefaultSearchDate() {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
      this.searchTime = [];
      this.searchTime.push(this.parseTime(start, '{y}-{m}-{d}'));
      this.searchTime.push(this.parseTime(end, '{y}-{m}-{d}'));
    },
    getList: function getList() {
      var _this = this;

      this.listQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQuery.dateRange = [];
        this.listQuery.dateRange.push(this.searchTime[0]);
        this.listQuery.dateRange.push(this.searchTime[1]);
      }
      if (this.listQuery.dateRange === undefined || this.listQuery.dateRange === '') {
        return this.notifyError('请选择注册时间范围');
      }

      this.listLoading = true;
      userStat(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function (err) {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
        _this.notifyError(err.data.msg);
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetQuery: function resetQuery() {
      this.listQuery = {
        page: 1,
        size: 20,
        type: 3,
        dateRange: undefined
      };
      this.getDefaultSearchDate();
      this.getList();
    },
    openEchart: function openEchart() {
      var _this2 = this;

      if (this.listQuery.dateRange !== undefined && this.listQuery.dateRange instanceof Array && this.listQuery.dateRange.length > 1 && this.chartQuery.dateRange !== undefined && this.chartQuery.dateRange instanceof Array && this.chartQuery.dateRange.length > 1 && this.listQuery.dateRange[0] === this.chartQuery.dateRange[0] && this.listQuery.dateRange[1] === this.chartQuery.dateRange[1] && this.listQuery.type === this.chartQuery.type) {
        this.dialogEchartVisible = true;
        return;
      }
      this.chartQuery.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.chartQuery.dateRange = [];
        this.chartQuery.dateRange.push(this.searchTime[0]);
        this.chartQuery.dateRange.push(this.searchTime[1]);
      }
      if (this.chartQuery.dateRange === undefined || this.chartQuery.dateRange === '') {
        return this.notifyError('请选择注册时间范围');
      }
      this.chartQuery.type = this.listQuery.type;

      this.dialogEchartVisible = true;
      this.chartLoading = true;
      userStat(this.chartQuery).then(function (response) {
        _this2.chartData = {
          columns: ['dateStr', 'counts'],
          rows: response.data.data.list
        };
        _this2.chartSettings = {
          labelMap: {
            'counts': '会员增长数'
          }
        };
        _this2.chartExtend = {
          xAxis: {
            boundaryGap: true,
            axisLabel: {
              rotate: 45 //x轴的文字倾斜(范围：-90~90)
            }
          },
          yAxis: {
            axisLine: { show: true },
            position: "left"
          },
          series: {
            label: {
              show: true,
              position: 'top'
            }
          }
        };
        _this2.chartLoading = false;
      }).catch(function (err) {
        _this2.chartData = {};
        _this2.chartSettings = {};
        _this2.chartExtend = {};
        _this2.chartLoading = false;
        _this2.notifyError(err.data.msg);
      });
    }
  }
};