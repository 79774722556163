import request from '@/utils/request';

// 上传文件
export function uploadFile(data) {
  return request({
    url: '/storage/upload',
    method: 'POST',
    data: data
  });
}

//上传文件
var uploadPath = process.env.BASE_API + '/storage/upload';

//多附件合并后回传稿件
var uploadWorksBackPath = process.env.BASE_API + '/storage/manuscript/upload/back';

//后台上传稿件
var uploadWorksPath = process.env.BASE_API + '/storage/manuscript/upload';

export { uploadPath, uploadWorksPath, uploadWorksBackPath };