import _getIterator from 'babel-runtime/core-js/get-iterator';
import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listManuscriptTag, createManuscriptTag, deleteManuscriptTag, updateManuscriptTag, disablingManuscriptTag, resumingManuscriptTag } from '@/api/manuscript/tag';

var textMap = {
  update: '编辑',
  create: '创建'
};

export default {
  name: 'ManuscriptTag',
  data: function data() {
    return {
      showSearch: true,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        name: undefined
      },
      downloadLoading: false,
      dataForm: {
        id: undefined,
        name: undefined,
        desc: undefined
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: textMap,
      rules: {
        name: [{ required: true, message: '标签名称不能为空', trigger: 'blur' }]
      }
    };
  },
  created: function created() {
    this.getList();
  },

  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      listManuscriptTag(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.list = [];
        _this.total = 0;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    },
    reset: function reset() {
      this.dataForm = {
        id: undefined,
        name: undefined,
        desc: undefined,
        enabled: true
      };
      this.resetForm("dataForm");
    },
    handleCreate: function handleCreate() {
      var _this2 = this;

      this.reset();
      this.dialogStatus = 'create';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this2.$refs['dataForm'].clearValidate();
      });
    },
    createData: function createData() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          createManuscriptTag(_this3.dataForm).then(function (response) {
            _this3.getList();
            _this3.dialogFormVisible = false;
            _this3.notifySuccess('添加成功');
          }).catch(function (response) {
            _this3.notifyError(response.data.msg);
          });
        }
      });
    },
    handleUpdate: function handleUpdate(row) {
      var _this4 = this;

      this.dataForm = _Object$assign({}, row);
      this.dialogStatus = 'update';
      this.dialogFormVisible = true;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].clearValidate();
      });
    },
    updateData: function updateData() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          updateManuscriptTag(_this5.dataForm).then(function () {
            _this5.getList();
            _this5.dialogFormVisible = false;
            _this5.notifySuccess('更新成功');
          }).catch(function (response) {
            _this5.notifyError(response.data.msg);
          });
        }
      });
    },
    handleStatusChange: function handleStatusChange(row) {
      var _this6 = this;

      var text = row.deleted === true ? '禁用' : '启用';
      this.$confirm('确认要"' + text + '""' + row.name + '"标签吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.changeStatus(row, text);
      }).catch(function () {
        // 取消操作
        row.deleted = !row.deleted;
      });
    },
    changeStatus: function changeStatus(row, text) {
      var _this7 = this;

      if (row.deleted === false) {
        // 停用改成启用
        resumingManuscriptTag(row.id).then(function (response) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = _getIterator(_this7.list), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var v = _step.value;

              if (v.id === row.id) {
                var index = _this7.list.indexOf(v);
                _this7.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          _this7.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this7.notifyError(response.data.msg);
        });
      } else {
        // 启用改成停用
        disablingManuscriptTag(row.id).then(function (response) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = _getIterator(_this7.list), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var v = _step2.value;

              if (v.id === row.id) {
                var index = _this7.list.indexOf(v);
                _this7.list.splice(index, 1, row);
                break;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          _this7.notifySuccess(text + '成功');
        }).catch(function (response) {
          row.deleted = !row.deleted;
          _this7.notifyError(response.data.msg);
        });
      }
    },
    handleDelete: function handleDelete(row) {
      var _this8 = this;

      var that = this;
      this.$confirm('是否确认删除名称为"' + row.name + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        // 确定操作
        deleteManuscriptTag(row.id).then(function (response) {
          that.notifySuccess('删除成功');
          var index = _this8.list.indexOf(row);
          _this8.list.splice(index, 1);
        }).catch(function (response) {
          that.notifyError(response.data.msg);
        });
      }).catch(function () {// 取消操作

      });
    }
  }
};