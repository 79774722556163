import request from '@/utils/request';

export function listRole(query) {
  return request({
    url: '/role/list',
    method: 'get',
    params: query
  });
}

export function createRole(data) {
  return request({
    url: '/role/create',
    method: 'post',
    data: data
  });
}

export function updateRole(data) {
  return request({
    url: '/role/update',
    method: 'put',
    data: data
  });
}

export function deleteRole(id) {
  return request({
    url: '/role/delete/' + id,
    method: 'delete'
  });
}

export function detailRole(id) {
  return request({
    url: '/role/read/' + id,
    method: 'get'
  });
}

export function getPermission(id) {
  return request({
    url: '/role/permissions/' + id,
    method: 'get'
  });
}

export function updatePermission(data) {
  return request({
    url: '/role/updatePermissions',
    method: 'put',
    data: data
  });
}

export function roleOptions(query) {
  return request({
    url: '/role/options',
    method: 'get',
    params: query
  });
}

// 角色功能授权列表
export function roleMenuList(roleId) {
  return request({
    url: '/role/menu/' + roleId,
    method: 'get'
  });
}

// 角色功能权限更新
export function updPermissionV2(data) {
  return request({
    url: '/role/updatePermissionsV2',
    method: 'put',
    data: data
  });
}

// 角色数据授权列表
export function roleDataPermList(roleId) {
  return request({
    url: '/role/datapermission/' + roleId,
    method: 'get'
  });
}

// 角色数据权限更新
export function updDataPerm(data) {
  return request({
    url: '/role/dataPermission',
    method: 'put',
    data: data
  });
}