import request from '@/utils/request';
import Qs from 'qs';

/**
 * 提现列表查询
 * @param {*} params 
 * @returns 
 */
export function listWithdrawal(params) {
  return request({
    url: '/account/trace/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

/**
 * 提现审核
 * @param {*} data 
 * @returns 
 */
export function approveWithdrawal(id, data) {
  return request({
    url: '/account/trace/approve/' + id,
    method: 'PUT',
    data: data
  });
}

/**
 * 确认支付完成
 * @param {*} id 
 * @returns 
 */
export function payWithdrawalConfirm(id) {
  return request({
    url: '/account/trace/pay/mark/' + id,
    method: 'PUT'
  });
}