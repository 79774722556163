import _extends from "babel-runtime/helpers/extends";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getToken } from "@/utils/auth";

import FileDownload from "@/components/FileDownload";

export default {
  name: "FileUpload",
  components: { FileDownload: FileDownload },
  props: {
    // 值
    value: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      default: 5
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: function _default() {
        return ["doc", "docx", "pdf", "png", "jpg"];
      }
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    uploadUrl: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: {}
    },
    showConfirmDialog: { //选择文件是否需要弹出确认框
      type: Boolean,
      default: false
    },
    showMsg: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      number: 0,
      uploadList: [],
      baseUrl: process.env.BASE_API,
      headers: {
        'AdminAuthorizationToken': getToken()
      },
      fileList: [],
      otherData: { //上传时附带的额外参数
        'fileName': ''
      },
      downloadVisible: false
    };
  },

  watch: {
    value: {
      handler: function handler(val) {
        if (val) {
          var temp = 1;
          // 首先将值转为数组
          var list = Array.isArray(val) ? val : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(function (item) {
            if (typeof item === "string") {
              item = { name: item, url: item };
            }
            item.uid = item.uid || new Date().getTime() + temp++;
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },

      deep: true,
      immediate: true
    },
    params: {
      handler: function handler(val) {
        if (val) {
          this.otherData = _extends({}, this.otherData, val);
        }
      },

      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip: function showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    }
  },
  methods: {
    uploadFile: function uploadFile() {
      var _this = this;

      this.$confirm(this.showMsg, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.$refs['upload'].$refs['upload-inner'].handleClick();
      }).catch(function () {});
    },

    // 上传前校检格式和大小
    handleBeforeUpload: function handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        var fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        var isTypeOk = this.fileType.some(function (type) {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$modal.msgError("\u6587\u4EF6\u683C\u5F0F\u4E0D\u6B63\u786E, \u8BF7\u4E0A\u4F20" + this.fileType.join("/") + "\u683C\u5F0F\u6587\u4EF6!");
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        var isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError("\u4E0A\u4F20\u6587\u4EF6\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7 " + this.fileSize + " MB!");
          return false;
        }
      }
      //this.otherData.fileName = '1.jpg'
      this.$modal.loading("正在上传文件，请稍候...");
      this.number++;
      return true;
    },

    // 文件个数超出
    handleExceed: function handleExceed() {
      this.$modal.msgError("\u4E0A\u4F20\u6587\u4EF6\u6570\u91CF\u4E0D\u80FD\u8D85\u8FC7 " + this.limit + " \u4E2A!");
    },

    // 上传失败
    handleUploadError: function handleUploadError(err) {
      this.$modal.msgError("上传文件失败，请重试");
      this.$modal.closeLoading();
    },

    // 上传成功回调
    handleUploadSuccess: function handleUploadSuccess(res) {
      if (res.code == 200) {
        this.uploadList.push({ name: res.data.url, url: res.data.url });
        if (this.uploadList.length === this.number) {
          this.fileList = this.fileList.concat(this.uploadList);
          this.uploadList = [];
          this.number = 0;
          this.$emit("input", this.listToString(this.fileList));
          this.$modal.closeLoading();
        }
      } else {
        this.$modal.msgError(res.msg);
        this.$modal.closeLoading();
      }
    },

    // 删除文件
    handleDelete: function handleDelete(index) {
      this.fileList.splice(index, 1);
      this.$emit("input", this.listToString(this.fileList));
    },

    // 获取文件名称
    getFileName: function getFileName(name) {
      if (name.lastIndexOf("_") > -1) {
        return name.slice(name.lastIndexOf("_") + 1).replace('.old', '');
      } else {
        return "";
      }
    },

    // 对象转成指定字符串分隔
    listToString: function listToString(list, separator) {
      var strs = "";
      separator = separator || ",";
      for (var i in list) {
        strs += list[i].url + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    },
    downloadFileItem: function downloadFileItem(fileUrl) {
      this.downloadVisible = true;
      this.$refs.fileDownload.downloadXhr(fileUrl);
    }
  }
};