var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "show-close": false,
            "close-on-click-modal": false,
            "destroy-on-close": true,
            "close-on-press-escape": false,
            visible: _vm.pdfEditVisible,
            fullscreen: "",
            top: "0vh"
          },
          on: {
            "update:visible": function($event) {
              _vm.pdfEditVisible = $event
            }
          }
        },
        [
          _c("div", {}, [
            _c("iframe", {
              ref: "iframe",
              staticClass: "myIframe",
              attrs: { src: _vm.html, scrolling: "no", frameborder: "0" }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }