var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.mailId ? "编辑信息" : "发布信息"))]),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "mailForm",
              staticStyle: { "margin-right": "150px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.mailForm,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "title" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.mailForm.title,
                      callback: function($$v) {
                        _vm.$set(_vm.mailForm, "title", $$v)
                      },
                      expression: "mailForm.title"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "会员范围", prop: "rangeType" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.mailForm.rangeType,
                        callback: function($$v) {
                          _vm.$set(_vm.mailForm, "rangeType", $$v)
                        },
                        expression: "mailForm.rangeType"
                      }
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("全部会员")
                      ]),
                      _vm._v(" "),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("指定会员")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mailForm.rangeType === 1,
                          expression: "mailForm.rangeType === 1"
                        }
                      ],
                      staticClass: "text-danger"
                    },
                    [
                      _vm._v(
                        "\n          会员接收范围为“全部会员”，则创建提交直接发送给所有会员\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "内容", prop: "content" } },
                [
                  _c("editor-tiny-mce", {
                    ref: "tinyMce",
                    attrs: {
                      config: { width: 0, height: 280 },
                      value: _vm.mailForm.content
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "op-container",
          staticStyle: { "margin-right": "150px" }
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取消")
          ]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handlePublish } },
            [_vm._v(_vm._s(_vm.mailId ? "更新" : "发布"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }