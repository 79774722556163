var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "80px" }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "用户名", prop: "username" } },
        [
          _c("el-input", {
            model: {
              value: _vm.user.username,
              callback: function($$v) {
                _vm.$set(_vm.user, "username", $$v)
              },
              expression: "user.username"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "手机号码", prop: "tel" } },
        [
          _c("el-input", {
            attrs: { maxlength: "11" },
            model: {
              value: _vm.user.tel,
              callback: function($$v) {
                _vm.$set(_vm.user, "tel", $$v)
              },
              expression: "user.tel"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        { attrs: { label: "邮箱", prop: "mail" } },
        [
          _c("el-input", {
            attrs: { maxlength: "50" },
            model: {
              value: _vm.user.mail,
              callback: function($$v) {
                _vm.$set(_vm.user, "mail", $$v)
              },
              expression: "user.mail"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit }
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close }
            },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }