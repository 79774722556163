import _Object$assign from 'babel-runtime/core-js/object/assign';
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { readCoupon, listCouponUser } from '@/api/promotion/coupon';

var defaultUseStatusOptions = [{ label: '未使用', value: 0 }, { label: '已使用', value: 1 }, { label: '已过期', value: 2 }, { label: '已下架', value: 3 }];

export default {
  name: 'CouponDetail',
  filters: {
    formatType: function formatType(coupon) {
      if (coupon.type === 0) {
        return '全部商品使用';
      } else if (coupon.type === 1) {
        return coupon.valueName + '分类使用';
      } else {
        return coupon.worksName + '作品使用';
      }
    },
    formatUseStatus: function formatUseStatus(status) {
      if (status === 0) {
        return '未使用';
      } else if (status === 1) {
        return '已使用';
      } else if (status === 3) {
        return '已过期';
      } else {
        return '已下架';
      }
    }
  },
  data: function data() {
    return {
      useStatusOptions: _Object$assign({}, defaultUseStatusOptions),
      coupon: {},
      list: undefined,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 20,
        couponId: 0,
        userId: undefined,
        statusArray: undefined //使用状态集合
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.init();
  },

  methods: {
    init: function init() {
      var _this = this;

      if (this.$route.query.id == null) {
        return;
      }
      readCoupon(this.$route.query.id).then(function (response) {
        _this.coupon = response.data.data;
      });
      this.listQuery.couponId = this.$route.query.id;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      listCouponUser(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.total;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.list = [];
        _this2.total = 0;
        _this2.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.handleFilter();
    }
  }
};