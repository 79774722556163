//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { printingOrderDetail, listTaskPrinting } from '@/api/printing/printing.js';

import { pickerOptions } from '@/utils/index';
import AuthorImg from '@/components/AuthImg';
import AuthImgPreview from '@/components/AuthImgPreview';

export default {
  name: 'PrintingOrderDetail',
  components: { AuthorImg: AuthorImg, AuthImgPreview: AuthImgPreview },
  data: function data() {
    return {
      activeName: '1',
      cbillid: 0,
      poDetail: {},
      listQueryPrinting: {
        cbillid: undefined,
        page: 1,
        size: 10,
        userId: undefined,
        custname: undefined,
        dateRange: undefined
      },
      printingList: [],
      printingTotal: 0,
      printingLoading: false,
      pickerOptions: {
        shortcuts: []
      },
      searchTime: [],
      imageDialogVisible: false,
      preImageUrl: ''
    };
  },

  filters: {
    parseVprocessmemo: function parseVprocessmemo(val) {
      if (val) {
        if (val.indexOf('：') !== -1) {
          val = val.split('：')[1];
        }
        var bookNameStr = '';
        var bookNameArr = val.split('|');
        bookNameArr.forEach(function (item) {
          bookNameStr += '《' + item + "》";
        });
        return bookNameStr;
      }
      return '';
    }
  },
  created: function created() {
    this.cbillid = this.$route.query.cbillid;
    this.pickerOptions.shortcuts = pickerOptions;
    this.getDetail();
    this.getPrintingList();
  },

  methods: {
    handleTabsClick: function handleTabsClick(tab, event) {
      this.activeName = tab.name;
    },
    getDetail: function getDetail() {
      var _this = this;

      printingOrderDetail(this.cbillid).then(function (response) {
        _this.poDetail = response.data.data;
      }).catch(function (response) {
        _this.notifyError(response.data.msg);
      });
    },
    handlePrintingFilter: function handlePrintingFilter() {
      this.listQueryPrinting.page = 1;
      this.getPrintingList();
    },
    resetPrintingQuery: function resetPrintingQuery() {
      this.resetForm("queryPrintingForm");
      this.searchTime = [];
      this.handlePrintingFilter();
    },
    getPrintingList: function getPrintingList() {
      var _this2 = this;

      this.listQueryPrinting.dateRange = '';
      if (this.searchTime && this.searchTime instanceof Array && this.searchTime.length > 1) {
        this.listQueryPrinting.dateRange = [];
        this.listQueryPrinting.dateRange.push(this.searchTime[0]);
        this.listQueryPrinting.dateRange.push(this.searchTime[1]);
      }
      this.listQueryPrinting.cbillid = this.cbillid;
      this.printingLoading = true;
      listTaskPrinting(this.listQueryPrinting).then(function (response) {
        _this2.printingList = response.data.data.list;
        _this2.printingTotal = response.data.data.total;
        _this2.printingLoading = false;
      }).catch(function () {
        _this2.printingList = [];
        _this2.printingTotal = 0;
        _this2.printingLoading = false;
      });
    },
    previewImage: function previewImage(url) {
      this.imageDialogVisible = !this.imageDialogVisible;
      this.preImageUrl = url;
    }
  }
};