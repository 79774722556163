import request from '@/utils/request';
import Qs from 'qs';

export function listCategory(params) {
  return request({
    url: '/category/list?' + Qs.stringify(params, {
      arrayFormat: 'repeat'
    }),
    method: 'get'
  });
}

export function createCategory(data) {
  return request({
    url: '/category/create',
    method: 'post',
    data: data
  });
}

export function readCategory(id) {
  return request({
    url: '/category/read/' + id,
    method: 'get'
  });
}

export function updateCategory(data) {
  return request({
    url: '/category/update',
    method: 'put',
    data: data
  });
}

export function deleteCategory(id) {
  return request({
    url: '/category/delete/' + id,
    method: 'delete'
  });
}

/**
 * 稿件分类父层级详情查询
 * @param id 当前稿件分类id
 */
export function readParentCategory(id) {
  return request({
    url: '/category/read/parent/' + id,
    method: 'get'
  });
}

/**
 * 禁用
 */
export function disablingCategory(id) {
  return request({
    url: '/category/disabling/' + id,
    method: 'PUT'
  });
}

/**
 * 启用
 */
export function resumingCategory(id) {
  return request({
    url: '/category/resuming/' + id,
    method: 'PUT'
  });
}