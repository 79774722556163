var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "downloadFileProgress",
      attrs: {
        visible: _vm.visible,
        "show-close": false,
        "close-on-click-modal": false,
        width: "120px"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      !isNaN(_vm.complete)
        ? _c("el-progress", {
            attrs: { percentage: _vm.complete, type: "circle" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }