//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getIndexData } from '@/api/dashboard';
import CountTo from 'vue-count-to';

export default {
  components: {
    CountTo: CountTo
  },
  data: function data() {
    return {
      messageBase: {},
      todayBase: {},
      totalBase: {}
    };
  },
  created: function created() {
    var _this = this;

    getIndexData().then(function (response) {
      _this.messageBase = response.data.data.messageBase;
      _this.todayBase = response.data.data.todayBase;
      _this.totalBase = response.data.data.totalBase;
    });
  },

  methods: {
    handleDo: function handleDo(type) {
      switch (type) {
        case 'author':
          this.$router.push({ path: '/author/author' });
          break;
        case 'works':
          this.$router.push({ path: '/manuscript/works' });
          break;
        case 'printing':
          this.$router.push({ path: '/printing/printingPlant' });
          break;
        case 'feedback':
          this.$router.push({ path: '/comment/leavingMsg' });
          break;
        case 'bookbinding':
          this.$router.push({ path: '/bookBinding/bookBinding' });
          break;
        case 'withdrawal':
          this.$router.push({ path: '/withdrawal/withdrawal' });
          break;
        case 'paperWorksShip':
          this.$router.push({ path: '/order/orderPaperBook' });
          break;
        case 'paperWorksReturn':
          this.$router.push({ path: '/order/orderPaperBook' });
          break;
      }
    }
  }
};