var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: { model: _vm.listQuery, size: "small", inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "作品名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: "请输入作品名称", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.name,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作品类型", prop: "systemTypesArray" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "240px" },
                  attrs: { multiple: "", placeholder: "请选择作品类型" },
                  model: {
                    value: _vm.listQuery.systemTypesArray,
                    callback: function($$v) {
                      _vm.$set(_vm.listQuery, "systemTypesArray", $$v)
                    },
                    expression: "listQuery.systemTypesArray"
                  }
                },
                _vm._l(_vm.worksTypeArray, function(key, value) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: key, value: value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作品分类", prop: "storyclassId" } },
            [
              _c("treeselect", {
                staticStyle: { width: "240px" },
                attrs: {
                  options: _vm.storyclassOptions,
                  normalizer: _vm.normalizer,
                  placeholder: "选择作品分类"
                },
                model: {
                  value: _vm.listQuery.storyclassId,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "storyclassId", $$v)
                  },
                  expression: "listQuery.storyclassId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作品导航类型", prop: "categoryId" } },
            [
              _c("treeselect", {
                staticStyle: { width: "240px" },
                attrs: {
                  options: _vm.categoryOptions,
                  normalizer: _vm.normalizer,
                  placeholder: "选择作品导航类型"
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.listQuery.categoryId,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "categoryId", $$v)
                  },
                  expression: "listQuery.categoryId"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/manuscript/sale/works/list"],
                      expression: "['GET /admin/manuscript/sale/works/list']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中...",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "作品ID", prop: "id", width: "80" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品名称",
              prop: "worksName",
              "min-width": "150",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品封面",
              prop: "worksCover",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.worksCover
                      ? _c("img", {
                          staticClass: "pointer",
                          attrs: {
                            src: _vm.baseUrl + scope.row.worksCover,
                            width: "40",
                            height: "40"
                          },
                          on: {
                            click: function($event) {
                              return _vm.previewImage(scope.row.worksCover)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品分类",
              prop: "storyclassName",
              "min-width": "100",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品导航类型",
              prop: "categoryName",
              "min-width": "100",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品价格",
              prop: "worksPrice",
              width: "80"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "关键字",
              prop: "worksKeyword",
              "min-width": "150",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "标签名称",
              prop: "tagName",
              width: "100"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作品类型",
              prop: "systemType",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.systemType == 0
                      ? _c("el-tag", [_vm._v(_vm._s(_vm.worksTypeArray[0]))])
                      : scope.row.systemType == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(_vm._s(_vm.worksTypeArray[1]))
                        ])
                      : _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v(_vm._s(_vm.worksTypeArray[2]))
                        ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "作者名称",
              prop: "authorName",
              "min-width": "100",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "推荐状态",
              align: "center",
              prop: "isRecommend",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [
                            "PUT /admin/manuscript/sale/works/recommend/{manuscriptId}/{isRecommend}"
                          ],
                          expression:
                            "['PUT /admin/manuscript/sale/works/recommend/{manuscriptId}/{isRecommend}']"
                        }
                      ],
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.handleRecommendChange(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isRecommend,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isRecommend", $$v)
                        },
                        expression: "scope.row.isRecommend"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "上架状态",
              align: "center",
              prop: "isOnSale",
              width: "80"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: [
                            "PUT /admin/manuscript/sale/works/disabling/{id}",
                            "PUT /admin/manuscript/sale/works/resuming/{id}"
                          ],
                          expression:
                            "['PUT /admin/manuscript/sale/works/disabling/{id}', 'PUT /admin/manuscript/sale/works/resuming/{id}']"
                        }
                      ],
                      attrs: { "active-value": 1, "inactive-value": 0 },
                      on: {
                        change: function($event) {
                          return _vm.handleStatusChange(scope.row)
                        }
                      },
                      model: {
                        value: scope.row.isOnSale,
                        callback: function($$v) {
                          _vm.$set(scope.row, "isOnSale", $$v)
                        },
                        expression: "scope.row.isOnSale"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              align: "center",
              prop: "createTime",
              width: "150"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "class-name": "small-padding fixed-width",
              width: "150",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [
                              "GET /admin/manuscript/sale/works/read/{manuscriptId}",
                              "PUT /admin/manuscript/sale/works/update"
                            ],
                            expression:
                              "['GET /admin/manuscript/sale/works/read/{manuscriptId}', 'PUT /admin/manuscript/sale/works/update']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: [
                              "GET /admin/manuscript/sale/works/read/{manuscriptId}"
                            ],
                            expression:
                              "['GET /admin/manuscript/sale/works/read/{manuscriptId}']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-tickets"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("详情")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑",
            visible: _vm.dialogFormVisible,
            width: "1000px",
            "close-on-press-escape": false,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "940px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "right",
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "作品名称", prop: "worksName" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dataForm.worksName,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "worksName", $$v)
                              },
                              expression: "dataForm.worksName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品分类", prop: "storyclassId" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.storyclassOptions,
                              normalizer: _vm.normalizer,
                              placeholder: "选择作品分类"
                            },
                            on: { select: _vm.handleSelect },
                            model: {
                              value: _vm.dataForm.storyclassId,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "storyclassId", $$v)
                              },
                              expression: "dataForm.storyclassId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "作品标签", prop: "tagId" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "350px" },
                              attrs: { placeholder: "请选择作品标签" },
                              model: {
                                value: _vm.dataForm.tagId,
                                callback: function($$v) {
                                  _vm.$set(_vm.dataForm, "tagId", $$v)
                                },
                                expression: "dataForm.tagId"
                              }
                            },
                            _vm._l(_vm.tagList, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "关键字", prop: "worksKeyword" }
                        },
                        [
                          _vm._l(_vm.keywords, function(tag) {
                            return _c(
                              "el-tag",
                              {
                                key: tag,
                                attrs: { closable: "", type: "primary" },
                                on: {
                                  close: function($event) {
                                    return _vm.handleClose(tag)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(tag) +
                                    "\n            "
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.newKeywordVisible
                            ? _c("el-input", {
                                ref: "newKeywordInput",
                                staticClass: "input-new-keyword",
                                attrs: { size: "small" },
                                on: { blur: _vm.handleInputConfirm },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleInputConfirm($event)
                                  }
                                },
                                model: {
                                  value: _vm.newKeyword,
                                  callback: function($$v) {
                                    _vm.newKeyword = $$v
                                  },
                                  expression: "newKeyword"
                                }
                              })
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-new-keyword",
                                  attrs: { size: "small", type: "primary" },
                                  on: { click: _vm.showInput }
                                },
                                [_vm._v("+\n              增加")]
                              )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "创作时间", prop: "addTime" }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择创作时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.dataForm.addTime,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "addTime", $$v)
                              },
                              expression: "dataForm.addTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "入展时间", prop: "showTime" }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择入展时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.dataForm.showTime,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "showTime", $$v)
                              },
                              expression: "dataForm.showTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "其他时间", prop: "otherTime" }
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              type: "datetime",
                              placeholder: "请选择其他时间",
                              "value-format": "yyyy-MM-dd HH:mm:ss"
                            },
                            model: {
                              value: _vm.dataForm.otherTime,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "otherTime", $$v)
                              },
                              expression: "dataForm.otherTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "作品价格", prop: "worksPrice" }
                        },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "350px" },
                            attrs: { precision: 2, step: 0.1, min: 0 },
                            model: {
                              value: _vm.dataForm.worksPrice,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "worksPrice", $$v)
                              },
                              expression: "dataForm.worksPrice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "ml20",
                          attrs: { label: "作品导航分类", prop: "categoryId" }
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.categoryOptions,
                              normalizer: _vm.normalizer,
                              placeholder: "选择作品导航分类"
                            },
                            on: { select: _vm.handleSelect },
                            model: {
                              value: _vm.dataForm.categoryId,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "categoryId", $$v)
                              },
                              expression: "dataForm.categoryId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.dataForm.systemType === 2
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "mr20",
                              attrs: { label: "快递运费", prop: "freightPrice" }
                            },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "350px" },
                                attrs: { precision: 2, step: 0.1, min: 0 },
                                model: {
                                  value: _vm.dataForm.freightPrice,
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataForm, "freightPrice", $$v)
                                  },
                                  expression: "dataForm.freightPrice"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 12 } })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "mr20",
                          attrs: { label: "作品封面", prop: "worksCover" }
                        },
                        [
                          _c("ImageUpload", {
                            attrs: {
                              uploadImgUrl: _vm.uploadPath,
                              value: _vm.dataForm.worksCover,
                              limit: 1,
                              fileSize: 1
                            },
                            on: { input: _vm.getWorksCoverUrl }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "作品简介", prop: "worksDesc" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 8 },
                            model: {
                              value: _vm.dataForm.worksDesc,
                              callback: function($$v) {
                                _vm.$set(_vm.dataForm, "worksDesc", $$v)
                              },
                              expression: "dataForm.worksDesc"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateData } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.imageDialogVisible
        ? _c("AuthImgPreview", {
            attrs: { src: _vm.preImageUrl, visible: _vm.imageDialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.imageDialogVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }