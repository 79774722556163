import { render, staticRenderFns } from "./userStat.vue?vue&type=template&id=012ff6fe&scoped=true&"
import script from "./userStat.vue?vue&type=script&lang=js&"
export * from "./userStat.vue?vue&type=script&lang=js&"
import style0 from "./userStat.vue?vue&type=style&index=0&id=012ff6fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012ff6fe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\zgsf\\UI\\shufa-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('012ff6fe', component.options)
    } else {
      api.reload('012ff6fe', component.options)
    }
    module.hot.accept("./userStat.vue?vue&type=template&id=012ff6fe&scoped=true&", function () {
      api.rerender('012ff6fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src\\views\\statistics\\userStat.vue"
export default component.exports