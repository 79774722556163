import { render, staticRenderFns } from "./category.vue?vue&type=template&id=5a93005d&scoped=true&"
import script from "./category.vue?vue&type=script&lang=js&"
export * from "./category.vue?vue&type=script&lang=js&"
import style0 from "./category.vue?vue&type=style&index=0&id=5a93005d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a93005d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\zgsf\\UI\\shufa-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('5a93005d', component.options)
    } else {
      api.reload('5a93005d', component.options)
    }
    module.hot.accept("./category.vue?vue&type=template&id=5a93005d&scoped=true&", function () {
      api.rerender('5a93005d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src\\views\\manuscript\\category.vue"
export default component.exports