//
//
//
//
//
//

import AuthorImg from '@/components/AuthImg';

/**
 * 图片浏览
 */
export default {
  name: "AuthImgPreview",
  components: { AuthorImg: AuthorImg },
  props: {
    src: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      baseUrl: process.env.BASE_API
    };
  },

  methods: {
    modalClose: function modalClose() {
      this.$emit('update:visible', false); // 直接修改父组件的属性
    }
  }
};