import _extends from 'babel-runtime/helpers/extends';
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
import Logo from './Logo';
import _variables from '@/styles/variables.scss';

export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _extends({}, mapGetters(['permission_routers', 'sidebar']), {
    showLogo: function showLogo() {
      return this.sidebar.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    theme: function theme() {
      return this.$store.getters.theme;
    }
  })
};