//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Page404',
  computed: {
    message: function message() {
      return '您访问的地址不存在......';
    }
  }
};