var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch"
            }
          ],
          ref: "queryForm",
          attrs: { model: _vm.listQuery, size: "small", inline: true }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "角色名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入角色名称", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleFilter($event)
                  }
                },
                model: {
                  value: _vm.listQuery.name,
                  callback: function($$v) {
                    _vm.$set(_vm.listQuery, "name", $$v)
                  },
                  expression: "listQuery.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["GET /admin/role/list"],
                      expression: "['GET /admin/role/list']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleFilter }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["POST /admin/role/create"],
                      expression: "['POST /admin/role/create']"
                    }
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini"
                  },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v("新增")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          attrs: {
            data: _vm.list,
            size: "small",
            "element-loading-text": "正在查询中。。。",
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "角色名称",
              prop: "name",
              sortable: ""
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "角色描述", prop: "desc" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", prop: "createTime" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "permission",
                            rawName: "v-permission",
                            value: ["PUT /admin/role/update"],
                            expression: "['PUT /admin/role/update']"
                          }
                        ],
                        attrs: {
                          size: "medium",
                          type: "text",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    scope.row.id > 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["DELETE /admin/role/delete/{id}"],
                                expression: "['DELETE /admin/role/delete/{id}']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-delete"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.id > 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["PUT /admin/role/updatePermissions"],
                                expression:
                                  "['PUT /admin/role/updatePermissions']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-circle-check"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleMenuPerm(scope.row)
                              }
                            }
                          },
                          [_vm._v("功能授权")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.id > 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["PUT /admin/role/updatePermissions"],
                                expression:
                                  "['PUT /admin/role/updatePermissions']"
                              }
                            ],
                            attrs: {
                              size: "medium",
                              type: "text",
                              icon: "el-icon-circle-check"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDataPerm(scope.row)
                              }
                            }
                          },
                          [_vm._v("数据授权")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.size
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.listQuery, "size", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.dataForm,
                "status-icon": "",
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.name,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "name", $$v)
                      },
                      expression: "dataForm.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "角色描述", prop: "desc" } },
                [
                  _c("label", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("   角色描述")
                  ]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.dataForm.desc,
                      callback: function($$v) {
                        _vm.$set(_vm.dataForm, "desc", $$v)
                      },
                      expression: "dataForm.desc"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _vm.dialogStatus == "create"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createData }
                    },
                    [_vm._v("确定")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.updateData }
                    },
                    [_vm._v("确定")]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.permissionDialogFormVisible,
            title: "权限配置",
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.permissionDialogFormVisible = $event
            }
          }
        },
        [
          _c("el-tree", {
            ref: "tree",
            attrs: {
              data: _vm.systemPermissions,
              "default-checked-keys": _vm.assignedPermissions,
              "show-checkbox": "",
              "node-key": "id",
              "highlight-current": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var data = ref.data
                  return _c(
                    "span",
                    { staticClass: "custom-tree-node" },
                    [
                      _c("span", [_vm._v(_vm._s(data.label))]),
                      _vm._v(" "),
                      data.api
                        ? _c(
                            "el-tag",
                            { attrs: { type: "success", size: "mini" } },
                            [
                              _vm._v(
                                "\n          " + _vm._s(data.api) + "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.permissionDialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updatePermission }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.menuPermVisible,
            width: "800px",
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.menuPermVisible = $event
            }
          }
        },
        [
          _vm.menuPermissions.length > 0
            ? _c(
                "el-row",
                { staticClass: "mb8", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            plain: "",
                            icon: "el-icon-sort",
                            size: "mini"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleExpandAll(1)
                            }
                          }
                        },
                        [_vm._v("展开/折叠")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            icon: "el-icon-document-copy",
                            size: "mini"
                          },
                          on: { click: _vm.updMenuPermission }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            icon: "el-icon-close",
                            size: "mini"
                          },
                          on: {
                            click: function($event) {
                              _vm.menuPermVisible = false
                            }
                          }
                        },
                        [_vm._v("关闭")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-tree", {
            ref: "menuTreeRef",
            attrs: {
              data: _vm.menuPermissions,
              props: _vm.menuPermProps,
              "show-checkbox": "",
              "node-key": "id",
              "highlight-current": "",
              "default-checked-keys": _vm.checkPermArr
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var node = ref.node
                  var data = ref.data
                  return _c(
                    "span",
                    { staticClass: "custom-tree-node" },
                    [
                      _c("span", [_vm._v(_vm._s(node.label))]),
                      _vm._v(" "),
                      data.perms
                        ? _c(
                            "el-tag",
                            { attrs: { type: "success", size: "mini" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(data.perms) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dataPermVisible,
            width: "600px",
            "destroy-on-close": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dataPermVisible = $event
            }
          }
        },
        [
          _vm.dataPermissions.length > 0
            ? _c(
                "el-row",
                { staticClass: "mb8", attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            plain: "",
                            icon: "el-icon-sort",
                            size: "mini"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleExpandAll(2)
                            }
                          }
                        },
                        [_vm._v("展开/折叠")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            icon: "el-icon-document-copy",
                            size: "mini"
                          },
                          on: { click: _vm.updDataPermission }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 1.5 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            icon: "el-icon-close",
                            size: "mini"
                          },
                          on: {
                            click: function($event) {
                              _vm.dataPermVisible = false
                            }
                          }
                        },
                        [_vm._v("关闭")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-tree", {
            ref: "dataTreeRef",
            attrs: {
              data: _vm.dataPermissions,
              props: _vm.dataPermProps,
              "show-checkbox": "",
              "node-key": "id",
              "highlight-current": "",
              "default-expand-all": "",
              "default-checked-keys": _vm.checkPermArr
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }